import React, { useEffect } from 'react';
import {
  Modal,
  Button,
  Flex,
  ModalContent,
  ModalOverlay,
  Text,
  Link as StyleLink,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Link from 'components/Link';
import { useRouter } from 'next/router';
import { ACCEPT_TERMS_NAME, useMigrationDue } from 'hooks/useMigrationDate';

const CommissionMigrationModal: React.FC = () => {
  const modal = useDisclosure();
  const [t] = useTranslation();
  const { pathname } = useRouter();

  const { data: isMigrationDue, mutation } = useMigrationDue();

  const isMigrationPage = pathname.split('/').splice(1).join('/') === 'migration';

  useEffect(() => {
    if (!isMigrationPage && isMigrationDue) {
      setTimeout(() => modal.onOpen(), 1000);
    }
  }, [isMigrationPage, isMigrationDue, modal]);

  const handleConfirm = async () => {
    try {
      const description = `${t('common:commissionMigrationModal.title')} ${t(
        'common:commissionMigrationModal.description'
      )} ${t('common:commissionMigrationModal.moreInformationLink')}`;
      await mutation.mutateAsync({ name: ACCEPT_TERMS_NAME, description });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isCentered
      size='xl'
      autoFocus={true}
    >
      <ModalOverlay bg='transparent.50' />
      <ModalContent borderRadius={0}>
        <Flex flexDir='column' p={{ base: 6, sm: 9, md: 12 }}>
          <Text fontWeight='bold' fontFamily='heading' fontSize='lg' mb={8}>
            {t('common:commissionMigrationModal.title')}
          </Text>
          <Text fontSize='md' mb={2}>
            {t('common:commissionMigrationModal.description')}
          </Text>
          <Link route='migration'>
            <StyleLink fontSize='md' color='blue.400' fontWeight='semibold' mb={8}>
              {t('common:commissionMigrationModal.moreInformationLink')}
            </StyleLink>
          </Link>
          <Flex sx={{ gap: '1rem' }} flexWrap='wrap'>
            <Button variant='primary' isLoading={mutation.isLoading} onClick={() => handleConfirm()}>
              {t('common:commissionMigrationModal.confirmButton')}
            </Button>
          </Flex>
          {mutation.isError && (
            <Text fontSize='md' color='red.400' mt={2}>
              {t('common:commissionMigrationModal.error')}
            </Text>
          )}
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default CommissionMigrationModal;
