import React from 'react';
import { Box, Container, Flex, chakra, Link as StyleLink } from '@chakra-ui/react';
import Link from 'components/Link';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

const AuthenticatedLawyerFooter = () => {
  const [t] = useTranslation();
  return (
    <chakra.footer borderTop='1px' borderColor='gray.200' bottom={0} mt='auto'>
      <Container maxW='73.75rem' py={8} px={{ base: 4, md: 8 }}>
        <Flex key={'fix786'} align='center' justify='space-between'>
          <Box display='flex'>
            <Image
              alt='Gray logo'
              src='/icons/signet-gray.svg'
              width={14}
              height={26}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Box>
          <Flex sx={{ gap: '1.5rem' }}>
            <Link route='info/data-protection'>
              <StyleLink fontSize='sm' color='blue.400' fontWeight='semibold'>
                {t('common:info/data-protection')}
              </StyleLink>
            </Link>
            <Link route='info/terms-of-use'>
              <StyleLink fontSize='sm' color='blue.400' fontWeight='semibold'>
                {t('common:info/terms-of-use')}
              </StyleLink>
            </Link>
          </Flex>
        </Flex>
      </Container>
    </chakra.footer>
  );
};

export default AuthenticatedLawyerFooter;
