export const germanSlugify = (string: string) => {
  const a =
    'äàáâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñöòóôœøṕŕřßşśšșťțüùúûǘůűūųẃẍÿýźžż·/_,:;ÄÀÁÂÃÅĂÆĄÇĆČĐĎÈÉĚĖËÊĘĞǴḦÌÍÏÎĮŁḾǸŃŇÑÖÒÓÔŒØṔŔŘSSŞŚŠȘŤȚÜÙÚÛǗŮŰŪŲẂẌŸÝŹŽŻ';
  const b =
    'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrssssssttuuuuuuuuuwxyyzzz';
  const p = new RegExp(a.split('').join('|'), 'g');
  // we may need to get back to this later, with other languages
  const germanSpecialTransformations = {
    ä: 'ae',
    ö: 'oe',
    ü: 'ue',
    Ü: 'ue',
    Ä: 'ae',
    Ö: 'oe',
  };

  Object.entries(germanSpecialTransformations).forEach(
    ([pattern, replace]) => (string = string.replace(pattern, replace))
  );

  const f = string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '-') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

  return f;
};

export const nonGermanSlugify = (string: string) => {
  const a =
    'äàáâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñöòóôœøṕŕřßşśšșťțüùúûǘůűūųẃẍÿýźžż·/_,:;ÄÀÁÂÃÅĂÆĄÇĆČĐĎÈÉĚĖËÊĘĞǴḦÌÍÏÎĮŁḾǸŃŇÑÖÒÓÔŒØṔŔŘSSŞŚŠȘŤȚÜÙÚÛǗŮŰŪŲẂẌŸÝŹŽŻ';
  const b =
    'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrssssssttuuuuuuuuuwxyyzzz';
  const p = new RegExp(a.split('').join('|'), 'g');

  const f = string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '-') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

  return f;
};

export const abbreviateCanton = (string: string) => {
  const map: Record<string, string> = {
    aargau: 'AG',
    'appenzell-ausserrhoden': 'AR',
    'appenzell-innerrhoden': 'AI',
    'basel-landschaft': 'BL',
    'basel-stadt': 'BS',
    bern: 'BE',
    freiburg: 'FR',
    genf: 'GE',
    glarus: 'GL',
    graubunden: 'GR',
    graubuenden: 'GR',
    jura: 'JU',
    luzern: 'LU',
    neuenburg: 'NE',
    nidwalden: 'NW',
    obwalden: 'OW',
    schaffhausen: 'SH',
    schwyz: 'SZ',
    solothurn: 'SO',
    'st-gallen': 'SG',
    tessin: 'TI',
    thurgau: 'TG',
    uri: 'UR',
    waadt: 'VD',
    wallis: 'VS',
    zug: 'ZG',
    zurich: 'ZH',
    zuerich: 'ZH',
  };

  const cantonShort = map[nonGermanSlugify(string)];

  return cantonShort ? cantonShort.toLowerCase() : string;
};

export const cantonMap: Record<string, string> = {
  AG: 'Aargau',
  AR: 'Appenzell-Ausserrhoden',
  AI: 'Appenzell-Innerrhoden',
  BL: 'Basel-Landschaft',
  BS: 'Basel-Stadt',
  BE: 'Bern',
  FR: 'Freiburg',
  GE: 'Genf',
  GL: 'Glarus',
  GR: 'Graubünden',
  JU: 'Jura',
  LU: 'Luzern',
  NE: 'Neuenburg',
  NW: 'Nidwalden',
  OW: 'Obwalden',
  SH: 'Schaffhausen',
  SZ: 'Schwyz',
  SO: 'Solothurn',
  SG: 'St. Gallen',
  TI: 'Tessin',
  TG: 'Thurgau',
  UR: 'Uri',
  VD: 'Waadt',
  VS: 'Wallis',
  ZG: 'Zug',
  ZH: 'Zürich',
};
