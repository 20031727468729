import { isSSActivated } from './utils';

export const setClientEmailVerification = () => {
  if (!isSSActivated()) return;
  sessionStorage.setItem('verifyClientEmailAddress', 'true');
};

export const getClientEmailVerification = () => {
  if (!isSSActivated()) return;
  const verify = sessionStorage.getItem('verifyClientEmailAddress');
  return verify === 'true' ? true : undefined;
};

export const clearClientEmailVerification = () => {
  if (!isSSActivated()) return;
  sessionStorage.removeItem('verifyClientEmailAddress');
};
