import React, { useEffect } from 'react';
import { Box, Center, Flex, Skeleton, Link as StyleLink, chakra } from '@chakra-ui/react';
import Image from 'next/image';
import useUser from 'utils/auth-utils';
import { Lawyer } from 'graphql/queries/SingleLawyerQuery';
import { WarningIcon } from '@chakra-ui/icons';
import { openSupportBubble, identifyUser } from 'utils/analytics';
import AuthenticatedLawyerMenu from '../AuthenticatedLawyerMenu';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from '../Link';
import { COLORS } from 'jurata-styleguide/src/materials/colors';
import CommissionMigrationModal from 'components/CommissionMigrationModal';

const HeaderAuthenticatedLawyer = () => {
  const [t] = useTranslation();
  const { pathname } = useRouter();
  const { user, loading } = useUser() as { user: Lawyer; loading: boolean };

  const isPoolPage = pathname.split('/').splice(1).join('/') === 'pool';
  const isRequestsPage = pathname.split('/').splice(1).join('/') === 'requests';

  const openSupport = async () => {
    await identifyUser(user._id, user.email);
    await openSupportBubble();
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--nprogresscolor', COLORS.green.light);
  }, []);

  return (
    <Box h={16}>
      <chakra.header
        display='flex'
        w='100%'
        borderBottom='1px'
        borderColor='gray.200'
        bg='white'
        pos='fixed'
        flexDir='column'
        zIndex={3}
        h={16}
        boxShadow='0px 0px 4px rgba(0, 0, 0, 0.25);'
      >
        <CommissionMigrationModal />
        <Center w='100%' maxW='73.75rem' flexWrap='nowrap' mx='auto' flexDir='column' px={{ base: 4, md: 8 }} flex={1}>
          <Flex justify='space-between' w='100%' py={3} sx={{ gap: '1rem' }}>
            <Flex sx={{ gap: { base: '1rem', md: '2rem' } }}>
              <Box display='flex' alignItems={'center'}>
                <Image
                  alt='Blue logo'
                  src='/icons/signet-blue.svg'
                  width={12}
                  height={24}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </Box>
              {
                <Flex
                  display={{ base: 'none', md: 'flex' }}
                  align='center'
                  sx={{ gap: { base: '1rem', sm: '2rem' } }}
                  overflow='hidden'
                >
                  <Link route='pool'>
                    <StyleLink
                      fontWeight='bold'
                      fontSize='md'
                      letterSpacing='0.05rem'
                      color={isPoolPage ? 'blue.400' : 'gray.400'}
                      _hover={{ cursor: 'pointer', color: 'blue.400' }}
                      overflow='hidden'
                      textOverflow='ellipsis'
                      whiteSpace='nowrap'
                      flexShrink={0}
                    >
                      {t('common:pool').toUpperCase()}
                    </StyleLink>
                  </Link>
                  <Link route='requests'>
                    <StyleLink
                      fontWeight='bold'
                      fontSize='md'
                      letterSpacing='0.05rem'
                      color={isRequestsPage ? 'blue.400' : 'gray.400'}
                      _hover={{ cursor: 'pointer', color: 'blue.400' }}
                      overflow='hidden'
                      textOverflow='ellipsis'
                      whiteSpace='nowrap'
                      flexShrink={3}
                    >
                      {t('common:myRequests').toUpperCase()}
                    </StyleLink>
                  </Link>
                </Flex>
              }
            </Flex>
            <Flex sx={{ gap: { base: '1rem', md: '1.5rem' } }} align='center'>
              <Flex alignSelf='center'>
                <Flex align='center' display={{ base: 'none', md: 'flex' }}>
                  <WarningIcon color='blue.400' w={4} h={4} mr={2} />
                  <StyleLink
                    fontWeight='semibold'
                    fontSize='md'
                    color='blue.400'
                    mr={8}
                    onClick={async () => await openSupport()}
                  >
                    {t('common:support')}
                  </StyleLink>
                </Flex>
                {loading ? (
                  <Skeleton h={9} w={{ base: 16, md: 40 }} endColor='gray.200' />
                ) : (
                  <>
                    <AuthenticatedLawyerMenu />
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Center>
      </chakra.header>
    </Box>
  );
};

export default HeaderAuthenticatedLawyer;
