import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
  Flex,
  Box,
  Divider,
  Button,
  useDisclosure,
  Link as LinkStyle,
} from '@chakra-ui/react';
import Link from './Link';
import { getAvatarImage } from 'utils/lawyers';
import useUser, { logout } from 'utils/auth-utils';
import { Lawyer } from 'graphql/queries/SingleLawyerQuery';
import Image from 'next/image';
import { TriangleUpIcon, TriangleDownIcon, WarningIcon } from '@chakra-ui/icons';
import { openSupportBubble, identifyUser } from 'utils/analytics';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { css } from '@emotion/react';

const AuthenticatedLawyerMenu = () => {
  const [t] = useTranslation();
  const { user } = useUser() as { user: Lawyer };
  const client = useApolloClient();
  const drawer = useDisclosure();

  const openSupport = async () => {
    await identifyUser(user._id, user.email);
    await openSupportBubble();
  };

  return (
    <>
      <Menu offset={[0, 16]} placement='bottom-end'>
        <MenuButton
          px={4}
          py={2}
          border='1px'
          borderColor='gray.150'
          borderRadius='0.375rem'
          align='center'
          as={Button}
          rightIcon={<TriangleDownIcon w={2} h={2} color='black' />}
          height='unset'
          bg='white'
          _active={{ bg: 'white' }}
          _hover={{ bg: 'white' }}
          display={{ base: 'none', md: 'flex' }}
        >
          <Flex>
            <Box w={6} h={6} borderRadius='50%' flexShrink={0} overflow='hidden' position='relative' mr={2}>
              <Image
                alt={`${user?.firstName} ${user?.lastName}`}
                src={getAvatarImage(user?.avatar, {
                  gender: user?.gender,
                  size: 'small',
                })}
                fill
                sizes='100vw'
                style={{
                  objectFit: 'cover',
                }}
              />
            </Box>
            <Text
              fontSize='md'
              fontWeight='semibold'
              textAlign='end'
              color='black'
              mr={2}
              maxW={32}
              whiteSpace='nowrap'
              overflow='hidden'
              textOverflow='ellipsis'
            >{`${user?.firstName} ${user?.lastName}`}</Text>
          </Flex>
        </MenuButton>
        <MenuList
          filter='drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));'
          borderRadius='0'
          color='black'
          zIndex={2}
          css={css`
            & div {
              z-index: 2;
            }
          `}
        >
          <Link route='pool'>
            <MenuItem py={2} px={6}>
              <Flex>
                {/* <Box display='flex' mr={4}>
                <Image alt='Law firm icon' src='/icons/law-firm.svg' width={12} height={16} />
              </Box> */}
                {t('common:pool')}
              </Flex>
            </MenuItem>
          </Link>
          <Link route='requests'>
            <MenuItem py={2} px={6}>
              <Flex>
                {/* <Box display='flex' mr={2.5}>
                <Image alt='Profile icon' src='/icons/profile.svg' width={18} height={13} />
              </Box> */}
                {t('common:myRequests')}
              </Flex>
            </MenuItem>
          </Link>
          <MenuDivider borderColor='gray.150' />
          <Link route='law-firm/my-law-firm'>
            <MenuItem py={2} px={6}>
              <Flex>
                <Box display='flex' alignItems={'center'} mr={4}>
                  <Image
                    alt='Law firm icon'
                    src='/icons/law-firm.svg'
                    width={12}
                    height={16}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Box>
                {t('common:lawfirm')}
              </Flex>
            </MenuItem>
          </Link>
          <Link route='profile/my-profile'>
            <MenuItem py={2} px={6}>
              <Flex>
                <Box display='flex' alignItems={'center'} mr={2.5}>
                  <Image
                    alt='Profile icon'
                    src='/icons/profile.svg'
                    width={18}
                    height={13}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Box>
                {t('common:profile')}
              </Flex>
            </MenuItem>
          </Link>
          <MenuDivider borderColor='gray.150' />
          <Link route='settings/account'>
            <MenuItem py={2} px={6}>
              <Flex>
                <Box display='flex' alignItems={'center'} mr={3}>
                  <Image
                    alt='Settings icon'
                    src='/icons/cogwheel.svg'
                    width={16}
                    height={16}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Box>
                {t('common:settings')}
              </Flex>
            </MenuItem>
          </Link>
          <Link route='settings/subscription'>
            <MenuItem py={2} px={6}>
              <Flex>
                <Box display='flex' alignItems={'center'} mr={3}>
                  <Image
                    alt='Settings icon'
                    src='/icons/subscription.svg'
                    width={16}
                    height={16}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Box>
                {t('common:subscription')}
              </Flex>
            </MenuItem>
          </Link>
          <Link route='commission-fees'>
            <MenuItem py={2} px={6}>
              <Flex>
                <Box display='flex' alignItems={'center'} mr={3}>
                  <Image
                    alt='Commission fees icon'
                    src='/icons/pen.svg'
                    width={16}
                    height={16}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Box>
                {t('common:commissionFees')}
              </Flex>
            </MenuItem>
          </Link>
          <MenuItem py={2} px={6} onClick={async () => await openSupport()}>
            <WarningIcon color='blue.400' w={4} h={4} mr={3} />
            {t('common:support')}
          </MenuItem>
          <MenuDivider borderColor='gray.150' />
          <MenuItem py={2} px={6} onClick={() => logout(client)}>
            <Box display='flex' alignItems={'center'} mr='3px'>
              <Image
                alt='Logout icon'
                src='/icons/logout.svg'
                width={26}
                height={23}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </Box>
            {t('common:logout')}
          </MenuItem>
        </MenuList>
      </Menu>
      <Flex
        display={{ base: 'flex', md: 'none' }}
        px={4}
        py={2}
        border='1px'
        borderColor='gray.150'
        borderRadius='0.375rem'
        align='center'
        onClick={drawer.onOpen}
      >
        <Flex>
          <Box w={6} h={6} borderRadius='50%' flexShrink={0} overflow='hidden' position='relative' mr={2}>
            <Image
              alt={`${user?.firstName} ${user?.lastName}`}
              src={getAvatarImage(user?.avatar, {
                gender: user?.gender,
                size: 'small',
              })}
              fill
              sizes='100vw'
              style={{
                objectFit: 'cover',
              }}
            />
          </Box>
          <Text
            fontSize='md'
            fontWeight='semibold'
            textAlign='end'
            color='black'
            mr={2}
            maxW={32}
            whiteSpace='nowrap'
            overflow='hidden'
            textOverflow='ellipsis'
          >{`${user?.firstName} ${user?.lastName}`}</Text>
        </Flex>
        <TriangleDownIcon w={2} h={2} color='black' />
      </Flex>
      <Drawer size={'md'} isOpen={drawer.isOpen} placement='right' onClose={drawer.onClose} autoFocus={false}>
        <DrawerOverlay />
        <DrawerContent bg='white'>
          <DrawerHeader
            borderBottom='1px solid gray'
            borderBottomColor='gray.200'
            display='flex'
            backgroundColor='white'
            alignItems='center'
            justifyContent='space-between'
            m={0}
            py={3}
            px={4}
            zIndex='modal'
          >
            <Box alignItems={'center'} display='flex'>
              <Image
                alt='Blue logo'
                src='/icons/signet-blue.svg'
                width={12}
                height={24}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </Box>
            <Flex
              px={4}
              py={2}
              border='1px'
              borderColor='gray.150'
              borderRadius='0.375rem'
              align='center'
              onClick={drawer.onClose}
            >
              <Box w={6} h={6} borderRadius='50%' flexShrink={0} overflow='hidden' position='relative' mr={2}>
                <Image
                  alt={`${user?.firstName} ${user?.lastName}`}
                  src={getAvatarImage(user?.avatar, {
                    gender: user?.gender,
                    size: 'small',
                  })}
                  fill
                  sizes='100vw'
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Text
                fontSize='md'
                fontWeight='semibold'
                textAlign='end'
                mr={2}
                color='black'
              >{`${user?.firstName} ${user?.lastName}`}</Text>
              <TriangleUpIcon w={2} h={2} color='black' />
            </Flex>
          </DrawerHeader>
          <DrawerBody py={3} px={0} overflow='auto'>
            <Link route='pool'>
              <LinkStyle _hover={{ bg: 'gray.100' }} py={2} px={6}>
                <Flex>
                  {/* <Box display='flex' mr={4}>
                  <Image alt='Law firm icon' src='/icons/law-firm.svg' width={12} height={16} />
                </Box> */}
                  {t('common:pool')}
                </Flex>
              </LinkStyle>
            </Link>
            <Link route='requests'>
              <LinkStyle _hover={{ bg: 'gray.100' }} py={2} px={6}>
                <Flex>
                  {/* <Box display='flex' mr={2.5}>
                  <Image alt='Profile icon' src='/icons/profile.svg' width={18} height={13} />
                </Box> */}
                  {t('common:myRequests')}
                </Flex>
              </LinkStyle>
            </Link>
            <Divider my={3} borderColor='gray.150' />
            <Link route='law-firm'>
              <LinkStyle _hover={{ bg: 'gray.100' }} py={2} px={6}>
                <Flex>
                  <Box display='flex' mr={4}>
                    <Image
                      alt='Law firm icon'
                      src='/icons/law-firm.svg'
                      width={12}
                      height={16}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  </Box>
                  {t('common:lawfirm')}
                </Flex>{' '}
              </LinkStyle>
            </Link>
            <Link route='profile/my-profile'>
              <LinkStyle _hover={{ bg: 'gray.100' }} py={2} px={6}>
                <Flex>
                  <Box display='flex' mr={2.5}>
                    <Image
                      alt='Profile icon'
                      src='/icons/profile.svg'
                      width={18}
                      height={13}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  </Box>
                  {t('common:profile')}
                </Flex>{' '}
              </LinkStyle>
            </Link>
            <Divider my={3} borderColor='gray.150' />
            <Link route='settings/account'>
              <LinkStyle _hover={{ bg: 'gray.100' }} py={2} px={6}>
                <Flex>
                  <Box display='flex' mr={3}>
                    <Image
                      alt='Settings icon'
                      src='/icons/cogwheel.svg'
                      width={16}
                      height={16}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  </Box>
                  {t('common:settings')}
                </Flex>{' '}
              </LinkStyle>
            </Link>
            <Link route='commission-fees'>
              <LinkStyle _hover={{ bg: 'gray.100' }} py={2} px={6}>
                <Flex>
                  <Box display='flex' mr={3}>
                    <Image
                      alt='Commission fees icon'
                      src='/icons/pen.svg'
                      width={16}
                      height={16}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  </Box>
                  {t('common:commissionFees')}
                </Flex>{' '}
              </LinkStyle>
            </Link>
            <LinkStyle _hover={{ bg: 'gray.100' }} py={2} px={6} onClick={async () => await openSupport()}>
              <WarningIcon color='blue.400' w={4} h={4} mr={3} />
              {t('common:support')}
            </LinkStyle>

            <Divider my={3} borderColor='gray.150' />
            <LinkStyle display='flex' _hover={{ bg: 'gray.100' }} py={2} px={6} onClick={async () => logout(client)}>
              <Box mr='3px'>
                <Image
                  alt='Logout icon'
                  src='/icons/logout.svg'
                  width={26}
                  height={23}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </Box>
              {t('common:logout')}
            </LinkStyle>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AuthenticatedLawyerMenu;
