import { useDisclosure } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';

const ForbiddenLoginModal = dynamic(() => import('./ForbiddenLoginModal'));

interface ClientErrors {
  isForbidden: boolean;
}

const ErrorBoundary: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [errors, setErrors] = useState<ClientErrors>({ isForbidden: false });
  const {
    isOpen: isForbiddenModalOpen,
    onClose: onForbiddenModalClose,
    onOpen: onForbiddenModalOpen,
  } = useDisclosure();

  useEffect(() => {
    window.addEventListener('forbiddenResource', () => {
      onForbiddenModalOpen();
      setErrors({ isForbidden: true });
    });

    return () => {
      window.removeEventListener('forbiddenResource', () => {
        onForbiddenModalClose();
        setErrors({ isForbidden: false });
      });
    };
  });
  return (
    <>
      {errors.isForbidden && <ForbiddenLoginModal isOpen={isForbiddenModalOpen} onClose={onForbiddenModalClose} />}
      {children}
    </>
  );
};

export default ErrorBoundary;
