import { gql } from '@apollo/client';
import { PoolCaseStatus } from 'typings/shared';

export const requestType = {
  CALLBACK: 'Callback',
  MESSAGE: 'Message',
  MEETING: 'Meeting',
  POOL: 'Pool',
} as const;
export type RequestType = typeof requestType[keyof typeof requestType];

export const lawyerAcceptanceStatus = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  REFUSED: 'Refused',
  EXPIRED: 'Expired',
  CLOSED: 'Closed',
} as const;
export type LawyerAcceptanceStatus = typeof lawyerAcceptanceStatus[keyof typeof lawyerAcceptanceStatus];

export const requestPricingType = {
  FREE: 'Free',
  MANDATE: 'Mandate',
  FIX: 'Fix',
} as const;
export type RequestPricingType = typeof requestPricingType[keyof typeof requestPricingType];

export interface MandateFee {
  issueDate: string | number;
  amount: number;
}

export interface Commission {
  percentage: number;
  cap?: number;
}

export interface Request {
  _id: string;
  createdAt: string;
  description: string;
  case: {
    title?: string;
    description: string;
    status: PoolCaseStatus;
    practiceAreas?: { name: string; _id: string }[];
    client: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    };
    location?: {
      place: string;
    };
    createdAt: string;
    _id: string;
  };
  type: RequestType;
  meeting: Meeting;
  videoPreference?: boolean;
  status: LawyerAcceptanceStatus;
  conditions?: string;
  lawyerReaction?: string;
  pricing?: {
    type: RequestPricingType;
    isPaid?: boolean;
    lastUpdated?: number;
    mandateFees?: MandateFee[];
    commission?: Commission;
  };
  isMandated?: boolean;
}

export interface Meeting {
  availability: DayAvailability[];
  notes: string;
}

export interface DayAvailability {
  date: string;
  timeSlots: string[];
}

export const GET_MY_REQUESTS = gql`
  query {
    requests @rest(method: "GET", type: "RequestPayload", path: "/api/requests?{args.timestamp}") {
      _id
      createdAt
      description
      case {
        _id
        title
        createdAt
        presentedDescription
        description
        status
        location
        practiceAreas
        client {
          firstName
          lastName
          email
          phone
        }
      }
      type
      lawyerReaction
      meeting {
        availability {
          date
          timeSlots
        }
        notes
      }
      videoPreference
      status
      conditions
      lastUpdated
      pricing {
        type
        isPaid
        lastUpdated
        mandateFees
        commission
      }
      isMandated
    }
  }
`;
