import { gql } from '@apollo/client';

export interface QueryResult {
  setLawyerEmailPassword: {
    success: boolean;
    token: string;
  };
}

export interface SetPasswordVariables {
  email: string;
  password: string;
}

export const SET_PASSWORD_QUERY = gql`
  mutation ($email: String, $password: String) {
    setLawyerEmailPassword(email: $email, password: $password) {
      success
      token
    }
  }
`;
