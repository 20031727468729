import { ApolloClient } from '@apollo/client';

import { withAuthHeaders } from '../graphql/mutations/utils';
import { LawyerFilters, OfficeWithLawyers, SearchLawyer } from '../typings/jurata';
import { LawyerSearchParam, RESTLawyer } from '../typings/shared';
import { LawyerSearchResults } from '../graphql/queries/LawyerSearchQuery';
import { Asset, Firm } from '../graphql/queries/SingleLawyerQuery';
import { OnboardingFirms, GET_FIRMS_DATA, GET_FILTERED_FIRMS_DATA } from '../graphql/queries/GetOnboardingData';
import { image } from './cockpit';
import { paramToTagFilter, escapeTagFilter } from './search-utils';
import { germanSlugify } from './slugify';
import { TFunction } from 'next-i18next';
import { ContentSpecialCertificatesQuery } from 'graphql/queries/GetLocalizedSpecialCertificates';

export const sortLawyerLanguages = (languages: RESTLawyer['languages']): RESTLawyer['languages'] => {
  return [...languages].sort((a, b) => {
    if (b.kind === a.kind) return 0;
    if (b.kind === 'mother' && a.kind !== 'mother') return 1;
    return b.kind === 'correspondance' && a.kind === 'other' ? 1 : -1;
  });
};

export const lawyerFirmSize = (lawyerCount: number): 'small' | 'medium' | 'large' => {
  if (lawyerCount > 20) return 'large';
  if (lawyerCount > 5) return 'medium';
  return 'small';
};

/**
 * Converts the browser URL query params into a valid filters object to be used by the app
 * @param query browser URL query params
 */
export const lawyerParamsToFilters = (queryParams: { [key in LawyerSearchParam]?: string }): LawyerFilters => {
  return {
    slug: queryParams.lawyers,
    lawFirmSlug: queryParams.firms,
    lawFirmPlaceSlug: queryParams.places,
    lawFirmCantonSlug: queryParams.cantons,
    practiceAreasSlugs: queryParams.practiceareas ? escapeTagFilter(queryParams.practiceareas) : undefined,
    lawFirmSizeGroup: queryParams.lawFirmSizeGroup,
    experienceGroup: queryParams.experienceGroup,
    savSpecialist: queryParams.savSpecialist,
    available: queryParams.available,
    languages: queryParams.languages ? paramToTagFilter(queryParams.languages) : undefined,
  };
};

interface PracticeArea {
  field: string;
  weight: number;
  slug: string;
}
export interface WeightedPracticeAreas {
  slug: string;
  data: [string, number];
}

type FlattenedPracticeAreas = [string, number][];

export const sortPracticeAreas = (
  columns: PracticeArea[]
): { weightedPracticeAreas: WeightedPracticeAreas[]; unweightedPracticeAreas: FlattenedPracticeAreas } => {
  const weightedPracticeAreas = columns
    .filter((column) => column.weight > 0)
    .sort((a, b) => b.weight - a.weight)
    .map((column) => ({ slug: column.slug, data: [column.field, column.weight] as [string, number] }));

  const unweightedPracticeAreas = columns
    .filter((column) => column.weight === 0 || column.weight === null)
    .sort((a, b) => b.weight - a.weight)
    .map((column) => [column.field, column.weight] as [string, number]);

  return {
    weightedPracticeAreas,
    unweightedPracticeAreas,
  };
};
export const lawyersFromData = (data: LawyerSearchResults): SearchLawyer[] =>
  data?.JurataSearchIndex && data?.JurataSearchIndex.items ? data.JurataSearchIndex.items.map((i) => i.payload) : [];

export const formatLawyerAddress = (office: OfficeWithLawyers, pbLabel: string): string[] => {
  const { address, postbox } = office;

  const facade = [];
  const place = address.town || address.municipality;
  if (address.extraLine && address.extraLine.trim()) facade.push(address.extraLine);
  if (postbox && postbox.location && postbox.location !== place) facade.push(address.street + ', ' + place);
  else facade.push(address.street);
  if (postbox && postbox.nr) {
    if (postbox.nr.indexOf(pbLabel) > -1) facade.push(postbox.nr);
    else facade.push(pbLabel + ' ' + postbox.nr);
  }
  facade.push(
    `${postbox?.zip || address.zip} ${postbox?.location || place}` + (postbox?.extraLine ? ` ${postbox.extraLine}` : '')
  );
  return facade;
};

export const getSimplifiedFirmsGraphQL = async (apolloClient: ApolloClient<{}>) => {
  const results = await apolloClient.query<OnboardingFirms>({
    query: GET_FIRMS_DATA,
  });

  if (results.errors && results.errors.length > 0)
    throw new Error(`An error occured when fetching a list of lawyers: ${results.errors[0]}`);

  const { data } = results;

  return {
    data,
  };
};

export const getSimplifiedFilteredFirmsGraphQL = async (filter: any, limit: number, apolloClient: ApolloClient<{}>) => {
  const results = await apolloClient.query<OnboardingFirms>({
    query: GET_FILTERED_FIRMS_DATA,
    variables: { filter, limit },
    context: withAuthHeaders,
  });

  if (results.errors && results.errors.length > 0)
    throw new Error(`An error occured when fetching a list of lawyers: ${results.errors[0]}`);

  const { data } = results;

  return {
    data,
  };
};

const AvatarSizes = {
  small: 32,
  medium: 142,
  large: 290,
};

interface AvatarOption {
  size: keyof typeof AvatarSizes;
  gender: string;
}

export const getAvatarImage = (asset: string | Asset, { gender = 'male', size = 'small' }: AvatarOption) => {
  const pixelDensity = 2;
  const defaultImage = `/icons/lawyer-${gender}.svg`;

  if (!asset || asset === '0') return defaultImage;

  if (typeof asset === 'string') return image(asset, AvatarSizes[size] * pixelDensity);

  if (asset._id) return image(asset._id, AvatarSizes[size] * pixelDensity);

  return defaultImage;
};

/**
 * Get slugs municipality or canton for the search page
 * @param param slug type (municipality or canton)
 * @param firm Firm object
 * @returns slug or nothing
 */
export const getOfficePlaceSlug = (param: 'municipality' | 'canton' | undefined, firm: Firm | null) => {
  if (firm && param) {
    if (param === 'municipality' && firm.office.address.municipalitySlug) {
      return `${firm.office.address.municipalitySlug}`;
    } else if (param === 'canton') {
      return `kanton-${germanSlugify(firm.office.address.cantonAbbr)}`;
    }
  }
  return '';
};

export const cleanCertificate = (certificate: string, gender: string, t: TFunction): string => {
  // This is to correct the data that comes out of the backend in different shapes. Normally we expect 'Fachanwalt'
  return (
    `${t('lawyerProfile:specialistLawyer', { context: gender })} ${t('lawyerProfile:certificateAbbreviation')} ${t(
      'lawyerProfile:certificatePreposition'
    )}` +
    certificate
      .replace(t('lawyerProfile:specialistLawyer', { context: 'male' }), '')
      .replace(t('lawyerProfile:specialistLawyer', { context: 'female' }), '')
      .replace('/', '')
      .replace(t('lawyerProfile:certificateAbbreviation'), '')
      .trim()
  );
};

export const translateCertificate = (
  certificate: string,
  gender: string,
  t: TFunction,
  translatedCertificates: ContentSpecialCertificatesQuery | undefined
): string => {
  const cleanedCertificated = cleanCertificate(certificate, gender, t);
  const hit =
    translatedCertificates &&
    translatedCertificates.specialCertificates?.items.find((sc) => cleanedCertificated.includes(sc.germanName));
  const translatedSC = hit ? cleanedCertificated.replace(hit.germanName, hit.name) : cleanedCertificated;

  return translatedSC;
};

export const getLocation = (lawyer: SearchLawyer, unknown: string, infoNotAvailable: string): string => {
  const { lawFirmPlaceDisplay, lawFirmCantonAbbr } = lawyer;
  if (lawFirmPlaceDisplay && lawFirmCantonAbbr) {
    return lawFirmPlaceDisplay + ', ' + lawFirmCantonAbbr;
  }
  if (lawFirmPlaceDisplay) return lawFirmPlaceDisplay;
  if (lawFirmCantonAbbr) return unknown + lawFirmCantonAbbr;
  return infoNotAvailable;
};

export const printFirstConsultationDuration = (duration: string | false = '15', t: TFunction) => {
  if (!duration) return t('lawyerProfile:maxMinutes', { minutesNo: '15' });
  const d = duration.replace(/Dauer:|Minuten|max|max.|min|Min.|min.|Min/, '').trim();
  return t('lawyerProfile:maxMinutes', { minutesNo: d });
};

export const printFirstConsultationPrice = (price: string | false, t: TFunction) => {
  if (!!price) return t('lawyerProfile:paidConsultation');
  return t('lawyerProfile:freeConsultation');
};

export const LAWYER_DASHBOARD_ROUTES = [
  '/law-firm',
  '/law-firm/change-law-firm',
  '/law-firm/edit-law-firm',
  '/law-firm/new-law-firm',
  '/pool',
  '/settings',
  '/settings/account',
  '/settings/subscription',
  '/profile/my-profile',
  '/profile/edit-career',
  '/profile/edit-engagement',
  '/profile/edit-practice-areas',
  '/profile/edit-profile',
  '/profile/edit-short-profile',
  '/commission-fees',
  '/requests',
  '/migration',
];
