import { ApolloClient, gql } from '@apollo/client';
import { SubscriptionType } from '../../typings/shared';

export interface UserSettingsData {
  slug: string;
  accountType: SubscriptionType;
  signUpStatus: 'unclaimed' | 'claimed' | 'onboarded';
  practiceAreas: any;
  firm: any;
  email: any;
  subscriptionPrice: string;
  subscriptionPeriod: string;
  termsOfUseAccepted: string;
  _id: string;
}

interface UserSettingsDataResult {
  lawyersCollection: UserSettingsData[];
}

interface GetUserInfoVariables {
  filter: { token?: string; slug?: string };
}

export const USER_SETTINGS_QUERY = gql`
  query ($filter: JsonType!) {
    lawyersCollection(filter: $filter, limit: 1) {
      slug
      _id
      email
      signUpStatus
      accountType
      subscriptionPrice
      practiceAreas {
        value {
          preference
          practiceArea {
            name
            _created
          }
        }
      }
      firm {
        _id
        name
        website
        office
      }
      subscriptionPeriod
      termsOfUseAccepted
    }
  }
`;

export const getUserSettingsFromSlug = async (slug: string, apolloClient: ApolloClient<{}>) => {
  const { data, errors } = await apolloClient.query<UserSettingsDataResult, GetUserInfoVariables>({
    query: USER_SETTINGS_QUERY,
    variables: {
      filter: {
        slug,
      },
    },
  });

  if (errors && errors.length > 0) throw new Error(`An error occured fetching the user settings: ${errors[0]}`);

  return data.lawyersCollection[0];
};
