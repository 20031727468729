import { useQuery } from '@apollo/client';
import { Chip } from 'components/SearchBox';
import { ResourceBySlugResult } from 'graphql/queries/GetResourceBySlug';
import { Aggregations, LawyerSearchResults, LAWYER_SEARCH_QUERY } from 'graphql/queries/LawyerSearchQuery';
import { useRouter } from 'next/router';
import { SearchLawyer } from 'typings/jurata';
import { AllLawyerSearchParams, LawyerSearchParam } from 'typings/shared';
import { lawyerParamsToFilters, lawyersFromData } from 'utils/lawyers';
import {
  grabValidQueryParams,
  similarSearchesData,
  mixInPathQuery,
  buildFilterString,
  getInitialChips,
  buildInitialChipsQuery,
} from 'utils/search-utils';
import { abbreviateCanton, cantonMap } from 'utils/slugify';
import { useLocalizedCountsQuery } from './useLocalizedCountsQuery';

export const LAWYERS_PER_PAGE = 20;

interface CountProps {
  name: string;
  count: number;
  slug: string;
}

export interface SimilarSearchesProps {
  similarPlaces: CountProps[];
  similarPracticeAreas: CountProps[];
  similarCanton?: { name: string; slug: string };
}

interface LawyerSearchProps {
  lawyers: SearchLawyer[];
  nearby?: string;
  count?: number;
  aggregations?: Aggregations;
  fullQuery?: AllLawyerSearchParams;
  initialChips?: Chip[];
  page?: number;
  searchFilters?: string;
  fetchErrors?: string[];
  similarSearches?: SimilarSearchesProps;
  firmLocations?: string[];
  canonical?: string;
  isLoading?: boolean;
  comingFromCanonical: boolean;
}

export const useLawyerSearch = (): LawyerSearchProps => {
  const router = useRouter();
  const { data: translatedCounts } = useLocalizedCountsQuery();

  const { slugs } = router.query;
  const { firmSlug } = router.query;

  let comingFromCanonical = false;
  if (slugs || firmSlug) {
    comingFromCanonical = true;
  }

  const shavedQuery: { [key in LawyerSearchParam]?: string[] } = grabValidQueryParams(router.query);
  const mixedQuery = mixInPathQuery(shavedQuery, slugs, firmSlug).query as
    | {
        [key in LawyerSearchParam]?: string[];
      }
    | null;

  const transformedQuery: AllLawyerSearchParams = {};
  Object.entries(mixedQuery || {}).map(([key, val]) => {
    if (val) transformedQuery[key as LawyerSearchParam] = val.join(',');
  });

  const searchFilters = buildFilterString(lawyerParamsToFilters(transformedQuery)) || '*';
  const page = mixedQuery && mixedQuery.page && mixedQuery.page[0] ? parseInt(mixedQuery.page[0]) : 1;

  const { initialChipsQuery, hasChips } = buildInitialChipsQuery({
    places: transformedQuery.places,
    cantons: transformedQuery.cantons,
    lawyers: transformedQuery.lawyers,
    practiceareas: transformedQuery.practiceareas,
    firms: transformedQuery.firms,
  });
  // useQuery({skip}) does not work as expected.
  // Issue: https://github.com/apollographql/apollo-client/issues/6190
  const { data: chipsData, loading: chipsLoading } = useQuery<{ [key: string]: { resource: ResourceBySlugResult } }>(
    initialChipsQuery,
    { skip: !hasChips }
  );
  const initialChips = hasChips ? getInitialChips(chipsData || {}, translatedCounts) : [];

  let cantonQuery = initialChips && initialChips.filter((c) => c.type === 'canton')[0];

  let cantonName: string | undefined = cantonQuery && cantonQuery.name;
  let cantonSlug: string | undefined = cantonQuery && cantonQuery.slug;

  if (!cantonQuery) {
    let placeQuery = initialChips && initialChips.filter((c) => c.type === 'place')[0];
    if (placeQuery) {
      const splitSlug = placeQuery.slug.split('-');
      const cantonAbbreviation = splitSlug[splitSlug.length - 1].toUpperCase();
      cantonName =
        cantonAbbreviation &&
        (translatedCounts?.JurataCountIndex.cantons?.data.find(
          (translatedCanton) => translatedCanton.cantonCode === cantonAbbreviation
        )?.name ||
          cantonMap[cantonAbbreviation]);
      cantonSlug = `kanton-${cantonAbbreviation.toLocaleLowerCase() || abbreviateCanton(cantonName as string)}`;
    }
  }

  const { data, loading, error } = useQuery<LawyerSearchResults>(LAWYER_SEARCH_QUERY, {
    variables: {
      offset: 0,
      limit: page && page * LAWYERS_PER_PAGE,
      filters: searchFilters,
      filterByCanton: cantonSlug,
    },
  });

  const { similarPlaces, similarPracticeAreas } = similarSearchesData(data!);

  const similarSearches: any = {
    similarPlaces,
    similarPracticeAreas: similarPracticeAreas.map((pa) => {
      const hit = translatedCounts?.JurataCountIndex.practiceAreas?.data.find(
        (translatedPA) => translatedPA.slug === pa.slug
      );
      return {
        ...pa,
        name: hit?.name || pa.name,
      };
    }),
  };

  if (cantonName && cantonSlug) {
    similarSearches.similarCanton = { name: cantonName as string, slug: cantonSlug };
  }

  return {
    lawyers: lawyersFromData(data as LawyerSearchResults),
    nearby: data?.JurataSearchIndex && data.JurataSearchIndex.nearby,
    count: data?.JurataSearchIndex && data.JurataSearchIndex.count,
    aggregations: data?.JurataSearchIndex && data.JurataSearchIndex.aggregations,
    similarSearches,
    isLoading: loading || (hasChips ? chipsLoading : false),
    fetchErrors: error?.message ? [error.message] : undefined,
    initialChips,
    page,
    comingFromCanonical,
    searchFilters,
    fullQuery: transformedQuery,
  };
};
