import { gql } from '@apollo/client';
import { BillingType, Gender, SubscriptionType } from '../../typings/shared';

export interface FirmWithOffice {
  _id: string;
  name: string;
  office: {
    name: string;
    address: {
      zip: string;
      municipality: string;
      town: string;
    };
  };
}

export interface UserData {
  _id: string;
  slug: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: Gender;
  signUpStatus: 'unclaimed' | 'claimed' | 'onboarded';
  accountType: SubscriptionType;
  subscriptionPrice: string;
  subscriptionPeriod: BillingType;
  stripeCustomerId: string;
  avatar: {
    _id: string;
    title: string;
    path: string;
    mime: string;
    tags: string[];
    colors: string[];
  };
}

export interface UserDataResult {
  lawyersCollection: UserData[];
}

export interface GetUserInfoVariables {
  filter: { token: string };
}

export const GET_USER_ENGAGEMENTS = gql`
  query ($filter: JsonType) {
    lawyersCollection(filter: $filter) {
      _id
      memberships
      awards
      accountType
      publications {
        value {
          title
          link
          publication
        }
      }
    }
  }
`;

export const GET_USER_PRACTICE_AREAS = gql`
  query ($filter: JsonType) {
    lawyersCollection(filter: $filter) {
      _id
      practiceAreas {
        value {
          preference
          practiceArea {
            name
            _created
          }
        }
      }
    }
  }
`;

export const USER_DATA_QUERY = gql`
  query ($filter: JsonType!) {
    lawyersCollection(filter: $filter, limit: 1) {
      _id
      slug
      firstName
      lastName
      email
      gender
      signUpStatus
      accountType
      subscriptionPrice
      subscriptionPeriod
      stripeCustomerId
      avatar {
        _id
        title
        path
        mime
        tags
        colors
      }
    }
  }
`;

export const GET_USER_AUTHENTICATION = gql`
  query ($filter: JsonType!) {
    lawyersCollection(filter: $filter, limit: 1) {
      _id
      email
    }
  }
`;
