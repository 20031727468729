import React from 'react';
import nprogress from 'nprogress';
import { useApolloClient } from '@apollo/client';
import { NextPage } from 'next';

import { useRouter } from 'next/router';
import { PageInitialProps, UrlHistory } from '../typings/shared';
import { getFullUserDataClientSide } from '../utils/auth-utils';
import { trackPage } from '../utils/analytics';
import { getSecondToLastFromArray } from '../utils/utils';
import { UserData } from 'graphql/queries/GetUserInfo';

interface PageTrickeryProps extends UrlHistory {
  children: React.ReactNode;
}

const PageTrickery: NextPage<PageTrickeryProps, PageInitialProps> = ({ children, urlHistory }) => {
  const apolloClient = useApolloClient();
  const router = useRouter();

  const nprogressStart = () => {
    nprogress.start();
  };

  const nprogressDone = () => {
    nprogress.done();
  };

  const trackPageAnalytics = async (path: string, referrer?: string) => {
    const user = await getFullUserDataClientSide(apolloClient);
    trackPage(path, user as UserData, referrer);
  };

  React.useEffect(() => {
    nprogress.configure({ showSpinner: false });
    router.events.on('routeChangeStart', nprogressStart);
    router.events.on('routeChangeComplete', nprogressDone);
    router.events.on('routeChangeError', nprogressDone);

    return () => {
      router.events.off('routeChangeStart', nprogressStart);
      router.events.off('routeChangeComplete', nprogressDone);
      router.events.off('routeChangeError', nprogressDone);
    };
  }, []);

  React.useEffect(() => {
    if (urlHistory.length >= 1 && router.asPath === urlHistory[urlHistory.length - 1]) {
      if (getSecondToLastFromArray(urlHistory).split('?')[0] !== router.asPath.split('?')[0]) {
        trackPageAnalytics(router.pathname, getSecondToLastFromArray(urlHistory));
      }
    } else {
      if (window.dataLayer && window.dataLayer.length) {
        const lastItem = window.dataLayer[window.dataLayer.length - 1];
        if (lastItem.path === window.location.pathname && lastItem.referrer === document.referrer) {
          return;
        }
      }
      trackPageAnalytics(router.pathname, undefined);
    }
  }, [urlHistory]);

  return <>{children}</>;
};

export default PageTrickery;
