import { ApolloClient } from '@apollo/client';
import { getUserSettingsFromSlug } from '../graphql/queries/SettingsQuery';
import { UserData } from '../graphql/queries/GetUserInfo';
import { Lawyer } from '../graphql/queries/SingleLawyerQuery';
import { SearchLawyer } from '../typings/jurata';
import { Query, SubscriptionType, subscriptionType, LawyerUserInterfaceType, RESTLawyer } from '../typings/shared';
import { Chip, TypedResource } from '../components/SearchBox';
import { getFullUserDataClientSide } from './auth-utils';
import { getHubSpotIdentificationToken, sendHubspotTimelineEvent } from './jurata-api';
import { Client } from 'graphql/queries/GetClient';

declare global {
  interface Window {
    analytics: any;
    dataLayer: any;
  }
}

interface UserDimensions {
  accountType?: SubscriptionType;
  signUpStatus?: string;
  userId?: string;
}

interface ActionObject extends UserDimensions, TypedResource {
  obj?: string;
  canton?: string;
  location?: string;
  practiceArea?: string;
  checked?: boolean;
  option?: string;
  searchResults?: {
    slug: string;
    rank: string;
  }[];
  slug?: string;
  boost?: string | boolean;
  rank?: string;
  resultCount?: string;
  searchQuery?: string;
  path?: string;
  email?: string;
  match?: boolean;
  position?: string;
  url?: string;
  search?: string;
  title?: string;
  source?: string;
  query?: Chip[] | string;
  userId?: string;
  type?: string;
  hsToken?: string;
}

interface FullActionObject extends ActionObject {
  event: 'action';
  name: string;
}

interface PageObject extends UserDimensions {
  event: 'page';
  path: string;
  referrer: string;
  search: string;
  title: string;
  url: string;
  contentGroup: string;
}

export interface SearchSource {
  rank: string;
  resultCount: string;
  searchQuery: string;
}

const addUserDataToObject = (
  object: PageObject | ActionObject,
  userData: UserData | false
): PageObject | ActionObject => {
  if (!userData) return object;
  return {
    ...object,
    ...{
      accountType: userData.accountType,
      signUpStatus: userData.signUpStatus,
      userId: userData._id,
    },
  };
};

export const trackAction = async (name: string, apolloClient?: ApolloClient<{}>, obj?: ActionObject) => {
  const user = apolloClient ? await getFullUserDataClientSide(apolloClient) : false;

  const object: FullActionObject = {
    name,
    event: 'action',
    ...(obj ? (addUserDataToObject(obj, user as UserData) as ActionObject) : {}),
    obj: JSON.stringify(obj),
  };

  window && window.dataLayer && window.dataLayer.push(object);
};

export const identifyUser = async (id: string, email: string) => {
  const token = await getHubSpotIdentificationToken();
  await trackAction('User Identified', undefined, { userId: id, email, hsToken: token });
};

export const identifyClient = async (id: string, email: string) => {
  const token = await getHubSpotIdentificationToken();
  await trackAction('Client Identified', undefined, { userId: id, email, hsToken: token });
  await removeSupportBubble();
};

export const openSupportBubble = async () => {
  await trackAction('Open Support Bubble');
};

export const removeSupportBubble = async () => {
  await trackAction('Remove Support Bubble');
};

export const trackPage = (name: string, userData: false | UserData | Client, referrer?: string) => {
  if (name.charAt(0) === '/') {
    name = name.substring(1);
    if (!name) {
      name = 'home';
    }
  }

  let obj: PageObject = {
    event: 'page',
    path: window.location.pathname,
    referrer: referrer || document.referrer,
    search: window.location.search,
    title: document.title,
    url: window.location.href,
    contentGroup: name,
  };

  if ((userData as UserData)?.signUpStatus) {
    obj = addUserDataToObject(obj, userData as UserData) as PageObject;
  } else if (userData) {
    obj = { ...obj, userId: (userData as Client)._id };
  }

  window && window.dataLayer && window.dataLayer.push(obj);
};

export const getConversionEventParams = (
  router: any,
  lawyer: Lawyer | SearchLawyer | RESTLawyer
): (SearchSource & { source: string; slug: string }) | {} => {
  if (router) {
    let { rank, resultCount, searchQuery, openCtaMeeting, openCtaMessage } = router.query as Query;
    const source = searchQuery
      ? `search${openCtaMeeting ? '-meeting-button' : ''}${openCtaMessage ? '-message-button' : ''}`
      : 'not-search';
    return {
      rank: rank as string,
      boost: lawyer.accountType === subscriptionType.PREMIUM ? 'true' : 'false',
      resultCount: resultCount as string,
      searchQuery: searchQuery as string,
      source,
      slug: lawyer.slug,
    };
  }
  return {};
};

export const publicHubSpotAnalytics = async (
  type: LawyerUserInterfaceType,
  name: string,
  lawyerSlug: string,
  apolloClient?: ApolloClient<{}>
) => {
  if (apolloClient) {
    const settingsData = await getUserSettingsFromSlug(lawyerSlug as string, apolloClient);
    await sendHubspotTimelineEvent(type, name, settingsData.email, settingsData._id);
  } else {
    await sendHubspotTimelineEvent(type, name);
  }
};
