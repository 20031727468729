import { gql } from '@apollo/client';

export interface LocalizedPracticeArea {
  name: string;
  name_de: string;
  legacyId: string;
  slug: string;
  practiceAreaGroup: { name: string };
}

export interface LocalizedPracticeAreasMap {
  practiceAreas: {
    items: LocalizedPracticeArea[];
  };
}

export const NEXTJS_API_CONTENT_PRACTICE_AREAS_QUERY = gql`
  query NextjsApiPracticeAreas {
    practiceAreas(locale: $locale)
      @rest(method: "GET", type: "PracticeAreas", path: "/api/practice-areas?locale={args.locale}") {
      items {
        name
        name_de
        legacyId
        slug
        practiceAreaGroup {
          name
        }
      }
    }
  }
`;
