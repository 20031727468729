module.exports = [
  { language: 'de', localName: '/', pathName: 'index', source: '/', destination: '/de' },
  { language: 'de', localName: '/', pathName: '/', source: '/', destination: '/de' },
  {
    language: 'de',
    localName: 'anwaelte',
    pathName: 'lawyers',
    source: '/de/anwaelte/:slugs*',
    destination: '/de/lawyers',
  },
  {
    language: 'de',
    localName: 'anwalt',
    pathName: 'lawyer/[slug]',
    source: '/de/anwalt/:slug',
    destination: '/de/lawyer/:slug',
  },
  {
    language: 'de',
    localName: 'anwalt',
    pathName: 'lawyer',
    source: '/de/anwalt',
    destination: '/de/lawyer',
  },
  {
    language: 'de',
    localName: 'anfrage-pool/konditionen',
    pathName: 'pool/conditions',
    source: '/de/anfrage-pool/konditionen',
    destination: '/de/pool/conditions',
  },
  {
    language: 'de',
    localName: 'anfrage-pool/beanspruchen',
    pathName: 'pool/claim/[caseId]',
    source: '/de/anfrage-pool/beanspruchen/:caseId',
    destination: '/de/pool/claim/:caseId',
  },
  {
    language: 'de',
    localName: 'finanzierungsrunde',
    pathName: 'news/investment-round',
    source: '/de/finanzierungsrunde',
    destination: '/de/news/investment-round',
  },
  {
    language: 'de',
    localName: 'ortschaften',
    pathName: 'directory/places',
    source: '/de/ortschaften',
    destination: '/de/directory/places',
  },
  {
    language: 'de',
    localName: 'ortschaften',
    pathName: 'directory/places/[letter]',
    source: '/de/ortschaften/:letter',
    destination: '/de/directory/places/:letter',
  },
  {
    language: 'de',
    localName: 'alle-anwaelte',
    pathName: 'directory/all-lawyers',
    source: '/de/alle-anwaelte',
    destination: '/de/directory/all-lawyers',
  },
  {
    language: 'de',
    localName: 'alle-anwaelte',
    pathName: 'directory/all-lawyers/[letter]',
    source: '/de/alle-anwaelte/:letter',
    destination: '/de/directory/all-lawyers/:letter',
  },
  {
    language: 'de',
    localName: 'kanzleien',
    pathName: 'directory/law-firms',
    source: '/de/kanzleien',
    destination: '/de/directory/law-firms',
  },
  {
    language: 'de',
    localName: 'kanzleien',
    pathName: 'directory/law-firms/[letter]',
    source: '/de/kanzleien/:letter',
    destination: '/de/directory/law-firms/:letter',
  },
  {
    language: 'de',
    localName: 'rechtsgebiete',
    pathName: 'directory/practice-areas',
    source: '/de/rechtsgebiete',
    destination: '/de/directory/practice-areas',
  },
  {
    language: 'de',
    localName: 'rechtsgebiete',
    pathName: 'directory/practice-areas/[slug]',
    source: '/de/rechtsgebiete/:slug',
    destination: '/de/directory/practice-areas/:slug',
  },
  {
    language: 'de',
    localName: 'kantone',
    pathName: 'directory/cantons',
    source: '/de/kantone',
    destination: '/de/directory/cantons',
  },
  { language: 'de', localName: 'login', pathName: 'login', source: '/de/login', destination: '/de/login' },
  {
    language: 'de',
    localName: 'kanzlei',
    pathName: 'firm',
    source: '/de/kanzlei/:firm',
    destination: '/de/lawyers?firms=:firm',
  },
  { language: 'de', localName: 'kanzlei', pathName: 'firm', source: '/de/kanzlei', destination: '/de/lawyers' },
  {
    language: 'de',
    localName: 'mein-profil',
    pathName: 'profile/my-profile',
    source: '/de/mein-profil',
    destination: '/de/profile/my-profile',
  },
  {
    language: 'de',
    localName: 'mein-profil/profil/bearbeiten',
    pathName: 'profile/edit-profile',
    source: '/de/mein-profil/profil/bearbeiten',
    destination: '/de/profile/edit-profile',
  },
  {
    language: 'de',
    localName: 'mein-profil/kurz-profil/bearbeiten',
    pathName: 'profile/edit-short-profile',
    source: '/de/mein-profil/kurz-profil/bearbeiten',
    destination: '/de/profile/edit-short-profile',
  },
  {
    language: 'de',
    localName: 'mein-profil/rechtsgebiete/bearbeiten',
    pathName: 'profile/edit-practice-areas',
    source: '/de/mein-profil/rechtsgebiete/bearbeiten',
    destination: '/de/profile/edit-practice-areas',
  },
  {
    language: 'de',
    localName: 'mein-profil/werdegang/bearbeiten',
    pathName: 'profile/edit-career',
    source: '/de/mein-profil/werdegang/bearbeiten',
    destination: '/de/profile/edit-career',
  },
  {
    language: 'de',
    localName: 'mein-profil/engagement/bearbeiten',
    pathName: 'profile/edit-engagement',
    source: '/de/mein-profil/engagement/bearbeiten',
    destination: '/de/profile/edit-engagement',
  },
  {
    language: 'de',
    localName: 'anfragen',
    pathName: 'requests',
    source: '/de/anfragen/:ids*',
    destination: '/de/requests',
  },
  {
    language: 'de',
    localName: 'anfrage-pool',
    pathName: 'pool',
    source: '/de/anfrage-pool/:id*',
    destination: '/de/pool',
  },
  {
    language: 'de',
    localName: 'mandate-erhalten',
    pathName: 'for-lawyers',
    source: '/de/mandate-erhalten',
    destination: '/de/for-lawyers',
  },
  {
    language: 'de',
    localName: 'onboarding',
    pathName: 'onboarding',
    source: '/de/onboarding',
    destination: '/de/onboarding',
  },
  {
    language: 'de',
    localName: 'passwort-vergessen',
    pathName: 'forgot-password',
    source: '/de/passwort-vergessen',
    destination: '/de/forgot-password',
  },
  {
    language: 'de',
    localName: 'einstellungen',
    pathName: 'settings',
    source: '/de/einstellungen',
    destination: '/de/settings',
  },
  {
    language: 'de',
    localName: 'einstellungen/konto-angaben',
    pathName: 'settings/account',
    source: '/de/einstellungen/konto-angaben',
    destination: '/de/settings/account',
  },
  {
    language: 'de',
    localName: 'einstellungen/abonnement',
    pathName: 'settings/subscription',
    source: '/de/einstellungen/abonnement',
    destination: '/de/settings/subscription',
  },
  {
    language: 'de',
    localName: 'abonnement',
    pathName: 'subscription-public',
    source: '/de/abonnement/:slug',
    destination: '/de/subscription-public?slug=:slug',
  },
  {
    language: 'de',
    localName: 'abonnement',
    pathName: 'subscription-public',
    source: '/de/abonnement',
    destination: '/de/subscription-public',
  },
  {
    language: 'de',
    localName: 'voranmeldung',
    pathName: 'presubscription',
    source: '/de/voranmeldung',
    destination: '/de/presubscription',
  },
  {
    language: 'de',
    localName: 'passwort-festlegen',
    pathName: 'set-password',
    source: '/de/passwort-festlegen/:token',
    destination: '/de/set-password?token=:token',
  },
  {
    language: 'de',
    localName: 'passwort-festlegen',
    pathName: 'set-password',
    source: '/de/passwort-festlegen',
    destination: '/de/set-password',
  },
  {
    language: 'de',
    localName: 'kontakt',
    pathName: 'info/contact',
    source: '/de/kontakt',
    destination: '/de/info/contact',
  },
  {
    language: 'de',
    localName: 'fragen-und-antworten',
    pathName: 'info/faq',
    source: '/de/fragen-und-antworten',
    destination: '/de/info/faq',
  },
  {
    language: 'de',
    localName: 'nutzungsbedingungen',
    pathName: 'info/terms-of-use',
    source: '/de/nutzungsbedingungen',
    destination: '/de/info/terms-of-use',
  },
  {
    language: 'de',
    localName: 'datenschutz',
    pathName: 'info/data-protection',
    source: '/de/datenschutz',
    destination: '/de/info/data-protection',
  },
  {
    language: 'de',
    localName: 'fallanmeldung',
    pathName: 'case-intake',
    source: '/de/fallanmeldung',
    destination: '/de/case-intake',
  },
  {
    language: 'de',
    localName: 'dashboard',
    pathName: 'dashboard',
    source: `/de/dashboard`,
    destination: `/de/dashboard`,
  },
  {
    language: 'de',
    localName: 'fall',
    pathName: 'case/[caseId]',
    source: '/de/fall/:caseId',
    destination: '/de/case/:caseId',
  },
  {
    language: 'de',
    localName: 'dashboard/mein-profil',
    pathName: 'dashboard/my-profile',
    source: `/de/dashboard/mein-profil`,
    destination: `/de/dashboard/my-profile`,
  },
  {
    language: 'de',
    localName: 'registrierung',
    pathName: 'register',
    source: '/de/registrierung',
    destination: '/de/register',
  },
  {
    language: 'de',
    localName: 'mandatsgebuehren',
    pathName: 'commission-fees',
    source: '/de/mandatsgebuehren',
    destination: '/de/commission-fees',
  },
  {
    source: '/sitemap.xml',
    destination: '/de/sitemap.xml',
  },
  {
    language: 'de',
    localName: 'meine-kanzlei/wechseln',
    pathName: 'law-firm/change-law-firm',
    source: '/de/meine-kanzlei/wechseln',
    destination: '/de/law-firm/change-law-firm',
  },
  {
    language: 'de',
    localName: 'meine-kanzlei/editieren',
    pathName: 'law-firm/edit-law-firm',
    source: '/de/meine-kanzlei/editieren',
    destination: '/de/law-firm/edit-law-firm',
  },
  {
    language: 'de',
    localName: 'meine-kanzlei/neue-kanzlei',
    pathName: 'law-firm/new-law-firm',
    source: '/de/meine-kanzlei/neue-kanzlei',
    destination: '/de/law-firm/new-law-firm',
  },
  {
    language: 'de',
    localName: 'meine-kanzlei',
    pathName: 'law-firm/my-law-firm',
    source: '/de/meine-kanzlei',
    destination: '/de/law-firm/my-law-firm',
  },
  {
    language: 'de',
    localName: 'kanzlei-verwaltung',
    pathName: 'law-firm',
    source: '/de/kanzlei-verwaltung',
    destination: '/de/law-firm',
  },
  {
    language: 'de',
    localName: 'checkout',
    pathName: 'checkout/[type]',
    source: '/de/checkout/:type',
    destination: '/de/checkout/:type',
  },
];
