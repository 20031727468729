import { ApolloError, useQuery } from '@apollo/client';
import {
  Counts,
  CountsResults,
  COUNTS_QUERY,
  CountValues,
  CountValuesData,
  LocalizedIndexCounts,
  LocalizedCountValues,
  NEXTJS_API_CONTENT_RESOURCE_QUERY,
} from 'graphql/queries/CountsQuery';
import {
  NEXTJS_API_CONTENT_PRACTICE_AREAS_QUERY,
  LocalizedPracticeAreasMap,
} from 'graphql/queries/GetLocalizedPracticeAreas';
import { PartialRecord } from 'jurata-styleguide/typings/types';
import { useRouter } from 'next/router';

type LocalizedCountIndex = 'practiceAreas' | 'cantons' | 'places';

type LocalizedCountsResults = PartialRecord<LocalizedCountIndex, LocalizedCountValues>;

export interface LocalizedCountsQuery {
  data: CountsResults | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

export const useLocalizedCountsQuery = (countIndexLimit: number = 1000) => {
  const { locale } = useRouter();

  const {
    data: jurataCountData,
    loading: loading1,
    error: error1,
  } = useQuery<CountsResults>(COUNTS_QUERY(['practiceAreas', 'places', 'cantons', 'total']), {
    variables: { limit: countIndexLimit },
  });

  const {
    data: contentData,
    loading: loading2,
    error: error2,
  } = useQuery<{ countsQuery: LocalizedCountsResults }>(NEXTJS_API_CONTENT_RESOURCE_QUERY, {
    variables: { locale },
    context: { clientName: 'nextjs-api' },
  });

  const { data: translatedPAs } = useQuery<LocalizedPracticeAreasMap>(NEXTJS_API_CONTENT_PRACTICE_AREAS_QUERY, {
    variables: { locale },
    context: { clientName: 'nextjs-api' },
  });

  const localizedData: LocalizedIndexCounts = {
    cantons: contentData?.countsQuery?.cantons,
    practiceAreas: contentData?.countsQuery?.practiceAreas,
    places: contentData?.countsQuery?.places,
  };

  const loading = loading1 || loading2;
  const error = error1 || error2;
  let translatedData: CountsResults = { JurataCountIndex: { ...jurataCountData?.JurataCountIndex } };

  if (jurataCountData?.JurataCountIndex) {
    Object.entries(jurataCountData?.JurataCountIndex || {}).forEach(([key, val]) => {
      if (
        typeof val === 'object' &&
        jurataCountData?.JurataCountIndex[key as keyof Counts] &&
        localizedData &&
        jurataCountData
      ) {
        let localizedArray = [...(val as CountValues).data].map((item) => {
          let hit: any;
          if (key === 'practiceAreas') {
            hit = translatedPAs?.practiceAreas?.items?.find(
              (localizedItem) => item.name.toLowerCase() === localizedItem.name_de.toLowerCase()
            );
          } else {
            hit = localizedData[key as keyof LocalizedIndexCounts]?.items?.find(
              (localizedItem) => item.slug === localizedItem.slug
            );
          }
          return {
            ...item,
            name: key === 'places' ? hit?.municipality : hit?.name,
            group: key === 'practiceAreas' && { name: hit?.practiceAreaGroup.name },
            legacyId: hit?.legacyId,
            cantonCode: hit?.cantonCode,
          };
        });
        (translatedData.JurataCountIndex[key as keyof Counts] as CountValues) = {
          total: (jurataCountData.JurataCountIndex[key as keyof Counts] as CountValues).total,
          data: Array.from(localizedArray) as CountValuesData,
        };
      }
    });
  }

  return {
    data: loading ? undefined : (translatedData as CountsResults),
    loading,
    error,
  } as LocalizedCountsQuery;
};
