import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { Locale, locale } from 'typings/shared';
import { isSSActivated } from 'utils/utils';

export const usePreserveScroll = () => {
  const router = useRouter();

  const isBack = useRef(false);
  useEffect(() => {
    router.beforePopState(() => {
      isBack.current = true;
      return true;
    });

    const onRouteChangeStart = () => {
      let url = router.asPath.split('?')[0];
      const paths = url.split('/');
      if (!Object.values(locale).includes(paths[0] as Locale)) url = `/${router.locale}${url}`;
      saveScrollPosition(url);
    };

    const onRouteChangeComplete = (url: any) => {
      const path = url.split('?')[0];
      if (isBack.current) {
        restoreScrollPositon(path);
      }

      isBack.current = false;
    };

    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router]);
};

const saveScrollPosition = (url: string) => {
  if (isSSActivated()) {
    let list = JSON.parse(sessionStorage.getItem('scrollPositions') || '{}');
    list = list ? list : {};
    list[url] = window.scrollY;
    sessionStorage.setItem('scrollPositions', JSON.stringify(list));
  }
};

const restoreScrollPositon = (url: string) => {
  if (isSSActivated()) {
    let list = JSON.parse(sessionStorage.getItem('scrollPositions') || '{}');
    list = list ? list : {};
    if (list[url]) {
      window.scroll({
        top: list[url],
        behavior: 'auto',
      });
    }
  }
};
