import React from 'react';
import { Link as StyleLink, Box, Flex, Center, Text } from '@chakra-ui/react';
import { TFunction, WithTranslation } from 'next-i18next';
import { NextPage } from 'next';
import { withTranslation } from 'next-i18next';
import Link from '../Link';
import { AccessScopeType, useUserScope } from 'utils/auth-utils';
import { css } from '@emotion/react';
import { Locale } from 'typings/shared';
import { useIsAuth } from '../../utils/auth-utils';

interface HeaderLink {
  text: string;
  link: string;
  weight: number;
  slug?: string;
  locale?: Locale;
}

const ClientHeaderContent: NextPage<
  WithTranslation & {
    close: () => void;
    setHideLogo: (s: boolean) => void;
    hideLogo: boolean;
  }
> = ({ t }) => {
  const scope = useUserScope();
  const isAuthenticated = useIsAuth();

  const servicesLinks = [...(t('headers.servicesDropdown', { returnObjects: true }) as Array<HeaderLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );
  const topicsLinks = [...(t('headers.topicsDropdown', { returnObjects: true }) as Array<HeaderLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );
  const moreLinks = [...(t('headers.moreDropdown', { returnObjects: true }) as Array<HeaderLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );

  return (
    <Flex w='100%' justify='center'>
      <Center px={4} display='flex' flexDir='column' w='100%' maxW='25rem'>
        <Link route={scope === AccessScopeType.client ? '/dashboard' : isAuthenticated ? '/pool' : '/login'}>
          <StyleLink
            display='flex'
            py={2}
            px={4}
            mt={4}
            w='100%'
            borderRadius='3px'
            border='1px'
            borderColor='blue.400'
            color='blue.400'
            fontWeight='semibold'
            _hover={{
              textDecor: 'none',
              borderColor: 'blue.500',
              color: 'blue.500',
            }}
            justifyContent='center'
          >
            {scope === AccessScopeType.client || isAuthenticated ? t('dashboard') : t('login')}
          </StyleLink>
        </Link>
        <Box w='100%' mt={4} mb={32}>
          <Text mt={6} mb={2} fontSize='1.375rem' fontWeight='bold' fontFamily='heading'>
            {t('common:services')}
          </Text>
          <Flex flexDir='column' sx={{ gap: '0.5rem' }}>
            {Array.isArray(servicesLinks) &&
              servicesLinks.length &&
              servicesLinks.map((serviceLink) =>
                serviceLink.locale && serviceLink.link ? (
                  <Link
                    key={serviceLink.link}
                    lang={serviceLink.locale}
                    route={serviceLink.link.replace(`/${serviceLink.locale}`, '')}
                  >
                    <StyleLink color='blue.400' fontWeight='semibold'>
                      {serviceLink.text}
                    </StyleLink>
                  </Link>
                ) : (
                  <Link key={serviceLink.link} route={serviceLink.link}>
                    <StyleLink color='blue.400' fontWeight='semibold'>
                      {serviceLink.text}
                    </StyleLink>
                  </Link>
                )
              )}
          </Flex>
          <Text mt={6} mb={2} fontSize='1.375rem' fontWeight='bold' fontFamily='heading'>
            {t('common:topics')}
          </Text>
          <Flex flexDir='column' sx={{ gap: '0.5rem' }}>
            {Array.isArray(topicsLinks) &&
              topicsLinks.length &&
              topicsLinks.map((topicLink) =>
                topicLink.locale && topicLink.link ? (
                  <Link
                    key={topicLink.link}
                    lang={topicLink.locale}
                    route={topicLink.link.replace(`/${topicLink.locale}`, '')}
                  >
                    <StyleLink color='blue.400' fontWeight='semibold'>
                      {topicLink.text}
                    </StyleLink>
                  </Link>
                ) : (
                  <Link key={topicLink.link} route={topicLink.link}>
                    <StyleLink color='blue.400' fontWeight='semibold'>
                      {topicLink.text}
                    </StyleLink>
                  </Link>
                )
              )}
          </Flex>
          <Text mt={6} mb={2} fontSize='1.375rem' fontWeight='bold' fontFamily='heading'>
            {t('common:more')}
          </Text>
          <Flex flexDir='column' sx={{ gap: '0.5rem' }}>
            {Array.isArray(moreLinks) &&
              moreLinks.length &&
              moreLinks.map((moreLink) =>
                moreLink.locale && moreLink.link ? (
                  <Link
                    key={moreLink.link}
                    lang={moreLink.locale}
                    route={moreLink.link.replace(`/${moreLink.locale}`, '')}
                  >
                    <StyleLink color='blue.400' fontWeight='semibold'>
                      {moreLink.text}
                    </StyleLink>
                  </Link>
                ) : (
                  <Link key={moreLink.link} route={moreLink.link}>
                    <StyleLink color='blue.400' fontWeight='semibold'>
                      {moreLink.text}
                    </StyleLink>
                  </Link>
                )
              )}
          </Flex>
        </Box>
      </Center>
    </Flex>
  );
};

export const UnstyledClientHeaderContent: NextPage<{
  close: () => void;
  setHideLogo: (s: boolean) => void;
  hideLogo: boolean;
  t: TFunction;
}> = ({ t }) => {
  const scope = useUserScope();
  const isAuthenticated = useIsAuth();

  const servicesLinks = [...(t('headers.servicesDropdown', { returnObjects: true }) as Array<HeaderLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );
  const topicsLinks = [...(t('headers.topicsDropdown', { returnObjects: true }) as Array<HeaderLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );
  const moreLinks = [...(t('headers.moreDropdown', { returnObjects: true }) as Array<HeaderLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );

  return (
    <Flex w='100%' justify='center'>
      <Center px={16} display='flex' flexDir='column' w='100%' maxW='25rem'>
        <Link route={scope === AccessScopeType.client ? '/dashboard' : isAuthenticated ? '/pool' : '/login'}>
          <StyleLink
            display='flex'
            py={8}
            px={16}
            mt={16}
            w='100%'
            borderRadius='3px'
            border='1px solid'
            borderColor='#4d6693'
            color='#4d6693'
            fontWeight='600'
            _hover={{
              textDecor: 'none',
              borderColor: '#3C5074',
              color: '#3C5074',
            }}
            justifyContent='center'
          >
            {scope === AccessScopeType.client || isAuthenticated ? t('dashboard') : t('login')}
          </StyleLink>
        </Link>
        <Box w='100%' mt={16} mb={128}>
          <Text mt={24} mb={8} fontSize='1.375rem' fontWeight='700' fontFamily='Merriweather'>
            {t('common:services')}
          </Text>
          <Flex
            flexDir='column'
            css={css`
              gap: 0.5rem;
            `}
          >
            {Array.isArray(servicesLinks) &&
              servicesLinks.length &&
              servicesLinks.map((serviceLink) =>
                serviceLink.locale && serviceLink.link ? (
                  <Link
                    key={serviceLink.link}
                    lang={serviceLink.locale}
                    route={serviceLink.link.replace(`/${serviceLink.locale}`, '')}
                  >
                    <StyleLink color='#4d6693' fontWeight='600'>
                      {serviceLink.text}
                    </StyleLink>
                  </Link>
                ) : (
                  <Link key={serviceLink.link} route={serviceLink.link}>
                    <StyleLink color='#4d6693' fontWeight='600'>
                      {serviceLink.text}
                    </StyleLink>
                  </Link>
                )
              )}
          </Flex>
          <Text mt={24} mb={8} fontSize='1.375rem' fontWeight='700' fontFamily='Merriweather'>
            {t('common:topics')}
          </Text>
          <Flex
            flexDir='column'
            css={css`
              gap: 0.5rem;
            `}
          >
            {Array.isArray(topicsLinks) &&
              topicsLinks.length &&
              topicsLinks.map((topicLink) =>
                topicLink.locale && topicLink.link ? (
                  <Link
                    key={topicLink.link}
                    lang={topicLink.locale}
                    route={topicLink.link.replace(`/${topicLink.locale}`, '')}
                  >
                    <StyleLink color='#4d6693' fontWeight='600'>
                      {topicLink.text}
                    </StyleLink>
                  </Link>
                ) : (
                  <Link key={topicLink.link} route={topicLink.link}>
                    <StyleLink color='#4d6693' fontWeight='600'>
                      {topicLink.text}
                    </StyleLink>
                  </Link>
                )
              )}
          </Flex>
          <Text mt={24} mb={8} fontSize='1.375rem' fontWeight='700' fontFamily='Merriweather'>
            {t('common:more')}
          </Text>
          <Flex
            flexDir='column'
            css={css`
              gap: 0.5rem;
            `}
          >
            {Array.isArray(moreLinks) &&
              moreLinks.length &&
              moreLinks.map((moreLink) =>
                moreLink.locale && moreLink.link ? (
                  <Link
                    key={moreLink.link}
                    lang={moreLink.locale}
                    route={moreLink.link.replace(`/${moreLink.locale}`, '')}
                  >
                    <StyleLink color='#4d6693' fontWeight='600'>
                      {moreLink.text}
                    </StyleLink>
                  </Link>
                ) : (
                  <Link key={moreLink.link} route={moreLink.link}>
                    <StyleLink color='#4d6693' fontWeight='600'>
                      {moreLink.text}
                    </StyleLink>
                  </Link>
                )
              )}
          </Flex>
        </Box>
      </Center>
    </Flex>
  );
};

export default withTranslation('common')(ClientHeaderContent);
