/** @type {import('next-i18next').UserConfig} */

const path = require('path');
const HttpBackend = require('i18next-http-backend/cjs');
const BackendAdapter = require('i18next-multiload-backend-adapter/cjs');

const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

module.exports = {
  i18n: {
    defaultLocale: 'de',
    locales: ['de', 'fr', 'en', 'it'],
  },
  backend: {
    backend: HttpBackend,
    backendOption: {
      allowMultiLoading: true,
      loadPath: () => {
        switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
          case 'staging':
            return 'https://staging.jurata.ch/api/locales?lng={{lng}}&ns={{ns}}';
          case 'production':
            return 'https://www.jurata.ch/api/locales?lng={{lng}}&ns={{ns}}';
          default:
            return 'http://localhost:3000/api/locales?lng={{lng}}&ns={{ns}}';
        }
      },
    },
  },
  use: [BackendAdapter],
  compatibilityJSON: 'v3',
  ns: [
    'aboutUs',
    'aboutUs-en',
    'investmentRound',
    'investmentRound-en',
    'caseClaim',
    'common',
    'poolConditions',
    'consultation',
    'contact',
    'dataSecurity',
    'editEngagement',
    'editFirmAndLocation',
    'editPracticeAreas',
    'editProfilePage',
    'filters',
    'forgotPassword',
    'forLawyers',
    'homePage',
    'languages',
    'lawyerProfile',
    'login',
    'mails',
    'onboarding',
    'pool',
    'requests',
    'search',
    'setPassword',
    'settings',
    'termsOfUse',
    'utils',
    'ie11',
    'error',
    'faq',
    'caseIntake',
    'clientDashboard',
    'myProfileClient',
    'register',
    'errorManagement',
    'commissionFees',
    'lawfirm',
  ],
  reloadOnPrerender: !isProduction,
  serializeConfig: false,
};
