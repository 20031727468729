import useUser from 'utils/auth-utils';
import { Lawyer } from 'graphql/queries/SingleLawyerQuery';
import {
  MIGRATION_TIMESTAMP,
  calculateMigrationDate,
  hasRequestsAfterMigration,
  writeAcceptanceDate,
} from 'utils/pool-utils';
import { getRESTLawyerById, lawyerAcceptTerms } from 'utils/react-query-api';
import { useMutation, useQuery as useReactQuery } from '@tanstack/react-query';
import { useQuery } from '@apollo/client';
import { withAuthHeaders } from 'graphql/mutations/utils';
import { parseCookies } from 'nookies';
import { subscriptionType } from 'typings/shared';
import { GET_MY_REQUESTS, Request as RequestType } from 'graphql/queries/Requests';

export const ACCEPT_TERMS_NAME = 'commission-migration-from-7/300-to-10/nocap';

export const useMigrationDate = () => {
  const { user: user, loading } = useUser() as { user: Lawyer; loading: boolean };

  const {
    data: restLawyer,
    error,
    refetch,
  } = useReactQuery(['getRESTLawyerById', user?._id], () => getRESTLawyerById(user?._id as string), {
    enabled: !!user?._id,
  });

  const term = restLawyer?.acceptedTerms?.find((acceptedTerm: any) => acceptedTerm.name === ACCEPT_TERMS_NAME);

  return {
    data: term && calculateMigrationDate(new Date(term.timestamp)),
    loading: loading,
    error,
    refetch,
  };
};

export const useAcceptanceDate = () => {
  const { user: user, loading } = useUser() as { user: Lawyer; loading: boolean };

  const { data: restLawyer, error } = useReactQuery(
    ['getRESTLawyerById', user?._id],
    () => getRESTLawyerById(user?._id as string),
    {
      enabled: !!user?._id,
    }
  );

  const term = restLawyer?.acceptedTerms?.find((acceptedTerm: any) => acceptedTerm.name === ACCEPT_TERMS_NAME);

  return {
    data: term && writeAcceptanceDate(new Date(term.timestamp)),
    loading,
    error,
  };
};

export const useMigrationDue = () => {
  const {
    user: user,
    loading,
    refetch,
  } = useUser() as { user: Lawyer; loading: boolean; refetch: (...rest: any) => Promise<any> };
  const { token } = parseCookies();

  const { data: lawyerRequests } = useQuery<{ requests: RequestType[] }>(GET_MY_REQUESTS, {
    ssr: false,
    context: { ...withAuthHeaders },
    fetchPolicy: 'network-only',
    skip: !token,
  });

  const { data: restLawyer, refetch: refetchRESTLawyer } = useReactQuery(
    ['getRESTLawyerById', user?._id],
    () => getRESTLawyerById(user?._id as string),
    {
      enabled: !!user?._id,
    }
  );

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return lawyerAcceptTerms(user._id, {
        ...data,
      });
    },
    onSuccess: async () => {
      await refetch();
      await refetchRESTLawyer();
    },
  });

  let isMigrationDue = false;

  if (restLawyer) {
    const hasAcceptedTerms = !!restLawyer.acceptedTerms.find((acceptedTerm) => acceptedTerm.name === ACCEPT_TERMS_NAME);

    if (
      !hasAcceptedTerms &&
      lawyerRequests?.requests &&
      user?.accountType === subscriptionType.STANDARD &&
      '' + user?.billing?.commission?.percentage === '7'
    ) {
      if (hasRequestsAfterMigration(lawyerRequests.requests, MIGRATION_TIMESTAMP)) {
        isMigrationDue = true;
      }
    }
  }

  return {
    data: isMigrationDue,
    loading,
    mutation,
  };
};
