import { CaseIntakeType } from 'utils/case-intake';
import { Billing } from './jurata';
import { Asset, Firm } from 'graphql/queries/SingleLawyerQuery';

export const subscriptionType = {
  STANDARD: 'Standard',
  FREE: 'Free',
  PREMIUM: 'Premium',
} as const;
export type SubscriptionType = typeof subscriptionType[keyof typeof subscriptionType];

export const billingType = {
  YEARLY: 'Year',
  MONTHLY: 'Month',
} as const;
export type BillingType = typeof billingType[keyof typeof billingType];

export const gender = {
  // the following are not PascalCase due to backward compatibility
  MALE: 'male',
  FEMALE: 'female',
} as const;
export type Gender = typeof gender[keyof typeof gender];

export type PageInitialProps<T = {}> = ({ namespacesRequired?: string[] } & T) | { error: Error };

export type Query = Record<string, string | string[] | undefined>;

export type SuggestionOption = 'lawyers' | 'practiceareas' | 'places' | 'cantons' | 'firms';

export type FilterOption = 'experienceGroup' | 'savSpecialist' | 'lawFirmSizeGroup' | 'available' | 'languages';

export const suggestionOptions: SuggestionOption[] = ['lawyers', 'practiceareas', 'places', 'cantons', 'firms'];
export const filterOptions: FilterOption[] = [
  'experienceGroup',
  'savSpecialist',
  'lawFirmSizeGroup',
  'available',
  'languages',
];

export type LawyerSearchParam = SuggestionOption | FilterOption | 'page';

export type AllLawyerSearchParams = { [key in LawyerSearchParam]?: string };

export interface UrlHistory {
  urlHistory: string[];
}

export const poolCaseStatus = {
  AVAILABLE: 'Available',
  ONGOING: 'Ongoing',
  CLOSED: 'Closed',
} as const;
export type PoolCaseStatus = typeof poolCaseStatus[keyof typeof poolCaseStatus];

export interface PracticeAreaGroup {
  _id: string;
  name_de: string;
  name_it: string;
  name_fr: string;
  name_en: string;
  slug_en: string;
  slug_de: string;
  slug_it: string;
  slug_fr: string;
}

export interface LocalizedPracticeAreaGroup extends PracticeAreaGroup {
  name: string;
}

export interface PoolCase {
  description: string;
  practiceAreaGroups?: PracticeAreaGroup[];
  title?: string;
  presentedDescription?: string;
  location?: { canton: string; place: string };
  _id: string;
  createdAt: string;
  price: number;
  replyCount?: number;
  status: PoolCaseStatus;
  checkedBy: string;
  releasedToPool?: string;
  claimControl?: {
    godMode?: string;
    requestLimitForCase?: number;
    requestLimitForPool?: number;
  };
}

export interface Client {
  name: string;
  email: string;
  phone?: string;
  locale?: string;
  gender?: Gender;
  companyName?: string;
  type?: CaseIntakeType;
}

export const lawyerUserInterfaceType = {
  CLICKED_CASE_REACTION: 'Clicked on Case Reaction',
  CLICKED_CASE_CLAIM_MODAL: 'Clicked Radio Button Case Claim Modal',
  VISITED_CASE_POOL_INFO_MODAL: 'Visited Case Pool Info Modal',
  CLICKED_RESPOND_TO_PERSONAL_REQUEST: 'Clicked Respond to Personal Request',
  VIEWED_PRICING_PACKAGE: 'Viewed Pricing Package',
  VIEWED_PRICING_OVERVIEW: 'Viewed Pricing Overview',
  LAWYER_INVITED_TO_CASE: 'Lawyer Invited to Case',
  LAWYER_LOGIN: 'Lawyer Login',
} as const;
export type LawyerUserInterfaceType = typeof lawyerUserInterfaceType[keyof typeof lawyerUserInterfaceType];

export const clientMode = {
  PRIVATE: 'private',
  BUSINESS: 'business',
} as const;
export type ClientMode = typeof clientMode[keyof typeof clientMode];

export const locale = {
  DE: 'de',
  FR: 'fr',
  EN: 'en',
  IT: 'it',
} as const;
export type Locale = typeof locale[keyof typeof locale];

export interface PracticeArea {
  preference: number;
  practiceArea: {
    _id: string;
    slug: string;
    display: string;
    link: string;
    name: string;
  };
}

export interface RESTLawyer {
  _id: string;
  hidden: boolean;
  slug: string;
  firstName: string;
  lastName: string;
  hasFirstConsultation: boolean;
  firstConsultationDuration: string;
  firstConsultationPrice: string;
  hasHourlyRate: boolean;
  hourlyRate: string;
  lawyerAdmissionYear: number;
  lawyerAdmissionCanton: string;
  firmFunction: string;
  avatar: Asset;
  available: boolean;
  title: string;
  specialCertificates: string[];
  email: string;
  phone: string;
  gender: string;
  profileText: {
    manual: string;
    generated: string;
  };
  profileTextSelection: string;
  languages: {
    lang: string;
    kind: 'correspondance' | 'mother' | 'other';
    _id: string;
  }[];
  firm: Firm | null;
  experience: {
    from: string;
    to: string | null;
    function: string;
    office: string;
  }[];
  education: {
    date: string;
    title: string;
    university: string;
  }[];
  hideAge: boolean;
  yearOfBirth: number;
  memberships: string[];
  publications: {
    link: string;
    publication: string;
    title: string;
  }[];
  awards: string[];
  practiceAreas: PracticeArea[];
  signUpStatus: 'unclaimed' | 'claimed' | 'onboarded';
  accountType: SubscriptionType;
  subscriptionPrice?: string;
  preferredContact: 'message' | 'callback';
  stripeCustomerId: string;
  showContactData: boolean;
  showClientMessageInEmail: boolean;
  subscriptionPeriod: BillingType;
  termsOfUseAccepted: string;
  mediator: string;
  billing: Billing;
  acceptedTerms: Array<{
    name: string;
    timestamp: string;
    description: string;
    ipAddress: string;
  }>;
  showAIReactionModal?: boolean;
}
