module.exports = [
  { language: 'en', localName: '/', pathName: 'index', source: '/', destination: '/en' },
  { language: 'en', localName: '/', pathName: '/', source: '/', destination: '/en' },
  {
    language: 'en',
    localName: 'lawyers',
    pathName: 'lawyers',
    source: '/en/lawyers/:slugs*',
    destination: '/en/lawyers',
  },
  {
    language: 'en',
    localName: 'lawyer',
    pathName: 'lawyer/[slug]',
    source: '/en/lawyer/:slug',
    destination: '/en/lawyer/:slug',
  },
  {
    language: 'en',
    localName: 'lawyer',
    pathName: 'lawyer',
    source: '/en/lawyer',
    destination: '/en/lawyer',
  },
  {
    language: 'en',
    localName: 'inquiry-pool/conditions',
    pathName: 'pool/conditions',
    source: '/en/inquiry-pool/conditions',
    destination: '/en/pool/conditions',
  },
  {
    language: 'en',
    localName: 'inquiry-pool/claim',
    pathName: 'pool/claim/[caseId]',
    source: '/en/inquiry-pool/claim/:caseId',
    destination: '/en/pool/claim/:caseId',
  },
  {
    language: 'en',
    localName: 'investment-round',
    pathName: 'news/investment-round',
    source: '/en/investment-round',
    destination: '/en/news/investment-round',
  },
  {
    language: 'en',
    localName: 'places',
    pathName: 'directory/places',
    source: '/en/places',
    destination: '/en/directory/places',
  },
  {
    language: 'en',
    localName: 'places',
    pathName: 'directory/places/[letter]',
    source: '/en/places/:letter',
    destination: '/en/directory/places/:letter',
  },
  {
    language: 'en',
    localName: 'all-lawyers',
    pathName: 'directory/all-lawyers',
    source: '/en/all-lawyers',
    destination: '/en/directory/all-lawyers',
  },
  {
    language: 'en',
    localName: 'all-lawyers',
    pathName: 'directory/all-lawyers/[letter]',
    source: '/en/all-lawyers/:letter',
    destination: '/en/directory/all-lawyers/:letter',
  },
  {
    language: 'en',
    localName: 'law-firms',
    pathName: 'directory/law-firms',
    source: '/en/law-firms',
    destination: '/en/directory/law-firms',
  },
  {
    language: 'en',
    localName: 'law-firms',
    pathName: 'directory/law-firms/[letter]',
    source: '/en/law-firms/:letter',
    destination: '/en/directory/law-firms/:letter',
  },
  {
    language: 'en',
    localName: 'practice-areas',
    pathName: 'directory/practice-areas',
    source: '/en/practice-areas',
    destination: '/en/directory/practice-areas',
  },
  {
    language: 'en',
    localName: 'practice-areas',
    pathName: 'directory/practice-areas/[slug]',
    source: '/en/practice-areas/:slug',
    destination: '/en/directory/practice-areas/:slug',
  },
  {
    language: 'en',
    localName: 'cantons',
    pathName: 'directory/cantons',
    source: '/en/cantons',
    destination: '/en/directory/cantons',
  },
  { language: 'en', localName: 'login', pathName: 'login', source: '/en/login', destination: '/en/login' },
  {
    language: 'en',
    localName: 'firm',
    pathName: 'firm',
    source: '/en/firm/:firm',
    destination: '/en/lawyers?firms=:firm',
  },
  { language: 'en', localName: 'firm', pathName: 'firm', source: '/en/firm', destination: '/en/lawyers' },
  {
    language: 'en',
    localName: 'my-profile',
    pathName: 'profile/my-profile',
    source: '/en/my-profile',
    destination: '/en/profile/my-profile',
  },
  {
    language: 'en',
    localName: 'my-profile/profile/edit',
    pathName: 'profile/edit-profile',
    source: '/en/my-profile/profile/edit',
    destination: '/en/profile/edit-profile',
  },
  {
    language: 'en',
    localName: 'my-profile/profile/edit-short-profile',
    pathName: 'profile/edit-short-profile',
    source: '/en/my-profile/profile/edit-short-profile',
    destination: '/en/profile/edit-short-profile',
  },
  {
    language: 'en',
    localName: 'my-profile/practice-areas/edit',
    pathName: 'profile/edit-practice-areas',
    source: '/en/my-profile/practice-areas/edit',
    destination: '/en/profile/edit-practice-areas',
  },
  {
    language: 'en',
    localName: 'my-profile/career/edit',
    pathName: 'profile/edit-career',
    source: '/en/my-profile/career/edit',
    destination: '/en/profile/edit-career',
  },
  {
    language: 'en',
    localName: 'my-profile/engagement/edit',
    pathName: 'profile/edit-engagement',
    source: '/en/my-profile/engagement/edit',
    destination: '/en/profile/edit-engagement',
  },
  {
    language: 'en',
    localName: 'requests',
    pathName: 'requests',
    source: '/en/requests/:ids*',
    destination: '/en/requests',
  },
  {
    language: 'en',
    localName: 'inquiry-pool',
    pathName: 'pool',
    source: '/en/inquiry-pool/:id*',
    destination: '/en/pool',
  },
  {
    language: 'en',
    localName: 'receive-mandates',
    pathName: 'for-lawyers',
    source: '/en/receive-mandates',
    destination: '/en/for-lawyers',
  },
  {
    language: 'en',
    localName: 'onboarding',
    pathName: 'onboarding',
    source: '/en/onboarding',
    destination: '/en/onboarding',
  },
  {
    language: 'en',
    localName: 'forgot-password',
    pathName: 'forgot-password',
    source: '/en/forgot-password',
    destination: '/en/forgot-password',
  },
  {
    language: 'en',
    localName: 'settings',
    pathName: 'settings',
    source: '/en/settings',
    destination: '/en/settings',
  },
  {
    language: 'en',
    localName: 'settings/account',
    pathName: 'settings/account',
    source: '/en/settings/account',
    destination: '/en/settings/account',
  },
  {
    language: 'en',
    localName: 'settings/subscription',
    pathName: 'settings/subscription',
    source: '/en/settings/subscription',
    destination: '/en/settings/subscription',
  },
  {
    language: 'en',
    localName: 'subscription',
    pathName: 'subscription-public',
    source: '/en/subscription/:slug',
    destination: '/en/subscription-public?slug=:slug',
  },
  {
    language: 'en',
    localName: 'subscription',
    pathName: 'subscription-public',
    source: '/en/subscription',
    destination: '/en/subscription-public',
  },
  {
    language: 'en',
    localName: 'presubscription',
    pathName: 'presubscription',
    source: '/en/presubscription',
    destination: '/en/presubscription',
  },
  {
    language: 'en',
    localName: 'set-password',
    pathName: 'set-password',
    source: '/en/set-password/:token',
    destination: '/en/set-password?token=:token',
  },
  {
    language: 'en',
    localName: 'set-password',
    pathName: 'set-password',
    source: '/en/set-password',
    destination: '/en/set-password',
  },
  {
    language: 'en',
    localName: 'contact',
    pathName: 'info/contact',
    source: '/en/contact',
    destination: '/en/info/contact',
  },
  {
    language: 'en',
    localName: 'faq',
    pathName: 'info/faq',
    source: '/en/faq',
    destination: '/en/info/faq',
  },
  {
    language: 'en',
    localName: 'terms-of-use',
    pathName: 'info/terms-of-use',
    source: '/en/terms-of-use',
    destination: '/en/info/terms-of-use',
  },
  {
    language: 'en',
    localName: 'data-protection',
    pathName: 'info/data-protection',
    source: '/en/data-protection',
    destination: '/en/info/data-protection',
  },
  {
    language: 'en',
    localName: 'case-intake',
    pathName: 'case-intake',
    source: '/en/case-intake',
    destination: '/en/case-intake',
  },
  {
    language: 'en',
    localName: 'case',
    pathName: 'case/[caseId]',
    source: '/en/case/:caseId',
    destination: '/en/case/:caseId',
  },
  {
    language: 'en',
    localName: 'dashboard/my-profile',
    pathName: 'dashboard/my-profile',
    source: `/en/dashboard/my-profile`,
    destination: `/en/dashboard/my-profile`,
  },
  {
    language: 'en',
    localName: 'dashboard',
    pathName: 'dashboard',
    source: `/en/dashboard`,
    destination: `/en/dashboard`,
  },
  {
    language: 'en',
    localName: 'register',
    pathName: 'register',
    source: '/en/register',
    destination: '/en/register',
  },
  {
    language: 'en',
    localName: 'commission-fees',
    pathName: 'commission-fees',
    source: '/en/commission-fees',
    destination: '/en/commission-fees',
  },
  {
    source: '/sitemap.xml',
    destination: '/en/sitemap.xml',
  },
  {
    language: 'en',
    localName: 'my-law-firm/switch',
    pathName: 'law-firm/change-law-firm',
    source: '/en/my-law-firm/switch',
    destination: '/en/law-firm/change-law-firm',
  },
  {
    language: 'en',
    localName: 'my-law-firm/edit',
    pathName: 'law-firm/edit-law-firm',
    source: '/en/my-law-firm/edit',
    destination: '/en/law-firm/edit-law-firm',
  },
  {
    language: 'en',
    localName: 'my-law-firm/new-law-firm',
    pathName: 'law-firm/new-law-firm',
    source: '/en/my-law-firm/new-law-firm',
    destination: '/en/law-firm/new-law-firm',
  },
  {
    language: 'en',
    localName: 'my-law-firm',
    pathName: 'law-firm/my-law-firm',
    source: '/en/my-law-firm',
    destination: '/en/law-firm/my-law-firm',
  },
];
