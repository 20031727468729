import { gql } from '@apollo/client';
import { ClientMode } from 'typings/shared';

export type CountIndex = 'practiceAreas' | 'places' | 'cantons' | 'firms';

type CountOption = CountIndex | 'total';

export interface CountInfo {
  name: string;
  count: number;
  slug: string;
  clientType: ClientMode | 'both';
  municipality?: string;
  legacyId?: string;
  group?: { name: string };
  cantonCode?: string;
  name_de?: string;
  towns?: Array<string>;
}
export type CountValuesData = CountInfo[];

export interface CountValues {
  total: number;
  data: CountValuesData;
}

export interface LocalizedCountValues {
  items: CountInfo[];
}

export type LocalizedIndexCounts = Partial<Record<CountIndex, LocalizedCountValues>>;

type IndexCounts = Partial<Record<CountIndex, CountValues>>;

export interface Counts extends IndexCounts {
  total?: number;
}

export interface CountsResults {
  JurataCountIndex: Counts;
}

export const COUNTS_QUERY = (counts: CountOption[]) => gql`
  query ($limit: Int) {
    JurataCountIndex(limit: $limit) {
      ${counts.join('\n')}
    }
  }
`;

export const NEXTJS_API_CONTENT_RESOURCE_QUERY = gql`
  query NextjsApiCountsQuery {
    countsQuery(locale: $locale)
      @rest(method: "GET", type: "CountsQuery", path: "/api/counts-query?locale={args.locale}") {
      practiceAreas {
        items {
          name
          name_de
          legacyId
          slug
          practiceAreaGroup {
            name
          }
        }
      }
      cantons {
        items {
          name
          cantonCode
          slug
        }
      }
      places {
        items {
          slug
          canton {
            name
            slug
          }
          municipality
          towns
          coordinates {
            lat
            lon
          }
          legacyId
        }
      }
    }
  }
`;
