import React from 'react';
import { ArrowBackIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  UseDisclosureReturn,
  Link as StyleLink,
  Flex,
  Box,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { SVGImage } from 'jurata-styleguide/src/elements/svg-image/svg-image';
import ClientHeaderContent, { UnstyledClientHeaderContent } from './headers/ClientHeaderContent';
import logo from 'jurata-styleguide/src/assets/images/logo.svg';
import { useTranslation } from 'next-i18next';
import Link from './Link';
import Image from 'next/image';

interface MenuProps {
  drawer: UseDisclosureReturn;
  modal: UseDisclosureReturn;
  closeModal: () => void;
  isAuthenticated: boolean;
  isClient: boolean;
}

const HeaderMenu: React.FC<MenuProps> = ({ drawer, modal, closeModal }) => {
  const [hideLogo, setHideLogo] = React.useState<boolean>(false);
  const [t] = useTranslation();
  const btnRef = React.useRef();

  return (
    <>
      <Drawer
        size={'md'}
        isOpen={drawer.isOpen}
        placement='right'
        onClose={() => {
          drawer.onClose();
          setHideLogo(false);
        }}
        finalFocusRef={btnRef as any}
        autoFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent bg='white'>
          <DrawerHeader
            borderBottom='1px solid gray'
            borderBottomColor='gray.200'
            display='flex'
            backgroundColor='white'
            alignItems='center'
            minH={75}
            m={0}
            pl='8'
            zIndex={'modal'}
          >
            {hideLogo ? (
              <StyleLink
                display='flex'
                alignItems='center'
                fontWeight='semibold'
                color='blue.400'
                fontSize='md'
                onClick={() => setHideLogo(false)}
              >
                <ArrowBackIcon mr='2' /> {t('common:goBack')}
              </StyleLink>
            ) : (
              <SVGImage
                svg={logo}
                cssStyles={css`
                  height: 28px;
                `}
              />
            )}
          </DrawerHeader>
          <DrawerCloseButton color='black' mt={'14px'} mr={'16px'} zIndex='popover' />
          <DrawerBody p={0} overflow='auto'>
            <ClientHeaderContent hideLogo={hideLogo} setHideLogo={setHideLogo} close={drawer.onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Modal autoFocus={false} scrollBehavior={'inside'} onClose={closeModal} size={'full'} isOpen={modal.isOpen}>
        <ModalContent backgroundColor='white' borderRadius='0'>
          <ModalHeader
            borderBottom='1px'
            borderBottomColor='gray.150'
            display='flex'
            backgroundColor='gray.100'
            alignItems='center'
            minH='4.75rem'
            maxW='73.75rem'
            justifyContent='space-between'
            m={0}
            px={{ base: 4, md: 6 }}
            sx={{ gap: '1rem' }}
            flexWrap='nowrap'
            zIndex={'modal'}
            w='100%'
          >
            <Link route='/'>
              <StyleLink display='flex'>
                <Flex>
                  <Image priority alt='Signet Logo' src='/icons/signet-blue.svg' height={36} width={18} />
                </Flex>
              </StyleLink>
            </Link>
            <Box p={2} onClick={closeModal} _hover={{ cursor: 'pointer' }}>
              <CloseIcon color='black' w={4} h={4} zIndex='popover' />
            </Box>
          </ModalHeader>
          <ModalBody p={0} overflow='auto'>
            <ClientHeaderContent hideLogo={hideLogo} setHideLogo={setHideLogo} close={closeModal} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export const UnstyledHeaderMenu: React.FC<MenuProps> = ({ drawer, modal, closeModal }) => {
  const [hideLogo, setHideLogo] = React.useState<boolean>(false);
  const [t] = useTranslation();
  const btnRef = React.useRef();

  return (
    <>
      <Drawer
        size={'md'}
        isOpen={drawer.isOpen}
        placement='right'
        onClose={() => {
          drawer.onClose();
          setHideLogo(false);
        }}
        finalFocusRef={btnRef as any}
        autoFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent
          bg='white'
          zIndex={1400}
          maxH='100vh'
          maxW='32rem'
          boxShadow='0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);'
        >
          <DrawerHeader
            borderBottom='1px solid'
            borderColor='#d8d8d8'
            display='flex'
            backgroundColor='white'
            alignItems='center'
            minH={76}
            m={0}
            py={16}
            pl={32}
            pr={24}
            zIndex={1400}
          >
            {hideLogo ? (
              <StyleLink
                display='flex'
                alignItems='center'
                fontWeight='600'
                color='#4d6693'
                fontSize='16'
                onClick={() => setHideLogo(false)}
              >
                <ArrowBackIcon mr='2' /> {t('common:goBack')}
              </StyleLink>
            ) : (
              <SVGImage
                svg={logo}
                cssStyles={css`
                  height: 28px;
                `}
              />
            )}
          </DrawerHeader>
          <DrawerCloseButton
            bg='none'
            w='2rem'
            h='2rem'
            color='black'
            mt='0.875rem'
            mr='1rem'
            zIndex={1500}
            pos='absolute'
            top='0.5rem'
            right='0.75rem'
            _hover={{ bg: 'rgba(0, 0, 0, 0.06)' }}
            borderRadius='0.375rem'
            css={css`
              & svg {
                width: 0.75rem;
                height: 0.75rem;
              }
            `}
          />
          <DrawerBody p={0} overflow='auto'>
            <UnstyledClientHeaderContent t={t} hideLogo={hideLogo} setHideLogo={setHideLogo} close={drawer.onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Modal autoFocus={false} scrollBehavior={'inside'} onClose={closeModal} isOpen={modal.isOpen}>
        <ModalContent backgroundColor='white' borderRadius='0' w='100vw' h='100vh'>
          <ModalHeader
            borderBottom='1px solid'
            borderColor='#e6e6e6'
            display='flex'
            backgroundColor='#f6f5f3'
            alignItems='center'
            minH='4.75rem'
            maxW='73.75rem'
            justifyContent='space-between'
            m={0}
            py={16}
            css={css`
              gap: 1rem;
              padding: 0 1rem;
              @media (min-width: 48em) {
                padding: 0 1.5rem;
              }
            `}
            flexWrap='nowrap'
            w='100%'
          >
            <Link route='/'>
              <StyleLink display='flex'>
                <Flex>
                  <Image priority alt='Signet Logo' src='/icons/signet-blue.svg' height={36} width={18} />
                </Flex>
              </StyleLink>
            </Link>
            <CloseIcon color='black' w={16} h={16} mr={14} zIndex={1500} onClick={closeModal} />
          </ModalHeader>
          <ModalBody p={0} overflow='auto'>
            <UnstyledClientHeaderContent t={t} hideLogo={hideLogo} setHideLogo={setHideLogo} close={closeModal} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HeaderMenu;
