import { gql } from '@apollo/client';
import { Gender, Locale } from 'typings/shared';

export interface Client {
  _id: string;
  firstName: string;
  lastName: string;
  gender: Gender;
  phone?: string;
  email: string;
  legalInsurance?: string;
  type: 'Private' | 'Business';
  locale: Locale;
  companyName?: string;
  emailVerified?: boolean;
}

export interface GetSingleClient {
  client: Client;
}

export const GET_AUTHENTICATED_CLIENT = gql`
  query RestClient($id: string) {
    client(id: $id) @rest(method: "GET", type: "AuthenticatedClient", path: "/api/client/{args.id}") {
      _id
      gender
      firstName
      lastName
      email
      phone
      legalInsurance
      type
      locale
      companyName
      emailVerified
    }
  }
`;
