const IS_SERVER = typeof window === 'undefined';

function getProtocol() {
  const isProd = process.env.VERCEL_ENV === 'production';
  if (isProd) return 'https://';
  return 'http://';
}

export function getAbsoluteUrl() {
  if (!IS_SERVER) {
    return location.origin;
  }
  const protocol = getProtocol();
  if (process.env.VERCEL_URL && process.env.NEXT_PUBLIC_ENVIRONMENT !== 'local') {
    return `${protocol}${process.env.VERCEL_URL}`;
  } else {
    return `${protocol}localhost:3000`;
  }
}
