import React, { useState, useEffect } from 'react';
import { SubscriptionType, subscriptionType } from '../typings/shared';

export const getSecondToLastFromArray = (arr: string[]): string => {
  if (arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  else return arr[arr.length - 2];
};

export const splitCommaReducer = (acc: any, slug: any) => {
  const parsedSlugs = slug.split(',');

  parsedSlugs.sort().forEach((el: string) => (acc = [el, ...acc]));

  return acc;
};

export const useDebounce = (value: string | null, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export const apostrophyNumber = (number: number | string | undefined) =>
  `${(number || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'")}`;

export const firstLetterToUppercase = (str: string) =>
  str.charAt(0) === str.charAt(0).toUpperCase() ? str : str.charAt(0).toUpperCase() + str.slice(1) || '';

export const getExternalURL = (url: string) => (url ? (url.indexOf('://') !== -1 ? url : `//${url}`) : '');

// Stop using this and switch to GraphQL local state
export const useStateWithSessionStorage = (
  sessionStorageKey: string
): [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>] => {
  const [value, setValue] = useState<string | undefined>();

  React.useEffect(() => {
    if (value !== undefined && isSSActivated()) {
      sessionStorage.setItem(sessionStorageKey, value);
    }
  }, [value]);

  React.useEffect(() => {
    if (isSSActivated()) {
      setValue(sessionStorage.getItem(sessionStorageKey) || '');
    }
  }, []);

  return [value, setValue];
};

export const mailtoData = (to: string, subject: string, opening: string, body: string, closing: string) =>
  `mailto:${to}?subject=${subject}&body=${opening}%0D%0A%0D%0A${body}%0D%0A%0D%0A${closing}`;

export const isUserNewStandard = (accountType: SubscriptionType, termsOfUseAccepted?: string) => {
  if (accountType === subscriptionType.STANDARD) {
    if (!termsOfUseAccepted) {
      return false;
    }
    const terms = new Date(termsOfUseAccepted).valueOf();
    const newDate = new Date('2021-10-07 23:59:59').valueOf();
    if (terms - newDate > 0) {
      return true;
    }
  }
  return false;
};

export const isSSActivated = () => {
  const test = 'test';
  try {
    sessionStorage.setItem(test, test);
    sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const extractStringsBetweenSquareBrackets = (text: string): Array<string> => {
  // Extract values between square brackets
  const pattern = /\[(.*?)\]/g;
  const matches = text.match(pattern);
  let result: Array<string> = [];

  if (matches) {
    for (const match of matches) {
      result.push(match.substring(1, match.length - 1));
    }
  }

  return result;
};
