import { gql } from '@apollo/client';
import { SearchLawyer } from '../../typings/jurata';

export interface Aggregations {
  available: number;
  savSpecialist: number;
  experienceGroup: {
    '1_4': number;
    '5_15': number;
    '15_30': number;
    '30_': number;
  };
  lawFirmSizeGroup: {
    small: number;
    medium: number;
    large: number;
  };
  languages: LanguageAggregate;
}
interface SimilarCount {
  total: number;
  data: {
    name: string;
    count: number;
    slug: string;
  }[];
}

export interface LanguageAggregate {
  [key: string]: number;
}

export interface LawyerSearchResults {
  JurataSearchIndex: {
    count: number;
    aggregations: Aggregations;
    nearby: string;
    items: { payload: SearchLawyer }[];
  };
  JurataCountIndex: {
    places: SimilarCount;
    practiceAreas: SimilarCount;
  };
}

export interface LawyerSearchVariables {
  filters: string;
  offset: number;
  limit: number;
  filterByCanton: string | undefined;
}

export interface AuthenticatedLawyerSearchVariables {
  filters: string;
  limit: number;
}

export interface AuthenticatedLawyerSearchresults {
  JurataSearchIndex: {
    items: { payload: SearchLawyer }[];
  };
}

export const LAWYER_SEARCH_QUERY = gql`
  query ($filters: String, $offset: Int, $limit: Int, $filterByCanton: String) {
    JurataSearchIndex(nearby: true, query: $filters, offset: $offset, limit: $limit) {
      count
      aggregations
      nearby
      items {
        payload
      }
    }
    JurataCountIndex(queryPlaces: $filterByCanton, queryPracticeAreas: $filterByCanton, limit: 11) {
      places
      practiceAreas
    }
  }
`;

export const AUTHENTICATED_LAWYER_SEARCH = gql`
  query ($limit: Int, $filters: String) {
    JurataSearchIndex(limit: $limit, query: $filters) {
      items {
        payload
      }
    }
  }
`;
