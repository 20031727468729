import React from 'react';

import { CommonHtmlProps } from '../../../typings/html';

import { styles } from './svg-image.styles';
import { SerializedStyles } from '@emotion/react';

export interface SVGIconProps {
  svg: string;
}

export const SVGImage: React.FC<SVGIconProps & CommonHtmlProps> = ({ svg, cssStyles }) => {
  return <div css={[styles.svgImage, cssStyles as SerializedStyles]} dangerouslySetInnerHTML={{ __html: svg }} />;
};
