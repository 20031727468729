import { Global, css } from '@emotion/react';

export const GlobalStyles = () => {
  return <Global styles={styles} />;
};

const styles = css`
  :root {
    --nprogresscolor: white;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  address {
    font: inherit;
  }

  * {
    border: 0;
    box-sizing: border-box;
    margin: 0;
    outline-color: #4bb2df;
    padding: 0;
    vertical-align: baseline;

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ol,
  li {
    list-style: none;
  }

  a {
    display: block;
    text-decoration: none;
  }

  p a {
    display: inline;
  }

  a:active,
  a:focus,
  a:hover,
  a:visited {
    text-decoration: none;
  }

  button,
  input {
    background: none;
    border: none;
    border-radius: 0;
    color: inherit;
    margin: 0;
    padding: 0;
  }

  html {
    height: 100%;
  }

  body,
  [id='__next'] {
    margin: 0;
    min-height: 100vh;
    padding: 0;
  }

  body {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    @media (min-width: 767px) {
      font-size: 1.0625rem;
    }

    &.js-jurata-styleguide-scroll-lock {
      overflow: hidden;
    }
  }

  [id='__next'] {
    display: flex;
    flex-direction: column;
  }

  b {
    font-weight: 600;
  }

  main {
    display: contents;
  }
`;

export const UnstyledGlobal = () => {
  return <Global styles={modalStyles} />;
};

const modalStyles = css`
  & .chakra-modal__content-container {
    z-index: 1400;
  }
`;
