import { NextRouter } from 'next/router';
import internationalisedRoutes from '../routes/index.js';

type Languages = 'de' | 'fr';

export interface InternationalisedRoutes {
  pathName: string;
  localName: string;
  language: Languages;
}

interface DestinationObject {
  route: string;
  pathVars?: Record<string, string | Array<string | undefined> | undefined>;
  query?: any;
}

export const i18nPath = (path: string, language = 'de') => {
  if (!path || typeof path !== 'string') return '/';
  path = path.replace(/\/$/, '').replace(/^\//, '');
  language = language.replace(/\/$/, '').replace(/^\//, '');
  const hit = (internationalisedRoutes as InternationalisedRoutes[]).find(
    (route) => route.language === language && path === route.pathName
  );
  if (!hit) {
    return `${path}`;
  }
  const t = hit.localName[0] === '/' ? hit.localName.slice(1) : hit.localName;
  return `/${t}`;
};

export const pushi18nRoute = (router: NextRouter, destination: DestinationObject, options: any = {}) => {
  const { query, route, pathVars } = destination;
  const language = router.locale;
  const replace = options.replace;
  delete options.replace;

  const pathVariables =
    (pathVars ? Object.values(pathVars).flat() : []).filter((v) => !!v).reduce((p, c) => p + '/' + c, '') || '';

  const translatedPathname = `/${language || 'de'}/${i18nPath(route, language)}${pathVariables}`.replace('//', '/');

  const href = {
    pathname: `/${route}`.replace('//', '/'),
    query: query || pathVars ? Object.assign(query || {}, pathVars || {}) : undefined,
  };

  const asQuery = Object.assign({}, query);
  for (const queryKey in asQuery) {
    if (typeof pathVars === 'object' && pathVars !== null && !Array.isArray(pathVars) && pathVars[queryKey])
      delete asQuery[queryKey];
  }
  const as = { pathname: translatedPathname, query: asQuery };

  return replace ? router.replace(href, as, options) : router.push(href, as, options);
};
