import { ApolloClient, InMemoryCache, ApolloLink, ServerError, HttpLink } from '@apollo/client/core';
import unfetch from 'isomorphic-unfetch';
import { parseCookies } from 'nookies';
import { RestLink } from 'apollo-link-rest';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { LawyerSearchResults } from 'graphql/queries/LawyerSearchQuery';
import { SearchLawyer } from 'typings/jurata';
import { expire } from 'utils/auth-utils';
import { LAWYERS_PER_PAGE } from 'hooks/lawyer-search';
import jwtDecode from 'jwt-decode';
import { getAbsoluteUrl } from 'utils/vercel';

const isServer = typeof window === 'undefined';
const windowApolloState = !isServer && (window.__NEXT_DATA__ as any).apolloState;
const isNotProduction = process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production';

let CLIENT: any;

const authLink = new ApolloLink((operation, forward) => {
  let { token, withAuthHeaders } = operation.getContext();
  if (!token && !withAuthHeaders) return forward(operation);
  if (!token) token = parseCookies().token;
  if (token) {
    let decode: any;
    try {
      decode = jwtDecode(token);
    } catch (err) {
      console.warn('Invalid token', err);
    }
    operation.setContext({
      headers: {
        authorization: decode?.scope?.split(':')[0] === 'client' ? `Bearer ${token}` : `LAWYER-AUTH-TOKEN ${token}`,
      },
    });
  }

  return forward(operation);
});

const customFetchUpload = (uri: string, options: any) => {
  uri = `${process.env.NEXT_PUBLIC_COCKPIT_URL_PUBLIC}/api/graphql/query` as string;
  return unfetch(uri, options);
};

const customFetch = unfetch;

const getRestUri = () => {
  const uri = process.env.NEXT_PUBLIC_API_URL;
  return uri;
};

const restLink = new RestLink({
  uri: getRestUri(),
  customFetch,
  responseTransformer: async (response) =>
    response.json().then((data: any) => {
      const { message, results, response } = data;
      if (message === 'Success') return response;
      if (results) return results;
      return data;
    }),
});

const getNextJSUri = () => {
  return getAbsoluteUrl();
};

const nextJSRestLink = new RestLink({
  uri: getNextJSUri(),
  customFetch,
});

const contentfulLink = new HttpLink({
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}`,
  headers: {
    authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN}`,
  },
  fetch: unfetch,
});

export function getApolloClient(forceNew: boolean): ApolloClient<{}> {
  if (!CLIENT || forceNew) {
    const cacheArgument = {
      typePolicies: {
        Query: {
          fields: {
            JurataSearchIndex: {
              keyArgs: ['query'],
              merge(
                existing: LawyerSearchResults['JurataSearchIndex'],
                incoming: LawyerSearchResults['JurataSearchIndex'],
                { args }: any
              ) {
                let items: { payload: SearchLawyer }[] = [];
                const { limit } = args;
                if (existing && existing.items) {
                  items = [
                    ...existing.items,
                    ...incoming.items.filter((i) => !existing.items.find((e) => e.payload.slug === i.payload.slug)),
                  ];
                  if (Number.isInteger(args.limit)) items = items.slice(0, limit);
                } else {
                  items = [...incoming.items];
                }
                return Object.assign({}, incoming, { items });
              },
              read(existing: LawyerSearchResults['JurataSearchIndex'], { args }: { args: any }) {
                let { offset, limit } = args;
                if (
                  existing &&
                  (existing.items.length === 0 ||
                    (existing.count < offset + limit && existing.items.length > offset + limit - LAWYERS_PER_PAGE))
                ) {
                  return existing;
                }
                if (existing && existing.items.length >= (offset || 0) + limit) {
                  return Object.assign({}, existing, { items: existing?.items.slice(offset, (offset || 0) + limit) });
                }
              },
            },
          },
        },
      },
    };

    const cache = new InMemoryCache(cacheArgument).restore(windowApolloState || {});

    const apolloLink = ApolloLink.from([
      authLink,
      onError(({ graphQLErrors, networkError, response }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.warn(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
          );
        if (networkError) {
          if ((networkError as ServerError).statusCode === 403 && typeof window !== 'undefined') {
            window.dispatchEvent(new Event('forbiddenResource'));
            return;
          } else if ((networkError as ServerError).statusCode === 401) {
            if (response) {
              // Avoid an error notification, but this is not working somehow.
              response.errors = undefined;
            }
            expire(CLIENT);
          } else {
            console.warn(`[Network error]: ${networkError} ${response && response.errors}`);
          }
        }
      }),
      restLink,
      nextJSRestLink,
      createUploadLink({
        uri: `${process.env.NEXT_PUBLIC_COCKPIT_URL_PUBLIC}/api/graphql/query`,
        credentials: 'same-origin',
        fetch: customFetchUpload,
      }),
    ]);

    CLIENT = new ApolloClient({
      link: ApolloLink.split((operation) => operation.getContext().clientName === 'contentful', contentfulLink).split(
        (operation) => operation.getContext().clientName === 'nextjs-api',
        nextJSRestLink,
        apolloLink
      ),
      cache,
      resolvers: {},
      ssrMode: isServer,
      connectToDevTools: !isServer && isNotProduction,
    });
  }

  return CLIENT;
}
