import React, { useEffect } from 'react';
import {
  useDisclosure,
  Link as StyleLink,
  chakra,
  Flex,
  Center,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { useTranslation, withTranslation } from 'next-i18next';
import Link from '../Link';
import { useUserScope, useIsAuth, AccessScopeType } from '../../utils/auth-utils';
import dynamic from 'next/dynamic';
import { ChevronDownIcon, ChevronUpIcon, HamburgerIcon } from '@chakra-ui/icons';
import Image from 'next/image';

import { useRouter } from 'next/router';
import { css } from '@emotion/react';
import { UnstyledHeaderMenu } from 'components/HeaderMenu';
import { Locale } from 'typings/shared';
import HeaderAuthenticatedLawyer from './HeaderAuthenticatedLawyer';
import { LAWYER_DASHBOARD_ROUTES } from 'utils/lawyers';

const HeaderMenu = dynamic(() => import('components/HeaderMenu'));

interface HeaderLink {
  text: string;
  link: string;
  weight: number;
  locale?: Locale;
}

interface HeaderProps {
  isFixed?: boolean;
}

const JurataHeader: React.FC<HeaderProps> = ({ isFixed }) => {
  const servicesDropdown = useDisclosure();
  const topicsDropdown = useDisclosure();
  const moreDropdown = useDisclosure();
  const modal = useDisclosure();
  const drawer = useDisclosure();
  const router = useRouter();
  const scope = useUserScope();
  const isAuthenticated = useIsAuth();

  useEffect(() => {
    if (topicsDropdown.isOpen) topicsDropdown.onClose();
    if (modal.isOpen) modal.onClose();
    if (drawer.isOpen) drawer.onClose();
  }, [router.query]);

  const [t] = useTranslation('common');

  const servicesLinks = [...(t('headers.servicesDropdown', { returnObjects: true }) as Array<HeaderLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );
  const topicsLinks = [...(t('headers.topicsDropdown', { returnObjects: true }) as Array<HeaderLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );
  const moreLinks = [...(t('headers.moreDropdown', { returnObjects: true }) as Array<HeaderLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );

  const isLawyerDashboard = LAWYER_DASHBOARD_ROUTES.includes(router.pathname);

  return isLawyerDashboard ? (
    <HeaderAuthenticatedLawyer />
  ) : (
    <chakra.header
      pos={isFixed ? 'fixed' : 'static'}
      bg='blue.400'
      w='100%'
      zIndex={900}
      borderBottom='1px solid'
      borderColor='whiteTransparent.50'
    >
      <Center
        w='100%'
        maxW='73.75rem'
        justifyContent='space-between'
        h='4.75rem'
        flexWrap='nowrap'
        sx={{ gap: '1rem' }}
        px={{ base: 4, md: 6 }}
        mx='auto'
      >
        <>
          <Box>
            <Link route='/'>
              <StyleLink display='flex'>
                <Flex display={{ base: 'none', lg: 'flex' }}>
                  <Image priority alt='White Logo' src='/icons/logo-white.svg' height={32} width={140} />
                </Flex>
                <Flex display={{ base: 'flex', lg: 'none' }}>
                  <Image alt='White Logo' src='/icons/logo-white.svg' height={28} width={140} />
                </Flex>
              </StyleLink>
            </Link>
          </Box>
          <Flex
            h='100%'
            align='center'
            grow={1}
            justify='flex-end'
            display={{
              base: 'none',
              md: 'flex',
            }}
          >
            <Flex h='100%' align='center' mr={6}>
              <Menu isOpen={servicesDropdown.isOpen} offset={[-24, 0]}>
                <MenuButton
                  color='white'
                  p={5}
                  display='inline-flex'
                  as={StyleLink}
                  onMouseEnter={servicesDropdown.onOpen}
                  onMouseLeave={servicesDropdown.onClose}
                  h='100%'
                  alignItems='center'
                  fontWeight='semibold'
                  _hover={{
                    textDecor: 'none',
                  }}
                >
                  {t('services')}{' '}
                  {servicesDropdown.isOpen ? (
                    <ChevronUpIcon w={5} h={5} mb={1} />
                  ) : (
                    <ChevronDownIcon w={5} h={5} mb={1} />
                  )}
                </MenuButton>
                {Array.isArray(servicesLinks) && servicesLinks.length && (
                  <MenuList
                    onMouseEnter={servicesDropdown.onOpen}
                    onMouseLeave={servicesDropdown.onClose}
                    borderRadius={0}
                    px={6}
                    py={4}
                    boxShadow='0 2px 9px 11px rgba(0,0,0,.1)'
                    zIndex={1100}
                  >
                    {servicesLinks.map((serviceLink) => (
                      <MenuItem
                        _hover={{ bg: 'none' }}
                        key={serviceLink.link + serviceLink.text}
                        px={0}
                        py='0.3125rem'
                        my={1}
                        as={Box}
                      >
                        {serviceLink.locale && serviceLink.link ? (
                          <Link route={serviceLink.locale + '/' + serviceLink.link} lang={serviceLink.locale}>
                            <StyleLink color='blue.400' fontWeight='semibold'>
                              {serviceLink.text}
                            </StyleLink>
                          </Link>
                        ) : (
                          <Link route={serviceLink.link}>
                            <StyleLink color='blue.400' fontWeight='semibold'>
                              {serviceLink.text}
                            </StyleLink>
                          </Link>
                        )}
                      </MenuItem>
                    ))}
                  </MenuList>
                )}
              </Menu>
              <Menu isOpen={topicsDropdown.isOpen} offset={[-24, 0]}>
                <MenuButton
                  color='white'
                  p={5}
                  display='inline-flex'
                  as={StyleLink}
                  onMouseEnter={topicsDropdown.onOpen}
                  onMouseLeave={topicsDropdown.onClose}
                  h='100%'
                  alignItems='center'
                  fontWeight='semibold'
                  _hover={{
                    textDecor: 'none',
                  }}
                >
                  {t('topics')}{' '}
                  {topicsDropdown.isOpen ? (
                    <ChevronUpIcon w={5} h={5} mb={1} />
                  ) : (
                    <ChevronDownIcon w={5} h={5} mb={1} />
                  )}
                </MenuButton>
                {Array.isArray(topicsLinks) && topicsLinks.length && (
                  <MenuList
                    onMouseEnter={topicsDropdown.onOpen}
                    onMouseLeave={topicsDropdown.onClose}
                    borderRadius={0}
                    px={6}
                    py={4}
                    boxShadow='0 2px 9px 11px rgba(0,0,0,.1)'
                    zIndex={1100}
                  >
                    {topicsLinks.map((topicLink) => (
                      <MenuItem
                        _hover={{ bg: 'none' }}
                        key={topicLink.link + topicLink.text}
                        px={0}
                        py='0.3125rem'
                        my={1}
                        as={Box}
                      >
                        {topicLink.locale && topicLink.link ? (
                          <Link route={topicLink.link.replace(`/${topicLink.locale}`, '')} lang={topicLink.locale}>
                            <StyleLink color='blue.400' fontWeight='semibold'>
                              {topicLink.text}
                            </StyleLink>
                          </Link>
                        ) : (
                          <Link route={topicLink.link}>
                            <StyleLink color='blue.400' fontWeight='semibold'>
                              {topicLink.text}
                            </StyleLink>
                          </Link>
                        )}
                      </MenuItem>
                    ))}
                  </MenuList>
                )}
              </Menu>
              <Menu isOpen={moreDropdown.isOpen} offset={[-24, 0]}>
                <MenuButton
                  color='white'
                  p={5}
                  display='inline-flex'
                  as={StyleLink}
                  onMouseEnter={moreDropdown.onOpen}
                  onMouseLeave={moreDropdown.onClose}
                  h='100%'
                  alignItems='center'
                  fontWeight='semibold'
                  _hover={{
                    textDecor: 'none',
                  }}
                >
                  {t('more')}{' '}
                  {moreDropdown.isOpen ? <ChevronUpIcon w={5} h={5} mb={1} /> : <ChevronDownIcon w={5} h={5} mb={1} />}
                </MenuButton>
                {Array.isArray(moreLinks) && moreLinks.length && (
                  <MenuList
                    onMouseEnter={moreDropdown.onOpen}
                    onMouseLeave={moreDropdown.onClose}
                    borderRadius={0}
                    px={6}
                    py={4}
                    boxShadow='0 2px 9px 11px rgba(0,0,0,.1)'
                    zIndex={1100}
                  >
                    {moreLinks.map((moreLink) => (
                      <MenuItem
                        _hover={{ bg: 'none' }}
                        key={moreLink.link + moreLink.text}
                        px={0}
                        py='0.3125rem'
                        my={1}
                        as={Box}
                      >
                        {moreLink.locale && moreLink.link ? (
                          <Link route={moreLink.link.replace(`/${moreLink.locale}`, '')} lang={moreLink.locale}>
                            <StyleLink color='blue.400' fontWeight='semibold'>
                              {moreLink.text}
                            </StyleLink>
                          </Link>
                        ) : (
                          <Link route={moreLink.link}>
                            <StyleLink color='blue.400' fontWeight='semibold'>
                              {moreLink.text}
                            </StyleLink>
                          </Link>
                        )}
                      </MenuItem>
                    ))}
                  </MenuList>
                )}
              </Menu>
            </Flex>
            <Link route={scope === AccessScopeType.client ? '/dashboard' : isAuthenticated ? '/pool' : '/login'}>
              <StyleLink
                display='flex'
                py={2}
                px={4}
                borderRadius='3px'
                border='1px'
                borderColor='white'
                color='white'
                fontWeight='semibold'
                _hover={{
                  textDecor: 'none',
                  borderColor: 'whiteTransparent.70',
                  color: 'whiteTransparent.70',
                }}
              >
                {isAuthenticated ? t('dashboard') : t('login')}
              </StyleLink>
            </Link>
          </Flex>
          <Flex
            h='4.6875rem'
            display={{
              base: 'flex',
              md: 'none',
            }}
            align='center'
          >
            <IconButton
              color='white'
              border='none'
              bg='none'
              outline='none'
              _hover={{ bg: 'rgba(246,245,243,.06)' }}
              onClick={modal.onOpen}
              aria-label='Menu'
              minW={8}
              borderRadius='5px'
              icon={<HamburgerIcon h={8} w={12} />}
            />
          </Flex>
          {(modal.isOpen || drawer.isOpen) && (
            <HeaderMenu
              isClient={scope === AccessScopeType.client}
              drawer={drawer}
              modal={modal}
              closeModal={modal.onClose}
              isAuthenticated={isAuthenticated}
            />
          )}
        </>
      </Center>
    </chakra.header>
  );
};

export const UnstyledJurataHeader: React.FC<HeaderProps> = ({ isFixed }) => {
  const servicesDropdown = useDisclosure();
  const topicsDropdown = useDisclosure();
  const moreDropdown = useDisclosure();
  const modal = useDisclosure();
  const drawer = useDisclosure();
  const router = useRouter();
  const scope = useUserScope();
  const isAuthenticated = useIsAuth();

  useEffect(() => {
    if (topicsDropdown.isOpen) topicsDropdown.onClose();
    if (modal.isOpen) modal.onClose();
    if (drawer.isOpen) drawer.onClose();
  }, [router.query]);

  const [t] = useTranslation('common');

  const servicesLinks = [...(t('headers.servicesDropdown', { returnObjects: true }) as Array<HeaderLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );
  const topicsLinks = [...(t('headers.topicsDropdown', { returnObjects: true }) as Array<HeaderLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );
  const moreLinks = [...(t('headers.moreDropdown', { returnObjects: true }) as Array<HeaderLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );

  return (
    <chakra.header
      pos={isFixed ? 'fixed' : 'static'}
      bg='#4d6693'
      w='100%'
      zIndex={900}
      borderBottom='1px solid'
      borderColor='rgba(255, 255, 255, 0.5)'
    >
      <Center
        w='100%'
        maxW='73.75rem'
        justifyContent='space-between'
        h='4.75rem'
        flexWrap='nowrap'
        mx='auto'
        css={css`
          gap: 1rem;
          padding: 0 1rem;
          @media (min-width: 48em) {
            padding: 0 1.5rem;
          }
        `}
      >
        <>
          <Box>
            <Link route='/'>
              <StyleLink display='flex'>
                <Flex
                  css={css`
                    display: none;
                    @media (min-width: 62em) {
                      display: flex;
                    }
                  `}
                >
                  <Image priority alt='White Logo' src='/icons/logo-white.svg' height={32} width={140} />
                </Flex>
                <Flex
                  css={css`
                    display: flex;
                    @media (min-width: 62em) {
                      display: none;
                    }
                  `}
                >
                  <Image alt='White Logo' src='/icons/logo-white.svg' height={28} width={140} />
                </Flex>
              </StyleLink>
            </Link>
          </Box>
          <Flex
            h='100%'
            align='center'
            grow={1}
            justify='flex-end'
            css={css`
              display: none;
              @media (min-width: 48em) {
                display: flex;
              }
            `}
          >
            <Flex h='100%' align='center' mr={24}>
              <Menu isOpen={servicesDropdown.isOpen} offset={[-24, 0]}>
                <MenuButton
                  color='white'
                  p={20}
                  display='inline-flex'
                  as={StyleLink}
                  onMouseEnter={servicesDropdown.onOpen}
                  onMouseLeave={servicesDropdown.onClose}
                  h='100%'
                  alignItems='center'
                  fontWeight='600'
                  _hover={{
                    textDecor: 'none',
                  }}
                >
                  {t('services')}{' '}
                  {servicesDropdown.isOpen ? (
                    <ChevronUpIcon w={20} h={20} mb={4} />
                  ) : (
                    <ChevronDownIcon w={20} h={20} mb={4} />
                  )}
                </MenuButton>
                {Array.isArray(servicesLinks) && servicesLinks.length && (
                  <MenuList
                    onMouseEnter={servicesDropdown.onOpen}
                    onMouseLeave={servicesDropdown.onClose}
                    borderRadius={0}
                    px={24}
                    py={16}
                    boxShadow='0 2px 9px 11px rgba(0,0,0,.1)'
                    zIndex={1100}
                    bg='white'
                  >
                    {servicesLinks.map((serviceLink) => (
                      <MenuItem
                        _hover={{ bg: 'none' }}
                        key={serviceLink.link + serviceLink.text}
                        px={0}
                        py='0.3125rem'
                        my={4}
                        as={Box}
                      >
                        {serviceLink.locale && serviceLink.link ? (
                          <Link
                            lang={serviceLink.locale}
                            route={serviceLink.link.replace(`/${serviceLink.locale}`, '')}
                          >
                            <StyleLink color='#4d6693' fontWeight='600'>
                              {serviceLink.text}
                            </StyleLink>
                          </Link>
                        ) : (
                          <Link route={serviceLink.link}>
                            <StyleLink color='#4d6693' fontWeight='600'>
                              {serviceLink.text}
                            </StyleLink>
                          </Link>
                        )}
                      </MenuItem>
                    ))}
                  </MenuList>
                )}
              </Menu>
              <Menu isOpen={topicsDropdown.isOpen} offset={[-24, 0]}>
                <MenuButton
                  color='white'
                  p={20}
                  display='inline-flex'
                  as={StyleLink}
                  onMouseEnter={topicsDropdown.onOpen}
                  onMouseLeave={topicsDropdown.onClose}
                  h='100%'
                  alignItems='center'
                  fontWeight='600'
                  _hover={{
                    textDecor: 'none',
                  }}
                >
                  {t('topics')}{' '}
                  {topicsDropdown.isOpen ? (
                    <ChevronUpIcon w={20} h={20} mb={4} />
                  ) : (
                    <ChevronDownIcon w={20} h={20} mb={4} />
                  )}
                </MenuButton>
                {Array.isArray(topicsLinks) && topicsLinks.length && (
                  <MenuList
                    onMouseEnter={topicsDropdown.onOpen}
                    onMouseLeave={topicsDropdown.onClose}
                    borderRadius={0}
                    px={24}
                    py={16}
                    boxShadow='0 2px 9px 11px rgba(0,0,0,.1)'
                    zIndex={1100}
                    bg='white'
                  >
                    {topicsLinks.map((topicLink) => (
                      <MenuItem
                        _hover={{ bg: 'none' }}
                        key={topicLink.link + topicLink.text}
                        px={0}
                        py='0.3125rem'
                        my={4}
                        as={Box}
                      >
                        {topicLink.locale && topicLink.link ? (
                          <Link lang={topicLink.locale} route={topicLink.link.replace(`/${topicLink.locale}`, '')}>
                            <StyleLink color='#4d6693' fontWeight='600'>
                              {topicLink.text}
                            </StyleLink>
                          </Link>
                        ) : (
                          <Link route={topicLink.link}>
                            <StyleLink color='#4d6693' fontWeight='600'>
                              {topicLink.text}
                            </StyleLink>
                          </Link>
                        )}
                      </MenuItem>
                    ))}
                  </MenuList>
                )}
              </Menu>
              <Menu isOpen={moreDropdown.isOpen} offset={[-24, 0]}>
                <MenuButton
                  color='white'
                  p={20}
                  display='inline-flex'
                  as={StyleLink}
                  onMouseEnter={moreDropdown.onOpen}
                  onMouseLeave={moreDropdown.onClose}
                  h='100%'
                  alignItems='center'
                  fontWeight='600'
                  _hover={{
                    textDecor: 'none',
                  }}
                >
                  {t('more')}{' '}
                  {moreDropdown.isOpen ? (
                    <ChevronUpIcon w={20} h={20} mb={4} />
                  ) : (
                    <ChevronDownIcon w={20} h={20} mb={4} />
                  )}
                </MenuButton>
                {Array.isArray(moreLinks) && moreLinks.length && (
                  <MenuList
                    onMouseEnter={moreDropdown.onOpen}
                    onMouseLeave={moreDropdown.onClose}
                    borderRadius={0}
                    px={24}
                    py={16}
                    boxShadow='0 2px 9px 11px rgba(0,0,0,.1)'
                    zIndex={1100}
                    bg='white'
                  >
                    {moreLinks.map((moreLink) => (
                      <MenuItem
                        _hover={{ bg: 'none' }}
                        key={moreLink.link + moreLink.text}
                        px={0}
                        py='0.3125rem'
                        my={4}
                        as={Box}
                      >
                        {moreLink.locale && moreLink.link ? (
                          <Link lang={moreLink.locale} route={moreLink.link.replace(`/${moreLink.locale}`, '')}>
                            <StyleLink color='#4d6693' fontWeight='600'>
                              {moreLink.text}
                            </StyleLink>
                          </Link>
                        ) : (
                          <Link route={moreLink.link}>
                            <StyleLink color='#4d6693' fontWeight='600'>
                              {moreLink.text}
                            </StyleLink>
                          </Link>
                        )}
                      </MenuItem>
                    ))}
                  </MenuList>
                )}
              </Menu>
            </Flex>
            <Link route={scope === AccessScopeType.client ? '/dashboard' : isAuthenticated ? '/pool' : '/login'}>
              <StyleLink
                display='flex'
                py={8}
                px={16}
                borderRadius='3px'
                border='1px solid'
                borderColor='white'
                color='white'
                fontWeight='600'
                _hover={{
                  textDecor: 'none',
                  borderColor: 'rgba(255, 255, 255, 0.7)',
                  color: 'rgba(255, 255, 255, 0.7)',
                }}
              >
                {isAuthenticated ? t('dashboard') : t('login')}
              </StyleLink>
            </Link>
          </Flex>
          <Flex
            h='4.6875rem'
            css={css`
              display: flex;
              @media (min-width: 48em) {
                display: none;
              }
            `}
            align='center'
          >
            <IconButton
              color='white'
              border='none'
              bg='none'
              outline='none'
              _hover={{ bg: 'rgba(246,245,243,.06)' }}
              onClick={modal.onOpen}
              aria-label='Menu'
              minW={32}
              borderRadius='5px'
              icon={<HamburgerIcon h={32} w={48} />}
            />
          </Flex>
          {(modal.isOpen || drawer.isOpen) && (
            <UnstyledHeaderMenu
              isClient={scope === AccessScopeType.client}
              drawer={drawer}
              modal={modal}
              closeModal={modal.onClose}
              isAuthenticated={isAuthenticated}
            />
          )}
        </>
      </Center>
    </chakra.header>
  );
};

export default withTranslation('common')(JurataHeader);
