// 1. Import the extendTheme function
import { extendTheme, useColorMode } from '@chakra-ui/react';
import React from 'react';

const theme = {
  styles: {
    global: {
      body: {
        backgroundColor: 'white',
        color: 'black',
      },
      '#chakra-toast-manager-bottom-left': {
        paddingLeft: '0.75rem',
        paddingBottom: '0.75rem',
      },
    },
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  colors: {
    gray: {
      50: '#f9f9f9',
      100: '#f6f5f3',
      150: '#e6e6e6',
      200: '#d8d8d8',
      250: '#bababa',
      300: '#b3b3b3',
      400: '#949494',
      500: '#757575',
      600: '#676767',
      700: '#4b4b4b',
    },
    blue: {
      50: '#eeeff4',
      100: '#f3f5f9',
      200: '#e8ebf2',
      300: '#a6b2c9',
      400: '#4d6693',
      500: '#3C5074',
      600: '#2E3D58',
    },
    sky: {
      light: '#eef4f4',
      regular: '#4bb2df',
      dark: '#2f7a9c',
      darker: '#13526f',
    },
    purple: {
      light: '#f9f0ff',
      regular: '#9952c7',
      dark: '#6d3095',
    },
    red: {
      200: '#fff2f2',
      250: '#e0736c',
      300: '#e3574e',
      400: '#e33a2b',
      500: '#d94100',
    },
    yellow: {
      100: '#fffdf2',
      300: '#fdf4d4',
      500: '#ffdf40',
    },
    green: {
      200: '#f0f8f7',
      300: '#69c2b4',
      400: '#278684',
      500: '#217271',
      600: '#375252',
      700: '#1E8681',
      900: '#299f8c',
    },
    black: '#161616',
    white: '#ffffff',
    whiteTransparent: {
      25: 'rgba(255, 255, 255, 0.25)',
      50: 'rgba(255, 255, 255, 0.5)',
      70: 'rgba(255, 255, 255, 0.7)',
    },
    transparent: {
      5: 'rgba(0, 0, 0, 0.05)',
      13: 'rgba(0, 0, 0, 0.13)',
      50: 'rgba(0, 0, 0, 0.5)',
      66: 'rgba(0, 0, 0, 0.66)',
    },
  },
  space: {
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.375rem',
    2: '0.5rem',
    2.5: '0.625rem',
    3: '0.75rem',
    3.5: '0.875rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    12: '3rem',
    13: '3.25rem',
    14: '3.5rem',
    16: '4rem',
    18: '4.5rem',
    20: '5rem',
    24: '6rem',
    28: '7rem',
    32: '8rem',
    36: '9rem',
    40: '10rem',
    44: '11rem',
    48: '12rem',
    52: '13rem',
    56: '14rem',
    60: '15rem',
    64: '16rem',
    72: '18rem',
    80: '20rem',
    96: '24rem',
  },
  fonts: {
    heading: `Merriweather`,
    body: `Source Sans Pro`,
    sansSerif: "'Source Sans Pro', sans-serif",
    serif: "'Merriweather', serif",
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
  fontWeights: {
    normal: 400,
    semibold: 600,
    bold: 700,
  },
  lineHeights: {
    normal: 'normal',
    none: 1,
    short: 1.2,
    base: 1.5,
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  shadows: {
    xl: '0px 0px 16px 4px rgba(0, 0, 0, 0.1)',
  },
  components: {
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: 'none',
        },
        _focusVisible: {
          boxShadow: 'outline',
        },
        borderRadius: 3,
        lineHeight: 'base',
        fontWeight: 'semibold',
        fontFamily: 'sansSerif',
        fontSize: {
          base: '1rem',
          lg: '1.0625rem',
        },
        cursor: 'pointer',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: '1px',
        display: 'inline-flex',
        justify: 'center',
        position: 'relative',
        textAlign: 'center',
        textDecoration: 'none',
        userSelect: 'none',
      },
      sizes: {
        base: {
          h: 'unset',
        },
        md: {
          h: '12',
        },
      },
      variants: {
        unstyled: {
          border: 'none',
        },
        primary: {
          backgroundColor: 'blue.400',
          borderColor: 'blue.400',
          color: 'white',
          _hover: {
            backgroundColor: 'blue.500',
            borderColor: 'blue.500',
            _disabled: {
              bg: 'blue.500',
            },
          },
          _focus: {
            backgroundColor: 'blue.500',
            borderColor: 'blue.500',
          },
          _active: {},
        },
        secondary: {
          backgroundColor: 'white',
          borderColor: 'blue.400',
          color: 'blue.400',
          _hover: {
            borderColor: 'blue.500',
            color: 'blue.500',
          },
          _focus: {
            borderColor: 'blue.500',
            color: 'blue.500',
          },
          _active: {
            borderColor: 'blue.500',
            color: 'blue.500',
          },
        },
        red: {
          backgroundColor: 'red.400',
          borderColor: 'red.400',
          color: 'white',
          _hover: {
            borderColor: 'red.600',
            backgroundColor: 'red.600',
            _disabled: {
              bg: 'red.600',
            },
          },
          _focus: {
            borderColor: 'red.600',
            backgroundColo: 'red.600',
          },
          _active: {
            borderColor: 'red.600',
            backgroundColo: 'red.600',
          },
        },
        primaryDark: {
          backgroundColor: 'blue.600',
          borderColor: 'blue.600',
          color: 'white',
          _hover: {
            backgroundColor: 'blue.500',
            borderColor: 'blue.500',
            _disabled: {
              bg: 'blue.500',
            },
          },
          _focus: {
            backgroundColor: 'blue.500',
            borderColor: 'blue.500',
          },
          _active: {
            borderColor: 'blue.500',
            color: 'white',
          },
        },
        sky: {
          backgroundColor: 'sky.regular',
          borderColor: 'sky.regular',
          color: 'white',
          _hover: {
            borderColor: 'sky.dark',
            backgroundColor: 'sky.dark',
          },
          _focus: {
            borderColor: 'sky.dark',
            backgroundColor: 'sky.dark',
          },
          _active: {
            borderColor: 'sky.dark',
            backgroundColor: 'sky.dark',
          },
        },
        skyDark: {
          backgroundColor: 'sky.dark',
          borderColor: 'sky.dark',
          color: 'white',
          _hover: {
            borderColor: 'sky.darker',
            backgroundColor: 'sky.darker',
          },
          _active: {
            borderColor: 'sky.darker',
            backgroundColor: 'sky.darker',
          },
          _focus: {
            borderColor: 'sky.darker',
            backgroundColor: 'sky.darker',
          },
        },
        green: {
          backgroundColor: 'green.300',
          borderColor: 'green.300',
          color: 'white',
          _hover: {
            backgroundColor: 'green.400',
            borderColor: 'green.400',
            _disabled: {
              backgroundColor: 'green.400',
              borderColor: 'green.400',
            },
          },
          _focus: {
            backgroundColor: 'green.400',
            borderColor: 'green.400',
          },
        },
        greenDark: {
          backgroundColor: 'green.400',
          borderColor: 'green.400',
          color: 'white',
          _hover: {
            backgroundColor: 'green.500',
            borderColor: 'green.500',
          },
          _active: {
            borderColor: 'green.500',
            backgroundColor: 'green.500',
          },
          _focus: {
            backgroundColor: 'green.500',
            borderColor: 'green.500',
          },
        },
        purple: {
          backgroundColor: 'purple.regular',
          borderColor: 'purple.regular',
          color: 'white',
          _hover: {
            borderColor: 'purple.dark',
            backgroundColor: 'purple.dark',
            _disabled: {
              bg: 'purple.dark',
            },
          },
          _focus: {
            borderColor: 'purple.dark',
            backgroundColo: 'purple.dark',
          },
          _active: {
            borderColor: 'purple.dark',
            backgroundColo: 'purple.dark',
          },
        },
        outlinePurple: {
          borderColor: 'purple.regular',
          color: 'purple.regular',
        },
        chip: {
          backgroundColor: 'green.300',
          borderRadius: '2px',
          fontWeight: 'normal',
          height: 'unset',
          border: 'none',
          color: 'white',
          _hover: {
            backgroundColor: 'green.300',
            border: 'none',
          },
          _focus: {
            backgroundColor: 'green.300',
            border: 'none',
          },
          _active: {
            backgroundColor: 'green.300',
            border: 'none',
          },
        },
        outline: {
          borderColor: 'blue.400',
          color: 'blue.400',
        },
        transparent: {
          backgroundColor: 'none',
          borderColor: 'none',
          border: 'none',
          _hover: {
            backgroundColor: 'none',
            borderColor: 'none',
          },
          _focus: {
            backgroundColor: 'none',
            borderColor: 'none',
          },
          _active: {
            backgroundColor: 'none',
            borderColor: 'none',
          },
        },
        transparentSecondary: {
          backgroundColor: 'none',
          borderColor: 'blue.400',
          color: 'blue.400',
          _hover: {
            backgroundColor: 'none',
            borderColor: 'blue.500',
            color: 'blue.500',
          },
          _focus: {
            backgroundColor: 'none',
            borderColor: 'blue.500',
            color: 'blue.500',
          },
          _active: {
            backgroundColor: 'none',
            borderColor: 'blue.500',
            color: 'blue.500',
          },
        },
        transparentWhite: {
          backgroundColor: 'none',
          borderColor: 'white',
          color: 'white',
          _hover: {
            backgroundColor: 'none',
            borderColor: 'white',
            color: 'white',
          },
          _focus: {
            backgroundColor: 'none',
            borderColor: 'white',
            color: 'white',
          },
          _active: {
            backgroundColor: 'none',
            borderColor: 'white',
            color: 'white',
          },
        },
      },
    },
    FormErrorMessage: {
      baseStyle: {
        color: 'red.500',
      },
    },
    Link: {
      baseStyle: {
        fontWeight: 400,
        _focus: {
          boxShadow: 'none',
        },
        _focusVisible: {
          boxShadow: 'outline',
        },
      },
    },
    Tab: {
      baseStyle: {
        _focus: {
          boxShadow: 'none',
        },
        _focusVisible: {
          boxShadow: 'outline',
        },
      },
    },
    Modal: {
      baseStyle: {
        dialogContainer: {
          '@supports(height: -webkit-fill-available)': {},
        },
      },
    },
    Popover: {
      baseStyle: {
        popper: {
          zIndex: 1500,
        },
      },
    },
    Input: {
      sizes: {
        md: {
          field: {
            borderRadius: 'base',
          },
        },
      },
    },
  },
};

export function ForceLightMode(props: { children: JSX.Element }) {
  const { colorMode, toggleColorMode } = useColorMode();

  React.useEffect(() => {
    if (colorMode === 'light') return;
    toggleColorMode();
  }, [colorMode]);

  return props.children;
}

export const extendedTheme = extendTheme(theme);
