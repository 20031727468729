module.exports = [
  { language: 'fr', localName: '/', pathName: 'index', source: '/', destination: '/fr' },
  { language: 'fr', localName: '/', pathName: '/', source: '/', destination: '/fr' },
  {
    language: 'fr',
    localName: 'avocats',
    pathName: 'lawyers',
    source: '/fr/avocats/:slugs*',
    destination: '/fr/lawyers',
  },
  {
    language: 'fr',
    localName: 'avocat',
    pathName: 'lawyer/[slug]',
    source: '/fr/avocat/:slug',
    destination: '/fr/lawyer/:slug',
  },
  {
    language: 'fr',
    localName: 'avocat',
    pathName: 'lawyer',
    source: '/fr/avocat',
    destination: '/fr/lawyer',
  },
  {
    language: 'fr',
    localName: 'pool-de-demande/conditions',
    pathName: 'pool/conditions',
    source: '/fr/pool-de-demande/conditions',
    destination: '/fr/pool/conditions',
  },
  {
    language: 'fr',
    localName: 'pool-de-demande/solliciter',
    pathName: 'pool/claim/[caseId]',
    source: '/fr/pool-de-demande/solliciter/:caseId',
    destination: '/fr/pool/claim/:caseId',
  },
  {
    language: 'fr',
    localName: 'tour-de-financement',
    pathName: 'news/investment-round',
    source: '/fr/tour-de-financement',
    destination: '/fr/news/investment-round',
  },
  {
    language: 'fr',
    localName: 'localites',
    pathName: 'directory/places',
    source: '/fr/localites',
    destination: '/fr/directory/places',
  },
  {
    language: 'fr',
    localName: 'localites',
    pathName: 'directory/places/[letter]',
    source: '/fr/localites/:letter',
    destination: '/fr/directory/places/:letter',
  },
  {
    language: 'fr',
    localName: 'tous-les-avocats',
    pathName: 'directory/all-lawyers',
    source: '/fr/tous-les-avocats',
    destination: '/fr/directory/all-lawyers',
  },
  {
    language: 'fr',
    localName: 'tous-les-avocats',
    pathName: 'directory/all-lawyers/[letter]',
    source: '/fr/tous-les-avocats/:letter',
    destination: '/fr/directory/all-lawyers/:letter',
  },
  {
    language: 'fr',
    localName: 'etudes',
    pathName: 'directory/law-firms',
    source: '/fr/etudes',
    destination: '/fr/directory/law-firms',
  },
  {
    language: 'fr',
    localName: 'etudes',
    pathName: 'directory/law-firms/[letter]',
    source: '/fr/etudes/:letter',
    destination: '/fr/directory/law-firms/:letter',
  },
  {
    language: 'fr',
    localName: 'domaines-juridiques',
    pathName: 'directory/practice-areas',
    source: '/fr/domaines-juridiques',
    destination: '/fr/directory/practice-areas',
  },
  {
    language: 'fr',
    localName: 'domaines-juridiques',
    pathName: 'directory/practice-areas/[slug]',
    source: '/fr/domaines-juridiques/:slug',
    destination: '/fr/directory/practice-areas/:slug',
  },
  {
    language: 'fr',
    localName: 'cantons',
    pathName: 'directory/cantons',
    source: '/fr/cantons',
    destination: '/fr/directory/cantons',
  },
  { language: 'fr', localName: 'login', pathName: 'login', source: '/fr/login', destination: '/fr/login' },
  {
    language: 'fr',
    localName: 'etude',
    pathName: 'firm',
    source: '/fr/etude/:firm',
    destination: '/fr/lawyers?firms=:firm',
  },
  { language: 'fr', localName: 'etude', pathName: 'firm', source: '/fr/etude', destination: '/fr/lawyers' },
  {
    language: 'fr',
    localName: 'mon-profil',
    pathName: 'profile/my-profile',
    source: '/fr/mon-profil',
    destination: '/fr/profile/my-profile',
  },
  {
    language: 'fr',
    localName: 'mon-profil/profil/editer',
    pathName: 'profile/edit-profile',
    source: '/fr/mon-profil/profil/editer',
    destination: '/fr/profile/edit-profile',
  },
  {
    language: 'fr',
    localName: 'mon-profil/profil/editer',
    pathName: 'profile/edit-short-profile',
    source: '/fr/mon-profil/profil/editer',
    destination: '/fr/profile/edit-short-profile',
  },
  {
    language: 'fr',
    localName: 'mon-profil/domaines-juridiques/editer',
    pathName: 'profile/edit-practice-areas',
    source: '/fr/mon-profil/domaines-juridiques/editer',
    destination: '/fr/profile/edit-practice-areas',
  },
  {
    language: 'fr',
    localName: 'mon-profil/parcours/editer',
    pathName: 'profile/edit-career',
    source: '/fr/mon-profil/parcours/editer',
    destination: '/fr/profile/edit-career',
  },
  {
    language: 'fr',
    localName: 'mon-profil/engagement/editer',
    pathName: 'profile/edit-engagement',
    source: '/fr/mon-profil/engagement/editer',
    destination: '/fr/profile/edit-engagement',
  },
  {
    language: 'fr',
    localName: 'demandes',
    pathName: 'requests',
    source: '/fr/demandes/:ids*',
    destination: '/fr/requests',
  },
  {
    language: 'fr',
    localName: 'pool-de-demande',
    pathName: 'pool',
    source: '/fr/pool-de-demande/:id*',
    destination: '/fr/pool',
  },
  {
    language: 'fr',
    localName: 'obtenir-des-mandats',
    pathName: 'for-lawyers',
    source: '/fr/obtenir-des-mandats',
    destination: '/fr/for-lawyers',
  },
  {
    language: 'fr',
    localName: 'onboarding',
    pathName: 'onboarding',
    source: '/fr/onboarding',
    destination: '/fr/onboarding',
  },
  {
    language: 'fr',
    localName: 'mot-de-passe-oublie',
    pathName: 'forgot-password',
    source: '/fr/mot-de-passe-oublie',
    destination: '/fr/forgot-password',
  },
  {
    language: 'fr',
    localName: 'parametres',
    pathName: 'settings',
    source: '/fr/parametres',
    destination: '/fr/settings',
  },
  {
    language: 'fr',
    localName: 'parametres/compte',
    pathName: 'settings/account',
    source: '/fr/parametres/compte',
    destination: '/fr/settings/account',
  },
  {
    language: 'fr',
    localName: 'parametres/abonnement',
    pathName: 'settings/subscription',
    source: '/fr/parametres/abonnement',
    destination: '/fr/settings/subscription',
  },
  {
    language: 'fr',
    localName: 'abonnement',
    pathName: 'subscription-public',
    source: '/fr/abonnement/:slug',
    destination: '/fr/subscription-public?slug=:slug',
  },
  {
    language: 'fr',
    localName: 'abonnement',
    pathName: 'subscription-public',
    source: '/fr/abonnement',
    destination: '/fr/subscription-public',
  },
  {
    language: 'fr',
    localName: 'pre-inscription',
    pathName: 'presubscription',
    source: '/fr/pre-inscription',
    destination: '/fr/presubscription',
  },
  {
    language: 'fr',
    localName: 'definir-mot-de-passe',
    pathName: 'set-password',
    source: '/fr/definir-mot-de-passe/:token',
    destination: '/fr/set-password?token=:token',
  },
  {
    language: 'fr',
    localName: 'definir-mot-de-passe',
    pathName: 'set-password',
    source: '/fr/definir-mot-de-passe',
    destination: '/fr/set-password',
  },
  {
    language: 'fr',
    localName: 'contact',
    pathName: 'info/contact',
    source: '/fr/contact',
    destination: '/fr/info/contact',
  },
  {
    language: 'fr',
    localName: 'questions-et-reponses',
    pathName: 'info/faq',
    source: '/fr/questions-et-reponses',
    destination: '/fr/info/faq',
  },
  {
    language: 'fr',
    localName: 'conditions-utilisation',
    pathName: 'info/terms-of-use',
    source: '/fr/conditions-utilisation',
    destination: '/fr/info/terms-of-use',
  },
  {
    language: 'fr',
    localName: 'protection-des-donnees',
    pathName: 'info/data-protection',
    source: '/fr/protection-des-donnees',
    destination: '/fr/info/data-protection',
  },
  {
    language: 'fr',
    localName: 'soumission-de-cas',
    pathName: 'case-intake',
    source: '/fr/soumission-de-cas',
    destination: '/fr/case-intake',
  },
  {
    language: 'fr',
    localName: 'dashboard',
    pathName: 'dashboard',
    source: `/fr/dashboard`,
    destination: `/fr/dashboard`,
  },
  {
    language: 'fr',
    localName: 'cas',
    pathName: 'case/[caseId]',
    source: '/fr/cas/:caseId',
    destination: '/fr/case/:caseId',
  },
  {
    language: 'fr',
    localName: 'dashboard/mon-profil',
    pathName: 'dashboard/my-profile',
    source: `/fr/dashboard/mon-profil`,
    destination: `/fr/dashboard/my-profile`,
  },
  {
    language: 'fr',
    localName: 'registration',
    pathName: 'register',
    source: '/fr/registration',
    destination: '/fr/register',
  },
  {
    language: 'fr',
    localName: 'commission-fees',
    pathName: 'commission-fees',
    source: '/fr/commission-fees',
    destination: '/fr/commission-fees',
  },
  {
    source: '/sitemap.xml',
    destination: '/fr/sitemap.xml',
  },
  {
    language: 'fr',
    localName: 'mon-etude/changer',
    pathName: 'law-firm/change-law-firm',
    source: '/fr/mon-etude/changer',
    destination: '/fr/law-firm/change-law-firm',
  },
  {
    language: 'fr',
    localName: 'mon-etude/editer',
    pathName: 'law-firm/edit-law-firm',
    source: '/fr/mon-etude/editer',
    destination: '/fr/law-firm/edit-law-firm',
  },
  {
    language: 'fr',
    localName: 'mon-etude/nouvelle-etude',
    pathName: 'law-firm/new-law-firm',
    source: '/fr/mon-etude/nouvelle-etude',
    destination: '/fr/law-firm/new-law-firm',
  },
  {
    language: 'fr',
    localName: 'mon-etude',
    pathName: 'law-firm/my-law-firm',
    source: '/fr/mon-etude',
    destination: '/fr/law-firm/my-law-firm',
  },
];
