import { gql } from '@apollo/client';

export interface FirmData {
  _id: string;
  name: string;
  offices: [
    {
      value: {
        address: Address;
      };
    }
  ];
}

export interface Address {
  zip: string;
  municipality: string;
  town?: string;
}

export interface OnboardingFirms {
  firmsCollection: FirmData[];
}

export const GET_FIRMS_DATA = gql`
  {
    firmsCollection(sort: { name: 1 }, limit: 50) {
      _id
      name
      offices {
        value {
          address {
            zip
            town
            municipality
          }
        }
      }
    }
  }
`;

export const GET_FILTERED_FIRMS_DATA = gql`
  query ($filter: JsonType, $limit: Int) {
    firmsCollection(sort: { name: 1 }, filter: $filter, limit: $limit) {
      _id
      name
      offices {
        value {
          address {
            zip
            town
            municipality
          }
        }
      }
    }
  }
`;
