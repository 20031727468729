import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Asset } from 'graphql/queries/SingleLawyerQuery';
import jwtDecode from 'jwt-decode';
import { parseCookies } from 'nookies';
import { Address, Postbox } from 'typings/jurata';
import { RESTLawyer, SubscriptionType } from 'typings/shared';
import { AccessScopeType, DecodedAuthToken } from './auth-utils';

export const NO_LAW_FIRM_KEY = 'no-law-firm-*#£';

const LIMIT = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? 10000 : 60000;
type MethodsType = 'GET' | 'POST' | 'PUT' | 'DELETE';
interface FetchProps {
  url: string;
  data: Record<string, any>;
  method?: MethodsType;
  timeout?: number;
  headers?: Record<string, string>;
}

const DEFAULT_CACHE_TIME = 10 * 60 * 1000; // 10 mins
const DEFAULT_STALE_TIME = 5 * 60 * 1000; // 5 mins

export const getQueryInstance = (staleTime = DEFAULT_STALE_TIME, cacheTime = DEFAULT_CACHE_TIME) => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime,
        cacheTime,
      },
    },
  });
};

const api = ({ url, data, method = 'POST', timeout = LIMIT, headers = {} }: FetchProps): Promise<any> => {
  url = process.env.NEXT_PUBLIC_API_URL + url;
  return Promise.race([
    axios({ url, data, method, headers, timeout, timeoutErrorMessage: 'Timeout error' }),
    new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout error')), timeout)),
  ]);
};

export const getLawyerFirmAndOfficesWithLawyers = async (lawyerId: string) => {
  try {
    const cookies = parseCookies();
    const isAuthenticated = !!cookies.token;
    const headers = isAuthenticated
      ? { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` }
      : ({} as Record<string, string>);
    const { data } = await api({
      method: 'GET',
      data: {},
      url: `/api/firms/lawyers/${lawyerId}${isAuthenticated ? '?withLawyers=true' : ''}`,
      headers,
    });

    return data.results;
  } catch (err) {
    return null;
  }
};

export const getLawyerFirmAndOffices = async (lawyerId: string) => {
  try {
    const { data } = await api({
      method: 'GET',
      data: {},
      url: `/api/firms/lawyers/${lawyerId}`,
      headers: {},
    });

    return data.results;
  } catch (err) {
    return null;
  }
};

export interface UpdateFirm {
  name?: string;
  website?: string;
  email?: string;
}

export const updateFirm = async (firmId: string, firm: UpdateFirm) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  const { data } = await api({ method: 'PUT', data: firm, url: `/api/firms/${firmId}`, headers });

  return data.results;
};

export interface UpdateOffice {
  isMain?: boolean;
  phone?: string;
  fax?: string;
  address?: Address;
  postbox?: Postbox;
  lawyers?: Array<string>;
  firm?: string;
}

export const updateOffice = async (firmId: string, officeId: string, office: UpdateOffice) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  const { data } = await api({
    method: 'PUT',
    data: office,
    url: `/api/firms/${firmId}/offices/${officeId}`,
    headers,
  });

  return data.results;
};

export interface CreateOffice {
  isMain: boolean;
  phone?: string;
  fax?: string;
  address: Address;
  postbox?: Postbox;
  lawyers?: Array<string>;
  firm?: string;
}

export const createOffice = async (firmId: string, office: CreateOffice) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  const { data } = await api({
    method: 'POST',
    data: office,
    url: `/api/firms/${firmId}/offices`,
    headers,
  });

  return data.results;
};

export interface CreateLawFirm {
  name: string;
  website: string;
  email: string;
  offices: Array<Omit<CreateOffice, 'firm'>>;
}

export const createLawFirm = async (firmWithOffice: CreateLawFirm) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  const { data } = await api({
    method: 'POST',
    data: firmWithOffice,
    url: `/api/firms`,
    headers,
  });

  return data.results;
};

export const getLawFirms = async () => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  const { data } = await api({
    method: 'GET',
    data: {},
    url: `/api/firms`,
    headers,
  });

  return data.results;
};

export const getOfficesForFirm = async (firmId: string | undefined) => {
  if (!firmId || firmId === NO_LAW_FIRM_KEY) return [];
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  const { data } = await api({
    method: 'GET',
    data: {},
    url: `/api/firms/${firmId}/offices`,
    headers,
  });

  return data.results;
};

export const changeLawyerOffice = async (firmId: string, officeId: string, lawyerId: string) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  const { data } = await api({
    method: 'POST',
    data: {},
    url: `/api/firms/${firmId}/offices/${officeId}/lawyers/${lawyerId}`,
    headers,
  });

  return data.results;
};

export interface LawyerQuery {
  _id: string;
  firstName: string;
  lastName: string;
  firmName?: string;
  municipality?: string;
  accountType: SubscriptionType;
  signUpStatus: 'unclaimed' | 'claimed' | 'onboarded';
  avatar: Asset;
  slug: string;
}

export const getLawyerByName = async (search: string) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  const { data } = await api({
    method: 'GET',
    data: {},
    url: `/api/lawyers?q=${search}`,
    headers,
  });

  return data.results;
};

export const inviteLawyersToOffice = async (
  firmId: string,
  officeId: string,
  lawyers: Array<{ _id: string } | { email: string }>
) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  const { data } = await api({
    method: 'POST',
    data: { invites: lawyers },
    url: `/api/firms/${firmId}/offices/${officeId}/invite-lawyers`,
    headers,
  });

  return data.response;
};

export const updateLawyer = async (lawyerId: string, updatedLawyer: any) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  const { data } = await api({
    method: 'PUT',
    data: { ...updatedLawyer },
    url: `/api/lawyers/${lawyerId}`,
    headers,
  });

  return data.results;
};

export const lawyerAcceptTerms = async (lawyerId: string, terms: { name: string; description: string }) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  const { data } = await api({
    method: 'POST',
    data: { ...terms },
    url: `/api/lawyers/${lawyerId}/accept-terms`,
    headers,
  });

  return data.results;
};

export const getRESTLawyerById = async (lawyerId: string, accessToken?: string): Promise<RESTLawyer> => {
  const cookies = parseCookies();
  const token = cookies.token || accessToken;

  let isClient: boolean = false;
  if (token) {
    try {
      const decode = jwtDecode<DecodedAuthToken>(token);
      isClient = decode.scope?.split(':')[0] === AccessScopeType.client;
    } catch (err) {
      isClient = false;
    }
  }

  const headers = token
    ? { authorization: isClient ? `Bearer ${token}` : `LAWYER-AUTH-TOKEN ${token}` }
    : ({} as Record<string, any>);
  const { data } = await api({
    method: 'GET',
    data: {},
    url: `/api/lawyers/${lawyerId}`,
    headers,
  });

  return data.results;
};

export const getRESTLawyerBySlug = async (slug: string, accessToken?: string): Promise<RESTLawyer> => {
  const cookies = parseCookies();
  const token = cookies.token || accessToken;

  let isClient: boolean = false;
  if (token) {
    try {
      const decode = jwtDecode<DecodedAuthToken>(token);
      isClient = decode.scope?.split(':')[0] === AccessScopeType.client;
    } catch (err) {
      isClient = false;
    }
  }

  const headers = token
    ? { authorization: isClient ? `Bearer ${token}` : `LAWYER-AUTH-TOKEN ${token}` }
    : ({} as Record<string, any>);
  const { data } = await api({
    method: 'GET',
    data: {},
    url: `/api/lawyers/slug/${slug}`,
    headers,
  });

  return data.results;
};

export const removeLawyerFromOffice = async (firmId: string, officeId: string, lawyerId: string) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  const { data } = await api({
    method: 'DELETE',
    data: {},
    url: `/api/firms/${firmId}/offices/${officeId}/lawyers/${lawyerId}`,
    headers,
  });

  return data.results;
};
