import Downshift, { DownshiftState, StateChangeOptions } from 'downshift';
import { ApolloClient, DocumentNode, gql } from '@apollo/client/core';

import axios from 'axios';

import { LawyerSearchResults, LawyerSearchVariables, LAWYER_SEARCH_QUERY } from '../graphql/queries/LawyerSearchQuery';
import { getCantonFromMunicipality } from '../graphql/queries/GetCantonFromMunicipality';

import { LawyerFilters } from '../typings/jurata';
import {
  Query,
  AllLawyerSearchParams,
  LawyerSearchParam,
  filterOptions,
  FilterOption,
  SuggestionOption,
  suggestionOptions,
} from '../typings/shared';
import { Chip, Payload, SearchType, SuggestionResults } from '../components/SearchBox';
import { ResourceBySlugResult } from '../graphql/queries/GetResourceBySlug';
import { lawyersFromData } from './lawyers';
import { abbreviateCanton } from './slugify';
import { splitCommaReducer } from './utils';
import { TFunction } from 'next-i18next';
import { CountsResults } from 'graphql/queries/CountsQuery';
import { getSearchSuggestions } from './jurata-api';

interface GetLawyers {
  offset: number;
  limit: number;
  filters: string;
  apolloClient: ApolloClient<{}>;
  initialChips?: Chip[];
}

// This will clear the input of Downshift once an item is selected
export function downshiftStateReducer(_state: DownshiftState<{}>, changes: StateChangeOptions<{}>) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return {
        ...changes,
        inputValue: '',
      };
    default:
      return changes;
  }
}

const transformParameters = (params: { [key in SearchType | FilterOption]?: string }): [
  LawyerSearchParam,
  string
][] => {
  return Object.entries(params)
    .map((param) => {
      switch (param[0]) {
        case 'practiceArea':
          return ['practiceareas', param[1]];
        case 'town':
        case 'municipality':
          return ['places', param[1]];
        case 'firm':
        case 'lawyer':
        case 'place':
        case 'canton':
          return [param[0] + 's', param[1]];
        case 'experienceGroup':
        case 'savSpecialist':
        case 'lawFirmSizeGroup':
        case 'available':
        case 'languages':
          return [param[0], param[1]];
        default:
          return [undefined, undefined];
      }
    })
    .filter((s) => s[0] && s[1]) as [LawyerSearchParam, string][];
};

/**
 * Mixes a new query object with the query currently in the URL
 * Separates multiple query strings for the same parameter with a comma
 */

export const createFullQuery = (
  add: { [key in SearchType | FilterOption]?: string },
  query: AllLawyerSearchParams
): AllLawyerSearchParams => {
  const newQuery: AllLawyerSearchParams = Object.assign({}, query);

  let newParams = transformParameters(add);
  newParams.forEach((param) => {
    const [key, value] = param;
    if (newQuery[key]) newQuery[key] = `${newQuery[key]},${value}`;
    else newQuery[key] = value;
  });

  return newQuery;
};

/**
 * Removes a single suggestion from the Query in the Url
 * Has to split on the comma if there are multiple suggestions
 * e.g places=zurich,lausanne,geneva
 */
export const removeFromFullQuery = (
  remove: { [key in SearchType | FilterOption]?: string },
  query: AllLawyerSearchParams
): AllLawyerSearchParams => {
  const newQuery: AllLawyerSearchParams = Object.assign({}, query);
  const [key, val] = transformParameters(remove)[0];

  if (newQuery[key]) {
    newQuery[key] = String(newQuery[key as LawyerSearchParam])
      .split(/,(?! )/)
      .filter((el) => el !== val)
      .join(',');
    if (!newQuery[key]) delete newQuery[key];
  }

  return newQuery;
};

const removeTownsFromSuggestions = (results: SuggestionResults) => {
  if (results.places.items.length) {
    const filteredPlaces = results.places.items.filter((place) => place.payload.type !== 'town');
    const offset = results.places.items.length - filteredPlaces.length;
    results = {
      ...results,
      count: results.count - offset,
      places: { items: filteredPlaces, count: filteredPlaces.length },
    };
  }
  return results;
};

const removePreviouslySelectedSuggestions = (results: SuggestionResults, selected: Query = {}) => {
  if (selected) {
    Object.entries(results).forEach(([key, val]) => {
      if (val.items) {
        const filterTerm =
          key === 'places'
            ? (String(selected.places) + ',' + String(selected.cantons)).split(',')
            : String(selected[key]);
        val.items = val.items.filter((item: Payload) => {
          if (filterTerm.indexOf(item.payload.slug || item.payload.name) === -1) return true;
          results.count--;
          val.count--;
          return false;
        });
      }
    });
  }
  return results;
};

const sortSuggestions = (results: SuggestionResults) => {
  Object.entries(results).map(([_, val]) => {
    if (val.items) {
      return val.items.sort((a: any, b: any) => {
        const avatarScoring = 0.5;
        const aScore = a.payload.avatar != '0' ? parseFloat(a.score) / (avatarScoring * 10) : parseFloat(a.score);
        const bScore = b.payload.avatar != '0' ? parseFloat(b.score) / (avatarScoring * 10) : parseFloat(b.score);
        return aScore > bScore ? 1 : bScore > aScore ? -1 : 0;
      });
    }
  });
  return results;
};

const truncateSuggestions = (results: SuggestionResults) => {
  Object.entries(results).map(([_, val]) => {
    if (val.items && val.items.length > 5) {
      val.items.length = 5;
    }
  });
  return results;
};

export const getSuggestionsForSearch = async (
  searchTerm: string,
  query: Query | undefined,
  locale: string = 'de'
): Promise<SuggestionResults | null> => {
  if (!searchTerm || !searchTerm.trim()) return null;
  try {
    const results: SuggestionResults = await getSearchSuggestions(searchTerm.trim(), locale)
      .then((res: any) => res.data.finalResult)
      .catch(async (err) => {
        console.log(err);
        return await axios
          .get(process.env.NEXT_PUBLIC_COCKPIT_URL_PUBLIC + '/api/jurata/suggest?q=' + searchTerm.trim())
          .then((res) => res.data)
          .catch((err) => {
            console.log(err);
            return null;
          });
      });

    const resultsWithoutTowns = removeTownsFromSuggestions(results);
    const resultsWithoutDuplicates = removePreviouslySelectedSuggestions(resultsWithoutTowns, query);
    const sortedResults = sortSuggestions(resultsWithoutDuplicates);
    const truncatedSuggestions = truncateSuggestions(sortedResults);

    return truncatedSuggestions;
  } catch (err) {
    throw new Error(`Something went wrong when trying to get search suggestions:\n${err}`);
  }
};

export const escapeTagFilter = (param: string): string => `${param.replace(/-/g, '\\-')}`;
/**
 * Some params need to be converted to a specific format to be accepted by Redisearch
 * This function performs that conversion: val1,val2 -> { val1 | val2 }
 * @param param string to modify
 */
export const paramToTagFilter = (param: string): string => `{ ${escapeTagFilter(param.replace(/,/g, ' | '))} }`;

/**
 * Builds the filter string to add in the Redisearch API request
 * @param filters filters obtained from the query param
 */
export const buildFilterString = (filters: LawyerFilters): string => {
  const queryString = Object.entries(filters)
    .filter(([_, val]) => val)
    .map(([key, val]) => {
      if (key === 'available' || key === 'savSpecialist') return `(@${key}:${val})`;
      if (key === 'experienceGroup' || key === 'lawFirmSizeGroup' || key === 'languages') {
        if (val.includes(',')) {
          const orQuery = val.replace(/,/g, '|');
          return `@${key}:(${orQuery})`;
        }
        return `@${key}:${val} `;
      } else if (key === 'slug') {
        return val
          .split(',')
          .map((sentence: string) => {
            return (
              '(' +
              sentence
                .split('-')
                .map((term: string) => `(@slug:${term}*)`)
                .join('') +
              ')'
            );
          })
          .join('|');
      } else if (key === 'practiceAreasSlugs') {
        return val
          .split(',')
          .map((practiceArea: string) => `(@practiceAreasSlugs:{${practiceArea}})`)
          .join(' ');
      } else if (val.includes(',')) {
        const orQuery = val.replace(/,/g, '|');
        return `(@${key}:(${orQuery}))`;
      } else {
        const orQuery = val.replace(/ /g, '');
        return `(@${key}:${orQuery})`;
      }
    })
    .join('');
  return queryString;
};

/**
 * Get similar places & practice areas from results
 * @param data lawyer search results
 */
export const similarSearchesData = (data: LawyerSearchResults) => {
  if (data) {
    const { places, practiceAreas } = data.JurataCountIndex;
    const similarPlaces = places && places.data;
    const similarPracticeAreas = practiceAreas && practiceAreas.data;

    if (similarPlaces && similarPracticeAreas) {
      return {
        similarPlaces,
        similarPracticeAreas,
      };
    }
  }

  return { similarPlaces: [], similarPracticeAreas: [] };
};

export const getLawyersGraphQL = async ({ offset, limit, filters, apolloClient, initialChips }: GetLawyers) => {
  // For similarSearches, we first determine the scope of the canton if there is any
  let cantonQuery = initialChips && initialChips.filter((c) => c.type === 'canton')[0];
  let cantonName: string | undefined = cantonQuery && cantonQuery.name;
  let cantonSlug: string | undefined = cantonQuery && cantonQuery.slug;
  if (!cantonQuery) {
    let placeQuery = initialChips && initialChips.filter((c) => c.type === 'place')[0];
    if (placeQuery) {
      let placeQueryName = placeQuery.name;
      cantonName = await getCantonFromMunicipality(placeQueryName, apolloClient as ApolloClient<{}>);
      cantonSlug = `kanton-${abbreviateCanton(cantonName as string)}`;
    }
  }

  const results = await apolloClient.query<LawyerSearchResults, LawyerSearchVariables>({
    query: LAWYER_SEARCH_QUERY,
    variables: {
      offset,
      limit,
      filters,
      filterByCanton: cantonSlug,
    },
  });

  if (results.errors && results.errors.length > 0)
    throw new Error(`An error occured when fetching a list of lawyers: ${results.errors[0]}`);

  const { data } = results;
  const { similarPlaces, similarPracticeAreas } = similarSearchesData(data);

  const similarSearches: any = {
    similarPlaces,
    similarPracticeAreas,
  };
  if (cantonName && cantonSlug) {
    similarSearches.similarCanton = { name: cantonName as string, slug: cantonSlug };
  }

  return {
    lawyers: lawyersFromData(data),
    nearby: data.JurataSearchIndex && data.JurataSearchIndex.nearby,
    count: data.JurataSearchIndex && data.JurataSearchIndex.count,
    aggregations: data.JurataSearchIndex && data.JurataSearchIndex.aggregations,
    similarSearches,
  };
};

/**
 * Takes a query object from the url and transforms it into the chips
 * to display in the search - used on first time SSR only
 * because it does not retain the clicked order
 */

export const getInitialChips = (
  query: { [key: string]: { resource: ResourceBySlugResult } },
  translatedCounts: CountsResults | undefined
): Chip[] => {
  const params = Object.entries(query);
  let chips: Chip[] = [];

  params.map((param) => {
    const { resource } = param[1];
    if (!resource || !resource.payload) return false;
    let type: SearchType | 'lawFirm' = resource.type;
    let name = resource.payload.name;
    if (resource.type === 'lawFirm') type = 'firm';
    if (resource.type === 'lawyer') name = `${resource.payload.firstName} ${resource.payload.lastName}`;
    if (resource.type === 'practiceArea')
      name =
        translatedCounts?.JurataCountIndex.practiceAreas?.data.find((pa) => pa.legacyId === resource.payload._id)
          ?.name || resource.payload.name;
    if (resource.type === 'canton')
      name =
        translatedCounts?.JurataCountIndex.cantons?.data.find((canton) => canton.slug === resource.payload.slug)
          ?.name || resource.payload.name;
    chips = [...chips, { slug: resource.payload.slug, name, type: type as SearchType }];
  });

  return chips;
};

export const buildInitialChipsQuery = (query: { [key in SuggestionOption]?: string }): {
  initialChipsQuery: DocumentNode;
  hasChips: boolean;
} => {
  const params = Object.entries(query);
  let queryString: string[] = [];

  params
    .filter((v) => v[1])
    .map((value: string[]) => {
      const allQueryValues = String(value[1]).split(/,(?! )/);
      allQueryValues.map((value) => {
        if (value) {
          queryString.push(`${value.split('-').join('')}:getResourceBySlug(slug: "${value}") {
            resource {
              type
              payload
            }
          }`);
        }
      });
    });

  if (queryString.length !== 0) {
    return {
      initialChipsQuery: gql(`{${queryString.reduce((a, qS) => `${a} ${qS}`, '')}}`),
      hasChips: true,
    };
  } else {
    return {
      initialChipsQuery: gql(`{ getResourceBySlug(slug: "Irrelevant") { resource } }`),
      hasChips: false,
    };
  }
};

export const initialSearchSuggestions = (t: TFunction): Chip[] =>
  t('search:initialSuggestionChips', { returnObjects: true }) as Array<Chip>;

/**
 * Takes a query object from the url and counts
 * the keys that are related to filters to display
 * to the user on smaller screens
 */

export const filterCounter = (query: AllLawyerSearchParams): number => {
  const typedQueryEntries = Object.entries(query) as [LawyerSearchParam, string][];

  const filterCount = typedQueryEntries.reduce((acc, curr) => {
    const [key, val] = curr;
    if (filterOptions.includes(key as FilterOption)) {
      const count = val.split(',').length;
      return count + acc;
    }
    return acc;
  }, 0);
  return filterCount;
};

/**
 * Takes a query object from the url and creates a string
 * to explain how we get to the count of lawyers by listing
 * all of the filters applied.
 */

export const lawyerQueriesString = (
  queryString: string,
  canton?: string
): { first?: string; last?: string; count: number } => {
  let splitQueryString = queryString.split(',').filter((query) => query && query !== 'undefined');
  if (canton) {
    splitQueryString = splitQueryString.map((el) => `${canton} ${el}`);
  }
  if (splitQueryString.length === 0) return { count: 0 };
  if (splitQueryString.length === 1) return { first: splitQueryString[0], count: 1, last: undefined };
  return { first: splitQueryString.slice(0, -1).join(', '), last: splitQueryString.slice(-1)[0], count: 2 };
};

const validateAsSearchSlug = (slug: string) => {
  const searchableSlugs = ['practiceareas', 'places', 'cantons', 'lawyers', 'firms'];

  return !!searchableSlugs.find((el) => el === slug);
};

export const buildLawFirmDescription = (firm: string, firmLocations: string[]) => {
  return `${firm} in ${firmLocations.reduce((a, c, i) => {
    if (i > 2) return a;
    if (i === 0) return a + c;
    if (i === firmLocations.length - 1) return a + ' und ' + c;
    if (i === 2 && firmLocations.length > 3) return a + ' und ' + (firmLocations.length - 2) + ' weitere Orte';
    return a + ', ' + c;
  }, '')}`;
};

export const grabValidQueryParams = (query: Query): { [key in LawyerSearchParam]?: string[] } => {
  const map = [
    `page`,
    'lawyers',
    'practiceareas',
    'places',
    'firms',
    'experienceGroup',
    'savSpecialist',
    'lawFirmSizeGroup',
    'available',
    'languages',
    'cantons',
  ];
  const cleanQuery: { [key in LawyerSearchParam]?: string[] } = {};
  map.forEach((m) => {
    if (query[m]) {
      cleanQuery[m as LawyerSearchParam] =
        typeof query[m] === 'string' ? [...(query[m] as string).split(',')] : (query[m] as string[]);
    }
  });
  return cleanQuery;
};

// We use this function to blend in search terms from the url path into the overall query
export const mixInPathQuery = (
  shavedQuery: { [key in LawyerSearchParam]?: string[] },
  slugs?: string | string[],
  firmSlug?: string | string[]
): { query: { [key in LawyerSearchParam]?: string[] } | null } => {
  if (firmSlug) {
    const formattedFirmSlug = typeof firmSlug === 'string' ? [firmSlug] : firmSlug || [];
    shavedQuery['firms'] = formattedFirmSlug.filter((p) => typeof p === 'string' && p !== '') as string[];
  } else {
    const formattedSlugs = typeof slugs === 'string' ? [slugs] : slugs || [];
    const pathParams = formattedSlugs.filter((p) => typeof p === 'string' && p !== '') as string[];

    if (pathParams.length > 0) {
      pathParams.map((p) => {
        if (/^kanton-/.test(p)) {
          shavedQuery['cantons'] = ((shavedQuery['cantons'] || []) as string[]).filter((r) => r != p);
          (shavedQuery['cantons'] as string[]).push(p);
        } else if (/-[a-z][a-z]$/.test(p)) {
          shavedQuery['places'] = ((shavedQuery['places'] || []) as string[]).filter((r) => r != p);
          (shavedQuery['places'] as string[]).push(p);
        } else {
          shavedQuery['practiceareas'] = ((shavedQuery['practiceareas'] || []) as string[]).filter((r) => r != p);
          (shavedQuery['practiceareas'] as string[]).push(p);
        }
      });
    }
  }

  return { query: shavedQuery };
};

export const practiceAreaTags = (s: string, chips: Chip[]): string[] => {
  if (!s) return [];

  const paCheck = chips
    .filter((c) => c.type === 'practiceArea')
    .map((c) => c.name)
    .join(',');

  const base = s
    .split(';')
    .sort((a, b) => {
      let numberA = a.split('[').length > 1 ? a.split('[')[1].split(']')[0] : 0;
      let numberB = b.split('[').length > 1 ? b.split('[')[1].split(']')[0] : 0;
      if (numberA > numberB) return -1;
      if (numberA < numberB) return 1;
      return 0;
    })
    .map((a) => (a.split('[').length > 1 ? a.split('[')[0] : a));

  const searchBase = base.sort().sort((a, b) => {
    if (paCheck.includes(a)) return -1;
    if (paCheck.includes(b)) return 1;
    return 0;
  });
  return searchBase;
};

export const generateDynamicCanonical = (query: any = {}, asPath: string, locale?: string) => {
  if (!asPath) return '';
  const parsedPath = asPath.split('/');

  const mappedLanguages = ['de', 'it', 'en', 'fr'];

  const hasLang = !!mappedLanguages.find((el) => el === parsedPath[1]);

  const parsedQuery = Object.keys(query)
    .sort((a, b) => suggestionOptions.indexOf(a as SuggestionOption) - suggestionOptions.indexOf(b as SuggestionOption))
    .reduce((object: any, key: string) => {
      if (!validateAsSearchSlug(key)) return object;

      if (key !== 'lng') {
        object[key] = query[key];
      }
      return object;
    }, {});

  const slugs = Object.values(parsedQuery as string[]).reduce(splitCommaReducer, []);

  const parsedQueryParams = asPath.split('?');
  if (parsedQuery.firms) parsedQueryParams[0] = '/kanzlei';

  let canonical = '';

  if (hasLang) canonical = `${parsedQueryParams[0]}`;
  else if (!hasLang) canonical = `/${locale || 'de'}${parsedQueryParams[0]}`;

  slugs.reverse().forEach((slug: string) => (canonical += `/${slug}`));

  const isFilterFirstParam = !!filterOptions.find(
    (filter) => filter === parsedQueryParams[parsedQueryParams.length - 1].split('=')[0]
  );

  if (isFilterFirstParam) canonical += `?${parsedQueryParams[parsedQueryParams.length - 1].split('&')[0]}`;

  const parsedFilterParamsPath = asPath.split('&');
  let canonicalHasFilter = false;

  if (parsedFilterParamsPath.length > 1) {
    parsedFilterParamsPath.forEach((param) => {
      if (!!filterOptions.find((el) => el === param.split('=')[0])) {
        if (!canonicalHasFilter && !isFilterFirstParam) {
          canonical += `?${param}`;
          canonicalHasFilter = true;
        } else canonical += `&${param}`;
      }
    });
  }

  return canonical;
};
