import React from 'react';
import { useTranslation } from 'next-i18next';
import { Locale } from 'typings/shared';
import Link from './Link';
import { Box, Flex, Link as StyleLink, chakra, Container, Text, Divider } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { css } from '@emotion/react';
import linkedInLogo from 'public/icons/linkedin-unfilled.svg';
import intagramLogo from 'public/icons/instagram.svg';
import {
  LocalizedPracticeAreasMap,
  NEXTJS_API_CONTENT_PRACTICE_AREAS_QUERY,
} from 'graphql/queries/GetLocalizedPracticeAreas';
import { useQuery } from '@apollo/client';
import { LAWYER_DASHBOARD_ROUTES } from 'utils/lawyers';
import AuthenticatedLawyerFooter from './AuthenticatedLawyerFooter';

const NO_OF_MOBILE_LINK_DISPLAYED = 5;

interface LanguageLink {
  name: string;
  locale: Locale;
  weight: number;
}

interface FooterLink {
  text: string;
  link: string;
  weight: number;
  slug?: string;
  locale?: Locale;
}

interface FooterSection {
  title: string;
  weight: number;
  links: Array<FooterLink>;
  isPracticeAreasSection?: boolean;
  isLegalProductsSection?: boolean;
}

const getAboutUs = (locale: string) => {
  let aboutUs = 'about-us';
  switch (locale) {
    case 'fr':
      return 'a-propos-de-nous';
    case 'de':
      return 'ueber-uns';
    case 'it':
      return 'chi-siamo';
  }
  return aboutUs;
};

interface FooterProps {
  lawyerSlug?: string;
}

const Footer: React.FC<FooterProps> = ({ lawyerSlug = '' }) => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const languageLinks = [...(t('footer.languages', { returnObjects: true }) as Array<LanguageLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );
  const sections = [...(t('footer.sections', { returnObjects: true }) as Array<FooterSection>)].sort(
    (section1, section2) => section2.weight - section1.weight
  );

  const { data: translatedPAs } = useQuery<LocalizedPracticeAreasMap>(NEXTJS_API_CONTENT_PRACTICE_AREAS_QUERY, {
    variables: { locale: router.locale },
    context: { clientName: 'nextjs-api' },
    ssr: true,
  });

  const isLawyerDashboard = LAWYER_DASHBOARD_ROUTES.includes(router.pathname);

  return isLawyerDashboard ? (
    <AuthenticatedLawyerFooter />
  ) : (
    <chakra.footer bg='blue.500' color='white' bottom={0} mt='auto'>
      <Container key={'footloot'} maxW='73.75rem' py={16} px={{ base: 12, md: 6 }}>
        <Flex wrap='wrap' justify='space-between' w='100%' flexDir={{ base: 'column', md: 'row' }} sx={{ gap: '1rem' }}>
          <Link route='/'>
            <StyleLink>
              <Image alt='White Logo' src='/icons/logo-white.svg' height={28} width={140} />
            </StyleLink>
          </Link>
          <Flex align={{ base: 'initial', md: 'center' }} flexWrap='wrap'>
            {languageLinks
              .sort((linkA, linkB) => linkB.weight - linkA.weight)
              .map((link, index) => {
                const isActive = router.locale === link.locale;
                const slug = lawyerSlug;
                return (
                  <>
                    <Link
                      key={link.name || `Language link ${index}`}
                      route={router.pathname}
                      lang={link.locale}
                      query={router.query}
                      pathVars={{ slug }}
                    >
                      <StyleLink
                        textDecor={isActive ? 'underline' : 'none'}
                        fontSize='0.9375rem'
                        fontWeight='semibold'
                        _hover={{
                          textDecor: 'underline',
                        }}
                      >
                        {link.name}
                      </StyleLink>
                    </Link>
                    {index < languageLinks.length - 1 && (
                      <Box w='1px' h={{ base: 'inherit', md: '60%' }} bg='white' mx={2} />
                    )}
                  </>
                );
              })}
          </Flex>
        </Flex>

        <Divider color='white' opacity={1} my={8} />

        <Flex
          wrap='wrap'
          justify='space-between'
          w='100%'
          flexDir={{ base: 'column', md: 'row' }}
          sx={{ gap: '2rem' }}
          color='white'
        >
          {sections &&
            sections.map((section) => (
              <Flex flexDir='column' key={section.title} w='13.5rem'>
                <Text fontSize='md' textTransform='uppercase' letterSpacing='0.1rem' fontWeight='bold' mb={8}>
                  {section.title}
                </Text>
                {section.links &&
                  section.links
                    .sort((link1, link2) => link2.weight - link1.weight)
                    .map((link, index) => {
                      const pa =
                        section.isPracticeAreasSection &&
                        link.slug &&
                        translatedPAs?.practiceAreas.items.find((translatedPa) => translatedPa.slug === link.slug);
                      return (
                        <Box
                          key={pa ? link.slug : link.link}
                          as='span'
                          display={{
                            base:
                              index >= NO_OF_MOBILE_LINK_DISPLAYED && !section.isLegalProductsSection
                                ? 'none'
                                : 'initial',
                            md: 'initial',
                          }}
                        >
                          {link.locale && link.link ? (
                            <Link
                              key={link.text}
                              route={link.link.replace(`/${link.locale}`, '')}
                              lang={link.locale}
                              query={router.query}
                            >
                              <StyleLink
                                _hover={{
                                  textDecor: 'underline',
                                }}
                                fontWeight='semibold'
                                mb={2}
                                lineHeight={1.2}
                                fontSize='1.0625rem'
                              >
                                {link.text}
                              </StyleLink>
                            </Link>
                          ) : link.locale ? (
                            <Link key={link.text} route={router.pathname} lang={link.locale} query={router.query}>
                              <StyleLink
                                _hover={{
                                  textDecor: 'underline',
                                }}
                                fontWeight='semibold'
                                mb={2}
                                lineHeight={1.2}
                                fontSize='1.0625rem'
                              >
                                {link.text}
                              </StyleLink>
                            </Link>
                          ) : (
                            <Link
                              route={pa ? 'lawyers' : link.link}
                              query={pa ? { practiceareas: link.slug } : undefined}
                              prefetch={false}
                            >
                              <StyleLink
                                _hover={{
                                  textDecor: 'underline',
                                }}
                                fontWeight='semibold'
                                mb={2}
                                lineHeight={1.2}
                                fontSize='1.0625rem'
                              >
                                {pa ? pa.name : link.text}
                              </StyleLink>
                            </Link>
                          )}
                        </Box>
                      );
                    })}
                {section.isPracticeAreasSection && (
                  <Link route='directory/practice-areas' prefetch={false}>
                    <StyleLink
                      _hover={{
                        textDecor: 'underline',
                      }}
                      fontWeight='semibold'
                      mb={2}
                      lineHeight={1.2}
                      fontSize='1.0625rem'
                    >
                      {t('allPracticeAreas')}
                    </StyleLink>
                  </Link>
                )}
              </Flex>
            ))}
          <Flex flexDir='column' w='13.5rem'>
            <Text textTransform='uppercase' letterSpacing='0.1rem' fontWeight='bold' mb={8} fontSize='md'>
              {t('findALawyer')}
            </Text>
            <Link route='lawyers' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                fontWeight='semibold'
                mb={2}
                lineHeight={1.2}
                fontSize='1.0625rem'
              >
                {t('lawyer')}
              </StyleLink>
            </Link>
            <Link route='directory/places' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                fontWeight='semibold'
                mb={2}
                lineHeight={1.2}
                fontSize='1.0625rem'
              >
                {t('directory/places')}
              </StyleLink>
            </Link>
            <Link route='directory/cantons' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                fontWeight='semibold'
                mb={2}
                lineHeight={1.2}
                fontSize='1.0625rem'
              >
                {t('directory/cantons')}
              </StyleLink>
            </Link>
            <Link route='directory/practice-areas' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                fontWeight='semibold'
                mb={2}
                lineHeight={1.2}
                fontSize='1.0625rem'
              >
                {t('directory/practice-areas')}
              </StyleLink>
            </Link>
            <Link route='directory/law-firms' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                fontWeight='semibold'
                mb={2}
                lineHeight={1.2}
                fontSize='1.0625rem'
              >
                {t('directory/law-firms')}
              </StyleLink>
            </Link>
            <Link route='directory/all-lawyers' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                fontWeight='semibold'
                mb={2}
                lineHeight={1.2}
                fontSize='1.0625rem'
              >
                {t('directory/all-lawyers')}
              </StyleLink>
            </Link>
            <Text textTransform='uppercase' letterSpacing='0.1rem' fontWeight='extrabold' mt={6} mb={8} fontSize='md'>
              {t('forLawyers')}
            </Text>
            <Link route='for-lawyers' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                fontWeight='semibold'
                mb={2}
                lineHeight={1.2}
                fontSize='1.0625rem'
              >
                {t('for-lawyers')}
              </StyleLink>
            </Link>
          </Flex>
        </Flex>

        <Divider color='white' opacity={1} my={8} />

        <Flex
          wrap='wrap'
          justify='space-between'
          w='100%'
          flexDir={{ base: 'column', md: 'row' }}
          sx={{ gap: { base: '2rem', md: '1rem' } }}
          color='white'
        >
          <Flex flexDir={{ base: 'column', md: 'row' }} sx={{ gap: { base: '2rem', md: '4rem' } }}>
            <Flex flexDir='column'>
              <Link route={getAboutUs(router.locale as string)} prefetch={false} key={'about-us'} lang={router.locale}>
                <StyleLink
                  _hover={{
                    textDecor: 'underline',
                  }}
                  mb={2}
                  lineHeight={1.2}
                  fontWeight='semibold'
                  fontSize='0.9375rem'
                >
                  {t('about-us')}
                </StyleLink>
              </Link>
              <Link route='info/contact' prefetch={false}>
                <StyleLink
                  _hover={{
                    textDecor: 'underline',
                  }}
                  fontWeight='semibold'
                  mb={2}
                  lineHeight={1.2}
                  fontSize='0.9375rem'
                >
                  {t('info/contact')}
                </StyleLink>
              </Link>
              <Link route='info/faq' prefetch={false}>
                <StyleLink
                  _hover={{
                    textDecor: 'underline',
                  }}
                  fontWeight='semibold'
                  mb={2}
                  lineHeight={1.2}
                  fontSize='0.9375rem'
                >
                  {t('info/faq')}
                </StyleLink>
              </Link>
            </Flex>
            <Flex flexDir='column'>
              <Link route='info/terms-of-use' prefetch={false}>
                <StyleLink
                  _hover={{
                    textDecor: 'underline',
                  }}
                  fontWeight='semibold'
                  mb={2}
                  lineHeight={1.2}
                  fontSize='0.9375rem'
                >
                  {t('info/terms-of-use')}
                </StyleLink>
              </Link>
              <Link route='info/data-protection' prefetch={false}>
                <StyleLink
                  _hover={{
                    textDecor: 'underline',
                  }}
                  fontWeight='semibold'
                  mb={2}
                  lineHeight={1.2}
                  fontSize='0.9375rem'
                >
                  {t('info/data-protection')}
                </StyleLink>
              </Link>
            </Flex>
          </Flex>
          <Flex flexDir='column' maxW='10rem'>
            <Flex mb={8} color='white' fontSize='0.9375rem' flexDir='column'>
              <Text whiteSpace='pre-line'>{t('companyAddress')}</Text>
            </Flex>
            <Flex>
              <StyleLink target='_blank' href='https://www.linkedin.com/company/jurata' mr={4}>
                <Box
                  css={css`
                    & svg {
                      fill: white;
                    }
                  `}
                  w='1.0625rem'
                  h='1.0625rem'
                  dangerouslySetInnerHTML={{ __html: linkedInLogo }}
                />
              </StyleLink>
              <StyleLink target='_blank' href='https://www.instagram.com/jurata.ch'>
                <Box
                  css={css`
                    & svg {
                      fill: white;
                    }
                  `}
                  w='1.0625rem'
                  h='1.0625rem'
                  dangerouslySetInnerHTML={{ __html: intagramLogo }}
                />
              </StyleLink>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </chakra.footer>
  );
};

export const UnstyledFooter = ({
  isHomepage,
  serverPAs,
}: {
  isHomepage?: boolean;
  serverPAs?: LocalizedPracticeAreasMap;
}) => {
  const router = useRouter();
  const { t } = useTranslation('common');

  const languageLinks = [...(t('footer.languages', { returnObjects: true }) as Array<LanguageLink>)].sort(
    (link1, link2) => link2.weight - link1.weight
  );
  const sections = [...(t('footer.sections', { returnObjects: true }) as Array<FooterSection>)].sort(
    (section1, section2) => section2.weight - section1.weight
  );

  const { data: clientPAs } = useQuery<LocalizedPracticeAreasMap>(NEXTJS_API_CONTENT_PRACTICE_AREAS_QUERY, {
    variables: { locale: router.locale },
    context: { clientName: 'nextjs-api' },
    skip: !!serverPAs,
  });

  const translatedPAs = serverPAs || clientPAs;

  return (
    <chakra.footer bg='#3c5074' color='white' bottom={0} mt='auto'>
      <Container
        maxW='73.75rem'
        css={css`
          padding: 4rem 3rem;
          @media (min-width: 48em) {
            padding: 4rem 1.5rem;
          }
        `}
        w='100%'
        margin='auto'
      >
        <Flex
          wrap='wrap'
          justify='space-between'
          w='100%'
          css={css`
            flex-direction: column;
            gap: 1rem;
            @media (min-width: 48em) {
              flex-direction: row;
            }
          `}
        >
          <Link route='/'>
            <StyleLink>
              <Image alt='White Logo' src='/icons/logo-white.svg' height={28} width={140} />
            </StyleLink>
          </Link>
          {isHomepage && (
            <Flex
              css={css`
                align-items: initial;
                @media (min-width: 48em) {
                  align-items: center;
                }
              `}
              flexWrap='wrap'
            >
              {languageLinks
                .sort((linkA, linkB) => linkB.weight - linkA.weight)
                .map((link, index) => {
                  const isActive = router.locale === link.locale;
                  const slug = router.query.slug;
                  return (
                    <>
                      <Link
                        key={link.name || `Language link ${index}`}
                        route={router.pathname}
                        lang={link.locale}
                        query={router.query}
                        pathVars={{ slug }}
                      >
                        <StyleLink
                          textDecor={isActive ? 'underline' : 'none'}
                          fontSize='0.9375rem'
                          fontWeight='600'
                          color='white'
                          _hover={{
                            textDecor: 'underline',
                          }}
                        >
                          {link.name}
                        </StyleLink>
                      </Link>
                      {index < languageLinks.length - 1 && (
                        <Box
                          w='1px'
                          css={css`
                            height: inherit;
                            @media (min-width: 48em) {
                              height: 60%;
                            }
                          `}
                          bg='white'
                          mx={8}
                        />
                      )}
                    </>
                  );
                })}
            </Flex>
          )}
        </Flex>

        <Box w='100%' bg='white' h='1px' my={32} />

        <Flex
          wrap='wrap'
          justify='space-between'
          w='100%'
          css={css`
            flex-direction: column;
            @media (min-width: 48em) {
              flex-direction: row;
            }
          `}
          sx={{ gap: '2rem' }}
          color='white'
        >
          {sections &&
            sections.map((section) => (
              <Flex flexDir='column' key={section.title} w='13.5rem'>
                <Text textTransform='uppercase' letterSpacing='0.1rem' fontWeight='700' mb={32} fontSize='1rem'>
                  {section.title}
                </Text>
                {section.links &&
                  section.links
                    .sort((link1, link2) => link2.weight - link1.weight)
                    .map((link, index) => {
                      const pa =
                        section.isPracticeAreasSection &&
                        link.slug &&
                        translatedPAs?.practiceAreas.items.find((translatedPa) => translatedPa.slug === link.slug);
                      return (
                        <Box
                          key={pa ? link.slug : link.link}
                          as='span'
                          css={css`
                            display: ${index >= NO_OF_MOBILE_LINK_DISPLAYED && !section.isLegalProductsSection
                              ? 'none'
                              : 'initial'};
                            @media (min-width: 48em) {
                              display: initial;
                            }
                          `}
                          mb={8}
                          lineHeight={1.2}
                        >
                          {link.locale && link.link ? (
                            <Link
                              key={link.text}
                              route={link.link.replace(`/${link.locale}`, '')}
                              lang={link.locale}
                              query={router.query}
                            >
                              <StyleLink
                                _hover={{
                                  textDecor: 'underline',
                                }}
                                fontWeight='600'
                                mb={8}
                                color='white'
                                lineHeight={1.2}
                                fontSize='1.0625rem'
                              >
                                {link.text}
                              </StyleLink>
                            </Link>
                          ) : link.locale ? (
                            <Link key={link.text} route={router.pathname} lang={link.locale} query={router.query}>
                              <StyleLink
                                _hover={{
                                  textDecor: 'underline',
                                }}
                                fontWeight='600'
                                mb={8}
                                color='white'
                                lineHeight={1.2}
                                fontSize='1.0625rem'
                              >
                                {link.text}
                              </StyleLink>
                            </Link>
                          ) : (
                            <Link
                              route={pa ? 'lawyers' : link.link}
                              query={pa ? { practiceareas: link.slug } : undefined}
                              prefetch={false}
                            >
                              <StyleLink
                                _hover={{
                                  textDecor: 'underline',
                                }}
                                fontWeight='600'
                                mb={8}
                                lineHeight={1.2}
                                color='white'
                                fontSize='1.0625rem'
                              >
                                {pa ? pa.name : link.text}
                              </StyleLink>
                            </Link>
                          )}
                        </Box>
                      );
                    })}
                {section.isPracticeAreasSection && (
                  <Link route='directory/practice-areas' prefetch={false}>
                    <StyleLink
                      _hover={{
                        textDecor: 'underline',
                      }}
                      fontWeight='600'
                      mb={8}
                      lineHeight={1.2}
                      color='white'
                      fontSize='1.0625rem'
                    >
                      {t('allPracticeAreas')}
                    </StyleLink>
                  </Link>
                )}
              </Flex>
            ))}
          <Flex flexDir='column' w='13.5rem'>
            <Text textTransform='uppercase' letterSpacing='0.1rem' fontWeight='700' mb={32} fontSize='1rem'>
              {t('findALawyer')}
            </Text>
            <Link route='lawyers' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                lineHeight={1.2}
                fontWeight='600'
                color='white'
                mb={8}
                fontSize='1.0625rem'
              >
                {t('lawyer')}
              </StyleLink>
            </Link>
            <Link route='directory/places' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                fontWeight='600'
                color='white'
                mb={8}
                lineHeight={1.2}
                fontSize='1.0625rem'
              >
                {t('directory/places')}
              </StyleLink>
            </Link>
            <Link route='directory/cantons' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                fontWeight='600'
                color='white'
                mb={8}
                lineHeight={1.2}
                fontSize='1.0625rem'
              >
                {t('directory/cantons')}
              </StyleLink>
            </Link>
            <Link route='directory/practice-areas' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                fontWeight='600'
                color='white'
                mb={8}
                lineHeight={1.2}
                fontSize='1.0625rem'
              >
                {t('directory/practice-areas')}
              </StyleLink>
            </Link>
            <Link route='directory/law-firms' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                fontWeight='600'
                color='white'
                mb={8}
                lineHeight={1.2}
                fontSize='1.0625rem'
              >
                {t('directory/law-firms')}
              </StyleLink>
            </Link>
            <Link route='directory/all-lawyers' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                fontWeight='600'
                color='white'
                mb={8}
                lineHeight={1.2}
                fontSize='1.0625rem'
              >
                {t('directory/all-lawyers')}
              </StyleLink>
            </Link>
            <Text textTransform='uppercase' letterSpacing='0.1rem' fontWeight='600' mt={24} mb={32} fontSize='1rem'>
              {t('forLawyers')}
            </Text>
            <Link route='for-lawyers' prefetch={false}>
              <StyleLink
                _hover={{
                  textDecor: 'underline',
                }}
                color='white'
                fontWeight='600'
                mb={8}
                lineHeight={1.2}
                fontSize='1.0625rem'
              >
                {t('for-lawyers')}
              </StyleLink>
            </Link>
          </Flex>
        </Flex>

        <Box w='100%' bg='white' h='1px' my={32} />

        <Flex
          wrap='wrap'
          justify='space-between'
          w='100%'
          css={css`
            flex-direction: column;
            gap: 2rem;
            @media (min-width: 48em) {
              flex-direction: row;
              gap: 1rem;
            }
          `}
          fontSize='1rem'
          color='white'
        >
          <Flex
            css={css`
              flex-direction: column;
              gap: 2rem;
              @media (min-width: 48em) {
                flex-direction: row;
                gap: 4rem;
              }
            `}
          >
            <Flex flexDir='column'>
              <Link route={getAboutUs(router.locale as string)} prefetch={false} key={'about-us'} lang={router.locale}>
                <StyleLink
                  _hover={{
                    textDecor: 'underline',
                  }}
                  fontWeight='600'
                  color='white'
                  mb={8}
                  lineHeight={1.2}
                  fontSize='0.9375rem'
                >
                  {t('about-us')}
                </StyleLink>
              </Link>
              <Link route='info/contact' prefetch={false}>
                <StyleLink
                  _hover={{
                    textDecor: 'underline',
                  }}
                  fontWeight='600'
                  color='white'
                  mb={8}
                  lineHeight={1.2}
                  fontSize='0.9375rem'
                >
                  {t('info/contact')}
                </StyleLink>
              </Link>
              <Link route='info/faq' prefetch={false}>
                <StyleLink
                  _hover={{
                    textDecor: 'underline',
                  }}
                  fontWeight='600'
                  color='white'
                  mb={8}
                  lineHeight={1.2}
                  fontSize='0.9375rem'
                >
                  {t('info/faq')}
                </StyleLink>
              </Link>
            </Flex>
            <Flex flexDir='column'>
              <Link route='info/terms-of-use' prefetch={false}>
                <StyleLink
                  _hover={{
                    textDecor: 'underline',
                  }}
                  fontWeight='600'
                  color='white'
                  mb={8}
                  lineHeight={1.2}
                  fontSize='0.9375rem'
                >
                  {t('info/terms-of-use')}
                </StyleLink>
              </Link>
              <Link route='info/data-protection' prefetch={false}>
                <StyleLink
                  _hover={{
                    textDecor: 'underline',
                  }}
                  fontWeight='600'
                  color='white'
                  mb={8}
                  lineHeight={1.2}
                  fontSize='0.9375rem'
                >
                  {t('info/data-protection')}
                </StyleLink>
              </Link>
            </Flex>
          </Flex>
          <Flex flexDir='column' maxW='9rem'>
            <Text color='white' fontSize='0.9375rem' mb={32}>
              {t('companyAddress')}
            </Text>
            <Flex>
              <StyleLink target='_blank' href='https://www.linkedin.com/company/jurata' mr={16}>
                <Box
                  css={css`
                    & svg {
                      fill: white;
                    }
                  `}
                  w='1.0625rem'
                  h='1.0625rem'
                  dangerouslySetInnerHTML={{ __html: linkedInLogo }}
                />
              </StyleLink>
              <StyleLink target='_blank' href='https://www.instagram.com/jurata.ch' mt={2}>
                <Box
                  css={css`
                    & svg {
                      fill: white;
                    }
                  `}
                  w='1.0625rem'
                  h='1.0625rem'
                  dangerouslySetInnerHTML={{ __html: intagramLogo }}
                />
              </StyleLink>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </chakra.footer>
  );
};

export default Footer;
