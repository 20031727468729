module.exports = [
  { language: 'it', localName: '/', pathName: 'index', source: '/', destination: '/it' },
  { language: 'it', localName: '/', pathName: '/', source: '/', destination: '/it' },
  {
    language: 'it',
    localName: 'avvocati',
    pathName: 'lawyers',
    source: '/it/avvocati/:slugs*',
    destination: '/it/lawyers',
  },
  {
    language: 'it',
    localName: 'avvocato',
    pathName: 'lawyer/[slug]',
    source: '/it/avvocato/:slug',
    destination: '/it/lawyer/:slug',
  },
  {
    language: 'it',
    localName: 'avvocato',
    pathName: 'lawyer',
    source: '/it/avvocato',
    destination: '/it/lawyer',
  },
  {
    language: 'it',
    localName: 'archivio-casi/condizioni',
    pathName: 'pool/conditions',
    source: '/it/archivio-casi/condizioni',
    destination: '/it/pool/conditions',
  },
  {
    language: 'it',
    localName: 'archivio-casi/rivendica',
    pathName: 'pool/claim/[caseId]',
    source: '/it/archivio-casi/rivendica/:caseId',
    destination: '/it/pool/claim/:caseId',
  },
  {
    language: 'it',
    localName: 'round-di-finanziamento',
    pathName: 'news/investment-round',
    source: '/it/round-di-finanziamento',
    destination: '/it/news/investment-round',
  },
  {
    language: 'it',
    localName: 'luogo',
    pathName: 'directory/places',
    source: '/it/luogo',
    destination: '/it/directory/places',
  },
  {
    language: 'it',
    localName: 'luogo',
    pathName: 'directory/places/[letter]',
    source: '/it/luogo/:letter',
    destination: '/it/directory/places/:letter',
  },
  {
    language: 'it',
    localName: 'tutti-gli-avvocati',
    pathName: 'directory/all-lawyers',
    source: '/it/tutti-gli-avvocati',
    destination: '/it/directory/all-lawyers',
  },
  {
    language: 'it',
    localName: 'tutti-gli-avvocati',
    pathName: 'directory/all-lawyers/[letter]',
    source: '/it/tutti-gli-avvocati/:letter',
    destination: '/it/directory/all-lawyers/:letter',
  },
  {
    language: 'it',
    localName: 'studi-legali',
    pathName: 'directory/law-firms',
    source: '/it/studi-legali',
    destination: '/it/directory/law-firms',
  },
  {
    language: 'it',
    localName: 'studi-legali',
    pathName: 'directory/law-firms/[letter]',
    source: '/it/studi-legali/:letter',
    destination: '/it/directory/law-firms/:letter',
  },
  {
    language: 'it',
    localName: 'aree-di-pratica',
    pathName: 'directory/practice-areas',
    source: '/it/aree-di-pratica',
    destination: '/it/directory/practice-areas',
  },
  {
    language: 'it',
    localName: 'aree-di-pratica',
    pathName: 'directory/practice-areas/[slug]',
    source: '/it/aree-di-pratica/:slug',
    destination: '/it/directory/practice-areas/:slug',
  },
  {
    language: 'it',
    localName: 'cantoni',
    pathName: 'directory/cantons',
    source: '/it/cantoni',
    destination: '/it/directory/cantons',
  },
  { language: 'it', localName: 'login', pathName: 'login', source: '/it/login', destination: '/it/login' },
  {
    language: 'it',
    localName: 'studio-legale',
    pathName: 'firm',
    source: '/it/studio-legale/:firm',
    destination: '/it/lawyers?firms=:firm',
  },
  {
    language: 'it',
    localName: 'studio-legale',
    pathName: 'firm',
    source: '/it/studio-legale',
    destination: '/it/lawyers',
  },
  {
    language: 'it',
    localName: 'il-mio-profilo',
    pathName: 'profile/my-profile',
    source: '/it/il-mio-profilo',
    destination: '/it/profile/my-profile',
  },
  {
    language: 'it',
    localName: 'il-mio-profilo/profilo/modifica',
    pathName: 'profile/edit-profile',
    source: '/it/il-mio-profilo/profilo/modifica',
    destination: '/it/profile/edit-profile',
  },
  {
    language: 'it',
    localName: 'il-mio-profilo/profilo/modifica-profilo-breve',
    pathName: 'profile/edit-short-profile',
    source: '/it/il-mio-profilo/profilo/modifica-profilo-breve',
    destination: '/it/profile/edit-short-profile',
  },
  {
    language: 'it',
    localName: 'il-mio-profilo/aree-di-pratica/modifica',
    pathName: 'profile/edit-practice-areas',
    source: '/it/il-mio-profilo/aree-di-pratica/modifica',
    destination: '/it/profile/edit-practice-areas',
  },
  {
    language: 'it',
    localName: 'il-mio-profilo/carriera/modifica',
    pathName: 'profile/edit-career',
    source: '/it/il-mio-profilo/carriera/modifica',
    destination: '/it/profile/edit-career',
  },
  {
    language: 'it',
    localName: 'il-mio-profilo/traguardi/modifica',
    pathName: 'profile/edit-engagement',
    source: '/it/il-mio-profilo/traguardi/modifica',
    destination: '/it/profile/edit-engagement',
  },
  {
    language: 'it',
    localName: 'richieste',
    pathName: 'requests',
    source: '/it/richieste/:ids*',
    destination: '/it/requests',
  },
  {
    language: 'it',
    localName: 'archivio-casi',
    pathName: 'pool',
    source: '/it/archivio-casi/:id*',
    destination: '/it/pool',
  },
  {
    language: 'it',
    localName: 'ricevi-mandati',
    pathName: 'for-lawyers',
    source: '/it/ricevi-mandati',
    destination: '/it/for-lawyers',
  },
  {
    language: 'it',
    localName: 'onboarding',
    pathName: 'onboarding',
    source: '/it/onboarding',
    destination: '/it/onboarding',
  },
  {
    language: 'it',
    localName: 'password-dimenticata',
    pathName: 'forgot-password',
    source: '/it/password-dimenticata',
    destination: '/it/forgot-password',
  },
  {
    language: 'it',
    localName: 'impostazioni',
    pathName: 'settings',
    source: '/it/impostazioni',
    destination: '/it/settings',
  },
  {
    language: 'it',
    localName: 'impostazioni/account',
    pathName: 'settings/account',
    source: '/it/impostazioni/account',
    destination: '/it/settings/account',
  },
  {
    language: 'it',
    localName: 'impostazioni/abbonamento',
    pathName: 'settings/subscription',
    source: '/it/impostazioni/abbonamento',
    destination: '/it/settings/subscription',
  },
  {
    language: 'it',
    localName: 'abbonamento',
    pathName: 'subscription-public',
    source: '/it/abbonamento/:slug',
    destination: '/it/subscription-public?slug=:slug',
  },
  {
    language: 'it',
    localName: 'abbonamento',
    pathName: 'subscription-public',
    source: '/it/abbonamento',
    destination: '/it/subscription-public',
  },
  {
    language: 'it',
    localName: 'pre-abbonamento',
    pathName: 'presubscription',
    source: '/it/pre-abbonamento',
    destination: '/it/presubscription',
  },
  {
    language: 'it',
    localName: 'imposta-password',
    pathName: 'set-password',
    source: '/it/imposta-password/:token',
    destination: '/it/set-password?token=:token',
  },
  {
    language: 'it',
    localName: 'imposta-password',
    pathName: 'set-password',
    source: '/it/imposta-password',
    destination: '/it/set-password',
  },
  {
    language: 'it',
    localName: 'contatti',
    pathName: 'info/contact',
    source: '/it/contatti',
    destination: '/it/info/contact',
  },
  {
    language: 'it',
    localName: 'faq',
    pathName: 'info/faq',
    source: '/it/faq',
    destination: '/it/info/faq',
  },
  {
    language: 'it',
    localName: 'condizioni-di-utilizzo',
    pathName: 'info/terms-of-use',
    source: '/it/condizioni-di-utilizzo',
    destination: '/it/info/terms-of-use',
  },
  {
    language: 'it',
    localName: 'protezione-dati',
    pathName: 'info/data-protection',
    source: '/it/protezione-dati',
    destination: '/it/info/data-protection',
  },
  {
    language: 'it',
    localName: 'invio-caso',
    pathName: 'case-intake',
    source: '/it/invio-caso',
    destination: '/it/case-intake',
  },
  {
    language: 'it',
    localName: 'caso',
    pathName: 'case/[caseId]',
    source: '/it/caso/:caseId',
    destination: '/it/case/:caseId',
  },
  {
    language: 'it',
    localName: 'dashboard/il-mio-profilo',
    pathName: 'dashboard/my-profile',
    source: '/it/dashboard/il-mio-profilo',
    destination: '/it/dashboard/my-profile',
  },
  {
    language: 'it',
    localName: 'dashboard',
    pathName: 'dashboard',
    source: '/it/dashboard',
    destination: '/it/dashboard',
  },
  {
    language: 'it',
    localName: 'iscrizione',
    pathName: 'register',
    source: '/it/iscrizione',
    destination: '/it/register',
  },
  {
    language: 'it',
    localName: 'commission-fees',
    pathName: 'commission-fees',
    source: '/it/commission-fees',
    destination: '/it/commission-fees',
  },
  {
    source: '/sitemap.xml',
    destination: '/it/sitemap.xml',
  },
  {
    language: 'it',
    localName: 'mio-studio-legale/cambia',
    pathName: 'law-firm/change-law-firm',
    source: '/it/mio-studio-legale/cambia',
    destination: '/it/law-firm/change-law-firm',
  },
  {
    language: 'it',
    localName: 'mio-studio-legale/modifica',
    pathName: 'law-firm/edit-law-firm',
    source: '/it/mio-studio-legale/modifica',
    destination: '/it/law-firm/edit-law-firm',
  },
  {
    language: 'it',
    localName: 'mio-studio-legale/nuovo',
    pathName: 'law-firm/new-law-firm',
    source: '/it/mio-studio-legale/nuovo',
    destination: '/it/law-firm/new-law-firm',
  },
  {
    language: 'it',
    localName: 'mio-studio-legale',
    pathName: 'law-firm/my-law-firm',
    source: '/it/mio-studio-legale',
    destination: '/it/law-firm/my-law-firm',
  },
];
