import { CountValuesData } from 'graphql/queries/CountsQuery';
import { TFunction } from 'next-i18next';
import { PoolCase, poolCaseStatus, subscriptionType, SubscriptionType } from '../typings/shared';
import { cantonMap } from './slugify';
import { LocalizedPoolCase } from 'hooks/useLocalizedCases';
import { Request as RequestType } from 'graphql/queries/Requests';

export const MIGRATION_TIMESTAMP = 1709528447000;

// Count initial numbers and set order of practice area filters.
export const countPracticeAreas = (
  allPracticeAreaGroups: string[],
  lawyerPracticeAreaGroups: string[],
  poolCases: LocalizedPoolCase[],
  t: TFunction
) => {
  const practiceAreaGroups = allPracticeAreaGroups.map((p) => {
    return { name: p, count: 0 };
  });

  poolCases.forEach((poolCase) => {
    poolCase.practiceAreaGroups &&
      poolCase.practiceAreaGroups.forEach((p) => {
        const foundPAG = practiceAreaGroups.find((pAG) => pAG.name === p.name);
        if (foundPAG) foundPAG.count = foundPAG.count + 1;
      });
  });

  const temp = practiceAreaGroups.filter((pA) => pA.count !== 0);

  const sorted = temp
    .concat(
      lawyerPracticeAreaGroups
        .filter((ppa) => !temp.find((pa) => ppa === pa.name))
        .map((pa) => {
          return { name: pa, count: 0 };
        })
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) =>
      lawyerPracticeAreaGroups.includes(a.name) ? -1 : lawyerPracticeAreaGroups.includes(b.name) ? 2 : 0
    );

  if (sorted.length === 0) {
    return t('pool:filters.emptyPACount', { returnObjects: true }) as Array<{ name: string; count: number }>;
  }

  return [
    ...sorted.slice(0, lawyerPracticeAreaGroups.length).sort((a, b) => a.name.localeCompare(b.name)),
    ...sorted.slice(lawyerPracticeAreaGroups.length),
  ];
};

// Count initial numbers and set order of canton filters.
export const countCantons = (lawyerPreferredCanton: string, poolCases: LocalizedPoolCase[]) => {
  const cantons = Object.keys(cantonMap).map((c) => {
    return { name: c, count: 0 };
  });

  poolCases.forEach((poolCase) => {
    if (poolCase.location) {
      const foundCa = cantons.find((cantonObj) => cantonObj.name === (poolCase.location && poolCase.location.canton));
      if (foundCa) foundCa.count = foundCa.count + 1;
    }
  });

  const temp = cantons.filter((pA) => pA.count !== 0);
  const sorted = temp
    .concat(!temp.find((c) => c.name === lawyerPreferredCanton) ? [{ name: lawyerPreferredCanton, count: 0 }] : [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => (lawyerPreferredCanton === a.name ? -1 : lawyerPreferredCanton === b.name ? 2 : 0));

  return sorted;
};

// Count initial numbers and but keep order of practice area filters.
export const recountPracticeAreas = (
  practiceAreaGroupsOptions: { name: string; count: number }[],
  poolCases: LocalizedPoolCase[]
) => {
  return practiceAreaGroupsOptions.map((option) => {
    let count = 0;
    poolCases.forEach((c) => {
      if (c.practiceAreaGroups && c.practiceAreaGroups.find((pa) => pa.name === option.name)) {
        count = count + 1;
      }
    });
    return { name: option.name, count };
  });
};

// Count initial numbers and but keep order of canton filters.
export const recountCantons = (cantonOptions: { name: string; count: number }[], poolCases: LocalizedPoolCase[]) =>
  cantonOptions.map((option) => {
    let count = 0;
    poolCases.forEach((c) => {
      if (c.location && c.location.canton === option.name) {
        count = count + 1;
      }
    });
    return { name: option.name, count };
  });

export const buildDescription = (
  searchFilters: { pas: string[]; cantons: string[] },
  poolCases: LocalizedPoolCase[],
  translatedCantons: CountValuesData | undefined,
  t: TFunction
) => {
  const practiceAreasDescription =
    searchFilters.pas.length > 0
      ? ` ${t('common:for')} ${searchFilters.pas.reduce((accum: string, pracAreas: string, i: number) => {
          if (i < searchFilters.pas.length - 2) return accum + pracAreas + ', ';
          if (i === searchFilters.pas.length - 1) return accum + pracAreas;
          return accum + pracAreas + ` ${t('common:and')} `;
        }, '')}`
      : '';

  const cantonsDescription =
    searchFilters.cantons.length > 0
      ? ` ${t('common:in')} ${searchFilters.cantons.reduce((accum: string, cant: string, i: number) => {
          const translatedCanton =
            translatedCantons?.find((canton) => canton.cantonCode === cant)?.name || cantonMap[cant];
          if (i < searchFilters.cantons.length - 2) return accum + translatedCanton + ', ';
          if (i === searchFilters.cantons.length - 1) return accum + translatedCanton;
          return accum + translatedCanton + ` ${t('common:and')} `;
        }, '')}`
      : '';

  let l = poolCases;
  let count =
    l.length === 0
      ? `${t('common:noCases')}`
      : l.length === 1
      ? `${t('common:oneCase')}`
      : `${l.length} ${t('common:cases')}`;

  return `${count}${practiceAreasDescription} ${cantonsDescription}`;
};

export const filterCases = (
  filters: { pas: string[]; cantons: string[]; available: boolean },
  allCases: LocalizedPoolCase[],
  lawyerId: string
) => {
  let presentedCases: LocalizedPoolCase[],
    cantonFilteredCases: LocalizedPoolCase[],
    practiceAreaFilteredCases: LocalizedPoolCase[],
    availableFilteredCases: LocalizedPoolCase[];

  presentedCases = allCases.filter((c) => {
    if (filters.cantons && filters.cantons.length > 0) {
      if (!c.location || !filters.cantons.includes(c.location.canton)) {
        return false;
      }
    }

    if (filters.pas && filters.pas.length > 0) {
      if (
        !c.practiceAreaGroups ||
        c.practiceAreaGroups.length === 0 ||
        !c.practiceAreaGroups.find((pa) => filters.pas.includes(pa.name))
      ) {
        return false;
      }
    }

    if (filters.available && isPoolCaseUnavailable(c, lawyerId)) {
      return false;
    }

    return true;
  });

  availableFilteredCases = allCases.filter((c) => {
    if (filters.cantons && filters.cantons.length > 0) {
      if (!c.location || !filters.cantons.includes(c.location.canton)) {
        return false;
      }
    }
    if (filters.pas && filters.pas.length > 0) {
      if (
        !c.practiceAreaGroups ||
        c.practiceAreaGroups.length === 0 ||
        !c.practiceAreaGroups.find((pa) => filters.pas.includes(pa.name))
      ) {
        return false;
      }
    }
    if (isPoolCaseUnavailable(c, lawyerId)) {
      return false;
    }
    return true;
  });

  cantonFilteredCases = allCases.filter((c) => {
    if (filters.pas && filters.pas.length > 0) {
      if (
        !c.practiceAreaGroups ||
        c.practiceAreaGroups.length === 0 ||
        !c.practiceAreaGroups.find((pa) => filters.pas.includes(pa.name))
      ) {
        return false;
      }
    }
    if (filters.available && isPoolCaseUnavailable(c, lawyerId)) {
      return false;
    }
    return true;
  });

  practiceAreaFilteredCases = allCases.filter((c) => {
    if (filters.cantons && filters.cantons.length > 0) {
      if (!c.location || !filters.cantons.includes(c.location.canton)) {
        return false;
      }
    }
    if (filters.available && isPoolCaseUnavailable(c, lawyerId)) {
      return false;
    }
    return true;
  });

  return { presentedCases, cantonFilteredCases, practiceAreaFilteredCases, availableFilteredCases };
};

export const printRequestPoolPrice = (accountType: SubscriptionType, price: number, t: TFunction) => {
  const isFree = accountType === subscriptionType.PREMIUM || price === 0;
  return isFree ? t('pool:freeCase') : t('pool:paidCase', { price });
};

export const calculateMigrationDate = (acceptanceDate: Date) => {
  const migrationDate = new Date(acceptanceDate.valueOf() + 1000 * 60 * 60 * 24 * 30);
  const date = migrationDate.getDate();
  const month = migrationDate.getMonth() + 1;
  return `${`${date}`.length === 1 ? `0${date}` : date}.${
    `${month}`.length === 1 ? `0${month}` : month
  }.${migrationDate.getFullYear()}`;
};

export const writeAcceptanceDate = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${`${day}`.length === 1 ? `0${day}` : day}.${
    `${month}`.length === 1 ? `0${month}` : month
  }.${date.getFullYear()}`;
};

export const hasRequestsAfterMigration = (requests: RequestType[], timestamp: number) => {
  const requestsAfterMigrationDate = requests.filter((r) => new Date(r.createdAt).valueOf() > timestamp);
  return requestsAfterMigrationDate.length > 0;
};

export const isPoolCaseAvailable = (poolCase?: PoolCase, lawyerId?: string) => {
  if (!poolCase || !lawyerId) {
    return false;
  }

  let isAvailable =
    poolCase.status === poolCaseStatus.AVAILABLE &&
    poolCase.checkedBy &&
    (poolCase.replyCount || 0) < maximumCaseReactions(poolCase);

  if (isAvailable && isSpecialCase(poolCase)) {
    isAvailable = !!lawyerId && isSpecialCaseAndYouAreGod(lawyerId, poolCase);
  }

  return isAvailable;
};

export const isPoolCaseUnavailable = (poolCase?: PoolCase, lawyerId?: string) => {
  if (!poolCase) {
    return true;
  }

  const isUnavailable =
    poolCase.status === poolCaseStatus.CLOSED ||
    poolCase.status === poolCaseStatus.ONGOING ||
    (poolCase.replyCount || 0) >= maximumCaseReactions(poolCase) ||
    isSpecialCaseAndYouAreNotGod(lawyerId, poolCase);

  return isUnavailable;
};

export const isSpecialCase = (poolCase?: PoolCase): boolean => {
  if (
    poolCase?.claimControl &&
    poolCase.claimControl?.requestLimitForPool &&
    poolCase.claimControl?.requestLimitForCase &&
    poolCase.claimControl.requestLimitForPool < poolCase.claimControl.requestLimitForCase &&
    (poolCase.replyCount as number) >= poolCase.claimControl.requestLimitForPool
  )
    return true;

  return false;
};

export const isSpecialCaseAndYouAreNotGod = (lawyerId?: string, poolCase?: PoolCase): boolean => {
  if (!poolCase || !lawyerId) {
    return false;
  }

  if (isSpecialCase(poolCase) && poolCase?.claimControl?.godMode !== lawyerId) return true;

  return false;
};

export const isSpecialCaseAndYouAreGod = (lawyerId?: string, poolCase?: PoolCase): boolean => {
  if (isSpecialCase(poolCase) && poolCase?.claimControl?.godMode === lawyerId) return true;

  return false;
};

export const ALLOWED_REACTIONS_PER_CASE = 5;

export const maximumCaseReactions = (poolCase: PoolCase) =>
  poolCase.claimControl && poolCase.claimControl.requestLimitForCase
    ? poolCase.claimControl.requestLimitForCase
    : ALLOWED_REACTIONS_PER_CASE;
