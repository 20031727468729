import { TFunction } from 'next-i18next';
import { SearchLawyer } from '../typings/jurata';
import { RESTLawyer } from 'typings/shared';

export const generateBaseLD = () => {
  return JSON.stringify(
    {
      '@context': 'http://schema.org/',
      '@type': 'Organization',
      url: 'https://www.jurata.ch/',
      name: 'Jurata – Schweizer Anwaltssuche',
      // TODO Add logo
      // "logo" : "https://www.jurata.ch/assets/logo-10384495995bb2d163ef6c605f9cf78fdc2c64613bdd56b02fc7a7866385e189.svg"
    },
    null,
    2
  );
};

export const generateSingleLawyerLD = (lawyer: RESTLawyer, t: TFunction) => {
  const { firstName, lastName, avatar, firmFunction, practiceAreas, languages, firm, email } = lawyer;

  return JSON.stringify(
    {
      '@context': 'http://schema.org/',
      '@type': 'Person',
      name: `${firstName} ${lastName}`,
      image: avatar && avatar.path,
      jobTitle: firmFunction && firmFunction,
      hasOfferCatalog: {
        '@type': 'OfferCatalog',
        name: t('common:practiceAreas'),
        itemListElement: practiceAreas
          ? practiceAreas.map((practiceArea) => ({
              '@type': 'Offer',
              itemOffered: {
                '@type': 'Service',
                name: practiceArea.practiceArea.name || practiceArea.practiceArea.display,
                availableChannel: {
                  '@type': 'ServiceChannel',
                  availableLanguage: languages
                    ? languages.map((language) => ({
                        '@type': 'Language',
                        name: language.lang,
                      }))
                    : [],
                },
              },
            }))
          : [],
      },
      worksFor: {
        '@type': 'LegalService',
        legalName: firm && firm.name,
        name: firm && firm.name,
        image: avatar && avatar.path,
        address: {
          '@type': 'PostalAddress',
          addressLocality: firm && firm.office.address.municipality,
          addressRegion: firm && firm.office.address.town,
          postOfficeBoxNumber: firm && firm.office.postbox.nr,
          postalCode: firm && firm.office.postbox.zip,
          streetAddress: firm && firm.office.address.street,
        },
        geo: {
          '@type': 'GeoCoordinates',
          latitude: firm && firm.office.latlng && firm.office.latlng.lat,
          longitude: firm && firm.office.latlng && firm.office.latlng.lng,
        },
        telephone: firm && firm.office.phone,
        faxNumber: firm && firm.office.fax,
        email: email,
        sameAs: firm && firm.website,
      },
    },
    null,
    2
  );
};

export const generateLawyerListLD = (lawyers: SearchLawyer[], t: TFunction) => {
  // const { firstName, lastName, avatar, firmFunction, practiceAreas, languages, firm, email } = lawyer;

  return JSON.stringify(
    {
      '@context': 'http://schema.org/',
      '@type': 'ItemList',
      itemListElement:
        lawyers &&
        lawyers.map(
          (
            { firstName, lastName, avatar, firmFunction, lawFirmName, lawFirmPlaceDisplay, lawFirmCantonAbbr, slug },
            index
          ) => ({
            '@type': 'ListItem',
            position: index + 1,
            item: {
              '@type': 'Person',
              name: `${firstName} ${lastName}`,
              image: avatar,
              jobTitle: firmFunction,
              url: `${t('search:lawyerUrlPrefix')}/${slug}`,
              worksFor: {
                '@type': 'Organization',
                name: lawFirmName,
                location: {
                  '@type': 'PostalAddress',
                  addressLocality: lawFirmPlaceDisplay,
                  addressRegion: lawFirmCantonAbbr,
                },
              },
            },
          })
        ),
    },
    null,
    2
  );
};
