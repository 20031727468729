import { gql } from '@apollo/client';
import { BillingType, SubscriptionType } from '../../typings/shared';

export interface Office {
  isMain: boolean;
  phone: string;
  fax: string;
  latlng: {
    lat: number;
    lng: number;
  };
  address: {
    street: string;
    extraLine: string;
    municipality: string;
    municipalitySlug?: string;
    town: string;
    zip: string;
    canton: string;
    cantonAbbr: string;
  };
  postbox: {
    nr: string | null;
    extraLine: string | null;
    location: string | null;
    zip: string | null;
  };
  lawyers: {
    _id: string;
    link: string;
    display: string;
  };
}

export interface PracticeArea {
  value: {
    preference: number;
    practiceArea: {
      _id: string;
      name: string;
      group: string;
      slug: string;
    };
  };
}

export interface Firm {
  _id: string;
  name: string;
  slug: string;
  office: Office;
  website: string;
  lawyersCount: number;
}

export interface Asset {
  _id: string;
  title: string;
  path: string;
  mime: string;
  tags: string[];
  colors: string[];
}

export interface Billing {
  commission: {
    percentage: number;
    cap?: number;
  };
}

export interface Lawyer {
  _id: string;
  hidden: boolean;
  slug: string;
  firstName: string;
  lastName: string;
  hasFirstConsultation: boolean;
  firstConsultationDuration: string;
  firstConsultationPrice: string;
  hasHourlyRate: boolean;
  hourlyRate: string;
  lawyerAdmissionYear: number;
  lawyerAdmissionCanton: string;
  firmFunction: string;
  avatar: Asset;
  available: boolean;
  title: string;
  specialCertificates: string[];
  email: string;
  phone: string;
  gender: string;
  profileText: {
    manual: string;
    generated: string;
  };
  profileTextSelection: string;
  languages: {
    value: {
      lang: string;
      kind: 'correspondance' | 'mother' | 'other';
    };
    _id: string;
  }[];
  firm: Firm | null;
  experience: {
    value: {
      from: string;
      to: string;
      function: string;
      office: string;
    };
  }[];
  education: {
    value: {
      date: string;
      title: string;
      university: string;
    };
  }[];
  hideAge: boolean;
  yearOfBirth: number;
  memberships: string[];
  publications: {
    value: {
      link: string;
      publication: string;
      title: string;
    };
  }[];
  awards: string[];
  practiceAreas: PracticeArea[];
  signUpStatus: 'unclaimed' | 'claimed' | 'onboarded';
  accountType: SubscriptionType;
  subscriptionPrice?: string;
  preferredContact: 'message' | 'callback';
  stripeCustomerId: string;
  showContactData: boolean;
  showClientMessageInEmail: boolean;
  subscriptionPeriod: BillingType;
  termsOfUseAccepted: string;
  mediator: string;
  billing: Billing;
}

export interface GetSingleLawyerResult {
  lawyersCollection: Lawyer[];
}

export const SINGLE_LAWYER_QUERY = gql`
  query ($filter: JsonType!) {
    lawyersCollection(limit: 1, filter: $filter) {
      _id
      hidden
      email
      phone
      gender
      slug
      firstName
      lastName
      avatar {
        _id
        title
        path
      }
      available
      title
      lawyerAdmissionYear
      hasFirstConsultation
      specialCertificates
      firstConsultationDuration
      firstConsultationPrice
      hasHourlyRate
      hourlyRate
      mediator
      profileText {
        generated
        manual
      }
      profileTextSelection
      languages {
        value {
          lang
          kind
        }
      }
      firm {
        _id
        name
        slug
        office
        website
        lawyersCount
      }
      lawyerAdmissionCanton
      firmFunction
      lawyerAdmissionYear
      experience {
        value {
          from
          to
          function
          office
        }
      }
      education {
        value {
          date
          title
          university
        }
      }
      hideAge
      yearOfBirth
      memberships
      awards
      publications {
        value {
          link
          publication
          title
        }
      }
      practiceAreas {
        value {
          preference
          practiceArea {
            _id
            slug
            name
            group
          }
        }
      }
      signUpStatus
      accountType
      subscriptionPrice
      subscriptionPeriod
      preferredContact
      showContactData
      termsOfUseAccepted
      stripeCustomerId
      showClientMessageInEmail
      billing {
        commission {
          cap
          percentage
        }
      }
    }
  }
`;
