import { parseCookies } from 'nookies';
import { ApolloClient, FetchPolicy } from '@apollo/client';

export const withAuthHeaders = { withAuthHeaders: true };

export const refetchQueryFilteredByToken = (query: any) => ({
  query,
  variables: {
    filter: { token: parseCookies().token },
  },
  context: { ...withAuthHeaders },
  fetchPolicy: 'network-only' as FetchPolicy,
});

interface UserMutation {
  apolloClient: ApolloClient<{}>;
  mutation: any;
  variables: Record<string, any>;
  refetch: any[];
}

export interface UpdateProfileData {
  updateLawyerProfile: {
    success: boolean;
  };
}

export const updateUserDataMutation = async ({ apolloClient, mutation, variables, refetch }: UserMutation) => {
  try {
    const { data, errors } = await apolloClient.mutate<UpdateProfileData>({
      mutation,
      variables,
      refetchQueries: refetch.map((query) => refetchQueryFilteredByToken(query)),
      context: { ...withAuthHeaders },
    });

    if (data && data.updateLawyerProfile && data.updateLawyerProfile.success) return true;

    if (errors && errors.length > 0) throw new Error(`Error while updating user data mutation: ${errors[0]}`);

    throw new Error('Oops something went wrong');
  } catch (error) {
    console.log(error);
  }
};
