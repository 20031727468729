import { css } from '@emotion/react';

const svgImage = css`
  display: inline-block;

  svg {
    height: 100%;
  }
`;

export const styles = {
  svgImage,
};
