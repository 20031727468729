import { AppProps } from 'next/app';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { ApolloProvider } from '@apollo/client/react';
import { ChakraProvider } from '@chakra-ui/react';
import SEO from '../next-seo.config';
import { setCookie, destroyCookie } from 'nookies';
import { appWithTranslation } from 'next-i18next';
import { trackAction } from '../utils/analytics';
import PageTrickery from '../lib/PageTrickery';
import WebflowPageTrickery from '../lib/WebflowPageTrickery';
import { extendedTheme } from '../styles/chakra-theme';
import { getApolloClient } from '../data/apollo';
import 'nprogress/nprogress.css';
import '../styles/nprogress.css';
import { DefaultSeo } from 'next-seo';
import Script from 'next/script';
import { generateBaseLD } from 'utils/ld-json';
import { UnstyledGlobal, GlobalStyles } from '../styles/GlobalStyles';
import ErrorBoundary from 'components/error-management/ErrorBoundary';
import { UnstyledFooter } from 'components/Footer';
import { usePreserveScroll } from 'hooks/usePreserverScroll';
import { UnstyledJurataHeader } from 'components/headers/HeaderJurata';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { getQueryInstance } from 'utils/react-query-api';

const nextI18NextConfig = require('../next-i18next.config.js');

const MyApp = ({ Component, pageProps, router, err }: AppProps & { err: any }) => {
  const [urlHistory, setUrlHistory] = React.useState([] as string[]);
  const [queryClient] = React.useState(() => getQueryInstance());
  const { query, asPath } = router;
  const apolloClient = getApolloClient(false);
  var isMessageSent = false;

  const onScroll = () => {
    if (window.scrollY > window.innerHeight * 1.5 && !isMessageSent) {
      trackAction('Scrolled');
      isMessageSent = true;
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll, false);

    if (asPath && asPath.split('?')[1]) {
      setCookie(undefined, 'arrivalQuery', asPath.split('?')[1], {
        path: '/',
      });
    } else {
      destroyCookie(undefined, 'arrivalQuery', {
        path: '/',
      });
    }
    return () => {
      window.removeEventListener('scroll', onScroll, false);
    };
  }, []);

  React.useEffect(() => {
    (async () => {
      if (!pageProps.isWebflow || pageProps.isHomepage) {
        (await import('../utils/auth-utils')).initializeAuthToken(apolloClient);
      }
    })();
  }, []);

  React.useEffect(() => {
    const { gclid } = query;
    if (gclid) {
      setCookie(undefined, 'gclid', gclid as string, { path: '/' });
    }
  }, [query]);

  React.useEffect(() => {
    if (urlHistory[urlHistory.length - 1] !== asPath && urlHistory[urlHistory.length - 1]?.split('?')[0] !== asPath) {
      setUrlHistory((history) => [...history, asPath || '']);
    }
  }, [asPath]);

  React.useEffect(() => {
    if (pageProps.bodyClasses) document.body.className = pageProps.bodyClasses;
  }, [pageProps.bodyClasses]);

  usePreserveScroll();

  if (pageProps.isWebflow) {
    return (
      <>
        <Script
          id='gtm'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`,
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          }}
        />
        <UnstyledGlobal />
        <ApolloProvider client={apolloClient}>
          <WebflowPageTrickery urlHistory={urlHistory}>
            {!pageProps.hasHeader && <UnstyledJurataHeader isFixed />}
            <Component {...pageProps} urlHistory={urlHistory} err={err} />
            {!pageProps.hasFooter && (
              <UnstyledFooter isHomepage={pageProps.isHomepage} serverPAs={pageProps.serverPAs} />
            )}
          </WebflowPageTrickery>
        </ApolloProvider>
      </>
    );
  }

  return (
    <>
      <Script
        id='html5shiv'
        strategy='lazyOnload'
        src='https://cdn.jsdelivr.net/npm/html5shiv@3.7.3/dist/html5shiv.min.js'
      />
      <Script
        id='ld+json'
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: `${generateBaseLD()}`,
        }}
      />
      {/* Google Tag Manager */}
      <Script
        id='gtm'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`,
        }}
      />
      {/* Google Tag Manager (noscript) */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
      <GlobalStyles />
      <ApolloProvider client={apolloClient}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ReactQueryDevtools initialIsOpen={false} />
            <PageTrickery urlHistory={urlHistory}>
              <ChakraProvider theme={extendedTheme}>
                <ErrorBoundary>
                  <DefaultSeo {...SEO} />
                  <Component {...pageProps} urlHistory={urlHistory} err={err} />
                </ErrorBoundary>
              </ChakraProvider>
            </PageTrickery>
          </Hydrate>
        </QueryClientProvider>
      </ApolloProvider>
    </>
  );
};

export default appWithTranslation(MyApp, nextI18NextConfig);
