import { ApolloClient, gql } from '@apollo/client';

interface GetCantonByMunicipality {
  placesCollection: {
    canton: string;
  }[];
}

const GET_CANTON_BY_MUNICIPALITY = gql`
  query placesCollection($filter: JsonType) {
    placesCollection(filter: $filter) {
      canton
    }
  }
`;

export const getCantonFromMunicipality = async (similarMunicipalityQuery: string, apolloClient: ApolloClient<{}>) => {
  const { data, errors } = await apolloClient.query<GetCantonByMunicipality>({
    query: GET_CANTON_BY_MUNICIPALITY,
    variables: { filter: { municipality: similarMunicipalityQuery } },
  });

  if (errors && errors.length > 0) throw new Error(`An error occured fetching engagements: ${errors[0]}`);

  if (data && data.placesCollection && data.placesCollection.length > 0) {
    const cantonFromMunicipality = data.placesCollection[0].canton;
    return cantonFromMunicipality;
  }
};
