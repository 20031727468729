const BLUE = {
  regular: '#4d6693',
  light: '#a6b2c9',
  lighter: '#e8ebf2',
  lightest: '#f3f5f9',
  dark: '#3C5074',
  darker: '#2E3D58',
};

const BLACK = {
  regular: '#161616',
};

const GREEN = {
  regular: '#278684',
  regularDark: '#217271',
  light: '#69c2b4',
  lighter: '#F0F8F7',
  dark: '#375252',
  darker: '#1E8681',
  new: '#299f8c',
};

const GREY = {
  regular: '#949494',
  dark: '#676767',
  midDark: '#757575',
  darker: '#4b4b4b',
  light: '#b3b3b3',
  lighter: '#d8d8d8',
  superLight: '#f6f5f3',
};

const RED = {
  regular: '#d90000',
  light: '#e33a2b',
  lightest: '#fff0ef',
  darker: '#8c0000',
  dark: '#af0000',
};

const PURPLE = {
  regular: '#9952c7',
};

const YELLOW = {
  lightest: '#FFFDF2',
  darkest: '#c5a700',
};

const WHITE = {
  regular: '#ffffff',
};

const SKY = {
  darker: '#13526F',
  dark: '#2f7a9c',
  regular: '#4bb2df',
};

const NAMED = {
  backdrop: 'rgba(0, 0, 0, 0.66)',
  eggshell: '#f6f5f3',
  offWhite: '#f9f9f9',
  sky: '#4bb2df',
  yellowLightest: '#FFFDF2',
};

const UTILITY = {
  body: WHITE.regular,
  tap: 'rgba(0, 0, 0, 0.05)',
  transparent: 'rgba(0, 0, 0, 0.00)',
};

export const COLORS = {
  black: BLACK,
  blue: BLUE,
  green: GREEN,
  grey: GREY,
  red: RED,
  purple: PURPLE,
  white: WHITE,
  named: NAMED,
  utility: UTILITY,
  sky: SKY,
  yellow: YELLOW,
};

export const colorHexes = (colorVariant: ColorVariant = 'black') => {
  switch (colorVariant) {
    case 'white':
      return `${COLORS.white.regular};`;
    case 'black':
      return `${COLORS.black.regular};`;
    case 'grey':
      return `${COLORS.grey.regular};`;
    case 'greyLight':
      return `${COLORS.grey.light};`;
    case 'greyLightest':
      return `${COLORS.grey.superLight};`;
    case 'greyLighter':
      return `${COLORS.grey.lighter};`;
    case 'greyDark':
      return `${COLORS.grey.dark};`;
    case 'greyDarker':
      return `${COLORS.grey.darker};`;
    case 'blue':
      return `${COLORS.blue.regular};`;
    case 'blueLight':
      return `${COLORS.blue.light};`;
    case 'blueDark':
      return `${COLORS.blue.dark};`;
    case 'red':
      return `${COLORS.red.regular};`;
    case 'green':
      return `${COLORS.green.regular};`;
    case 'greenLight':
      return `${COLORS.green.light};`;
    case 'sky':
      return `${COLORS.sky.regular};`;
    default:
      return `${COLORS.black.regular};`;
  }
};

export type ColorVariant =
  | 'black'
  | 'white'
  | 'lightGrey'
  | 'grey'
  | 'greyLight'
  | 'greyLightest'
  | 'greyLighter'
  | 'greyDark'
  | 'greyDarker'
  | 'blue'
  | 'blueLight'
  | 'blueDark'
  | 'red'
  | 'green'
  | 'greenDark'
  | 'greenLight'
  | 'greenLighter'
  | 'sky'
  | 'skyDark'
  | 'purple'
  | 'yellowLightest'
  | 'redLightest'
  | 'yellowDarkest'
  | undefined;
