/* eslint-disable @typescript-eslint/no-use-before-define */
import qs from 'query-string';

type ImageConfig = ImageOptions | number | (number | ImageOptions)[];
export interface ImageOptions {
  width?: number;
  height?: number;
  quality?: 85 | number;
  pixelRatio?: 1 | 2 | number; // default: 1
  mode?: 'thumbnail' | 'bestFit' | 'resize' | 'fitToWidth' | 'fitToHeight';
  filters?: {
    blur?: number;
    brighten?: number;
    contrast?: number;
    darken?: number;
    pixelate?: number;
    desaturate?: 0 | 1;
    flip?: 'x' | 'y';
    colorize?: string;
    invert?: 0 | 1;
    opacity?: number;
    sharpen?: number;
  };
  srcSet?: string;
}

const stringifyObject = (obj: { [key: string]: number | string | number | undefined }) =>
  obj ? Object.keys(obj).reduce((acc, key) => `${acc}&f[${key}]=${obj[key]}`, '') : undefined;

const getImageOptions = (options: ImageOptions | number) =>
  typeof options === 'number' ? { width: options } : options;

export const imageSrcSet = (assetId: string, widths: ImageConfig): string => {
  if (!widths) return '';

  if (!Array.isArray(widths)) return '';

  return widths
    .map((width) => {
      if (typeof width === 'object') {
        const { srcSet, ...opts } = width;

        return `${image(assetId, opts)} ${srcSet || opts.width ? `${srcSet || `${opts.width}w`}` : ''}`;
      }

      return `${image(assetId, { width })} ${width}w`;
    })
    .join(', ');
};

export const image = (assetId: string, options?: ImageConfig) => {
  if (!options || options === {} || options === [])
    return `${process.env.NEXT_PUBLIC_COCKPIT_URL_PUBLIC}/api/public/image?src=${assetId}&o=1&w=300`;

  if (Array.isArray(options)) return imageSrcSet(assetId, options);

  const opts = getImageOptions(options);
  const { width, height, quality, pixelRatio = 1, filters, ...rest } = opts;
  const f = filters ? stringifyObject(filters) : '';

  return `${process.env.NEXT_PUBLIC_COCKPIT_URL_PUBLIC}/api/public/image?${qs.stringify({
    w: width ? Number(width) * pixelRatio : undefined,
    h: height ? Number(height) * pixelRatio : undefined,
    src: assetId,
    q: quality,
    d: 1,
    o: 1,
    ...rest,
  })}${f}`;
};
