import React from 'react';
import nprogress from 'nprogress';
import { useRouter } from 'next/router';
import { UrlHistory } from '../typings/shared';
import { trackPage } from '../utils/analytics';
import { getSecondToLastFromArray } from '../utils/utils';

interface PageTrickeryProps extends UrlHistory {
  children: React.ReactNode;
}

const WebflowPageTrickery: React.FC<PageTrickeryProps> = ({ children, urlHistory }) => {
  const router = useRouter();

  const nprogressStart = () => {
    nprogress.start();
  };

  const nprogressDone = () => {
    nprogress.done();
  };

  const trackPageAnalytics = async (path: string, referrer?: string) => {
    trackPage(path, false, referrer);
  };

  React.useEffect(() => {
    nprogress.configure({ showSpinner: false });
    router.events.on('routeChangeStart', nprogressStart);
    router.events.on('routeChangeComplete', nprogressDone);
    router.events.on('routeChangeError', nprogressDone);

    return () => {
      router.events.off('routeChangeStart', nprogressStart);
      router.events.off('routeChangeComplete', nprogressDone);
      router.events.off('routeChangeError', nprogressDone);
    };
  }, []);

  React.useEffect(() => {
    if (urlHistory.length >= 1 && router.asPath === urlHistory[urlHistory.length - 1]) {
      if (getSecondToLastFromArray(urlHistory).split('?')[0] !== router.asPath.split('?')[0]) {
        trackPageAnalytics(router.pathname, getSecondToLastFromArray(urlHistory));
      }
    } else {
      trackPageAnalytics(router.pathname, undefined);
    }
  }, [urlHistory]);

  return <>{children}</>;
};

export default WebflowPageTrickery;
