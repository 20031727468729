import { ApolloClient, useQuery } from '@apollo/client';
import { NextPageContext } from 'next';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { QueryResult, SetPasswordVariables, SET_PASSWORD_QUERY } from '../graphql/queries/SetLawyerPassword';
import { UserData, UserDataResult, USER_DATA_QUERY } from '../graphql/queries/GetUserInfo';
import { withAuthHeaders } from '../graphql/mutations/utils';
import { GetSingleLawyerResult, SINGLE_LAWYER_QUERY } from '../graphql/queries/SingleLawyerQuery';
import Router, { useRouter } from 'next/router';
import React from 'react';
import { pushi18nRoute } from './i18n-utils';
import { identifyClient, identifyUser, trackAction } from './analytics';
import { Client, GetSingleClient, GET_AUTHENTICATED_CLIENT } from '../graphql/queries/GetClient';
import jwtDecode from 'jwt-decode';
import { requestLogin } from './jurata-api';
import { extractStringsBetweenSquareBrackets } from './utils';
import router from 'next/router';
import { setClientEmailVerification } from './verify-email-address';
import { getApolloClient } from 'data/apollo';

export enum AccessScopeType {
  client = 'client',
  lawyer = 'lawyer',
}

export enum TokenType {
  ACCESS_TOKEN = 'access_token',
  AUTHORIZATION_CODE = 'auth_code',
}

export interface DecodedAuthToken {
  exp: string | number;
  scope: AccessScopeType;
  userId: string;
  data?: string;
  type?: TokenType;
  verify?: string;
}

export const setPasswordGraphQL = async (
  email: string,
  password: string,
  apolloClient: ApolloClient<{}>,
  token?: string
) => {
  const { data, errors } = await apolloClient.mutate<QueryResult, SetPasswordVariables>({
    mutation: SET_PASSWORD_QUERY,
    variables: {
      email,
      password,
    },
    context: { ...withAuthHeaders, token },
  });

  const newToken = data && data.setLawyerEmailPassword.token;
  if (newToken) {
    setCookie(undefined, 'token', newToken, { path: '/' });
    apolloClient.resetStore();
  }

  return {
    success: data,
    error: errors,
  };
};

export const useUserScope = (): AccessScopeType | false => {
  const { token } = parseCookies();
  try {
    const decode = jwtDecode<DecodedAuthToken>(token);
    return (decode.scope?.split(':')[0] as AccessScopeType) || false;
  } catch (err) {
    return false;
  }
};

// Only use this client side !
export const getToken = (ctx?: NextPageContext): string | false => {
  const { token } = parseCookies(ctx);
  return token ? token : false;
};

export const getFullUserDataClientSide = async (apolloClient: ApolloClient<{}>) => {
  const token = getToken();

  if (!token) return false;
  let decode;
  try {
    decode = jwtDecode<DecodedAuthToken>(token);
  } catch (err) {
    console.warn('getFullUserDataClientSide', err);
  }

  const isClient = decode?.scope?.split(':')[0] === AccessScopeType.client;

  const { data } = await apolloClient.query<UserDataResult | GetSingleClient>({
    query: isClient ? GET_AUTHENTICATED_CLIENT : USER_DATA_QUERY,
    variables: isClient ? { id: decode?.userId } : { filter: { token } },
    context: isClient ? { ...withAuthHeaders } : { token, ...withAuthHeaders },
  });

  const user = isClient ? (data as GetSingleClient)?.client : (data as UserDataResult)?.lawyersCollection[0];

  return user;
};

export const logout = (client: ApolloClient<{}>) => {
  destroyCookie(undefined, 'token', { path: '/' });
  destroyCookie(undefined, TokenType.AUTHORIZATION_CODE, { path: '/' });
  client.clearStore();
  pushi18nRoute(Router, { route: '/' });
  trackAction('Logout', client);
};

export const expire = async (client: ApolloClient<{}>, ctx?: NextPageContext) => {
  destroyCookie(ctx, 'token', { path: '/' });
  destroyCookie(ctx, TokenType.AUTHORIZATION_CODE, { path: '/' });
  await client.clearStore();
  pushi18nRoute(Router, { route: 'login', query: { expired: 'true' } });
};

export const useUserData = (scope: AccessScopeType = AccessScopeType.lawyer): false | UserData | Client => {
  const { token } = parseCookies();
  let decode: any;
  let isClient = scope === AccessScopeType.client;

  if (token) {
    try {
      decode = jwtDecode<DecodedAuthToken>(token);
      isClient = decode.scope?.split(':')[0] === AccessScopeType.client;
    } catch (err) {
      console.warn(err);
    }
  }

  const { data } = useQuery<GetSingleClient | UserDataResult>(isClient ? GET_AUTHENTICATED_CLIENT : USER_DATA_QUERY, {
    variables: isClient ? { id: decode?.userId } : { filter: { token } },
    context: isClient ? { ...withAuthHeaders } : { token, ...withAuthHeaders },
    skip: !token,
    ssr: false,
  });

  const user = isClient ? (data as GetSingleClient)?.client : (data as UserDataResult)?.lawyersCollection[0];

  return user && user._id ? user : false;
};

export const useIsAuth = (scope?: AccessScopeType): boolean => {
  const { token } = parseCookies();
  let decode: any;
  let isClient = scope === AccessScopeType.client;

  if (token && !scope) {
    try {
      decode = jwtDecode<DecodedAuthToken>(token);
      isClient = decode.scope?.split(':')[0] === AccessScopeType.client;
    } catch (err) {
      isClient = false;
    }
  }

  const { data } = useQuery<GetSingleClient | UserDataResult>(isClient ? GET_AUTHENTICATED_CLIENT : USER_DATA_QUERY, {
    variables: isClient ? { id: decode?.userId } : { filter: { token } },
    skip: !token,
    context: isClient ? { ...withAuthHeaders } : { token, ...withAuthHeaders },
    ssr: false,
  });

  const isLoggedIn = isClient
    ? !!(data as GetSingleClient)?.client._id
    : !!(data as UserDataResult)?.lawyersCollection[0]?._id;

  return isLoggedIn;
};

export const validateToken = async (
  token: string,
  apolloClient: ApolloClient<{}>
): Promise<UserData | Client | false> => {
  if (!token) return false;
  let decode: DecodedAuthToken | any = {};
  let access_token = token;

  try {
    decode = jwtDecode<DecodedAuthToken>(token);
    if (decode.exp && Number(decode.exp) * 1000 <= Date.now()) return false;
    if (decode.type === TokenType.AUTHORIZATION_CODE) {
      const data = await requestLogin({ grant_type: 'authorization_code', code: token });
      if (decode.verify && data?.data?.scope.includes(AccessScopeType.client) && data?.data?.verification === true) {
        setClientEmailVerification();
      }
      access_token = data.data.access_token;
      decode = jwtDecode<DecodedAuthToken>(access_token);
      setCookie(undefined, 'token', access_token, { path: '/' });
      destroyCookie(undefined, TokenType.AUTHORIZATION_CODE, {
        path: '/',
      });
      return false;
    }
  } catch (err: any) {
    console.warn('ValidateToken', err.message);
    destroyCookie(undefined, TokenType.AUTHORIZATION_CODE, {
      path: '/',
    });
    if (!parseCookies()['token']) {
      const { asPath } = router;
      pushi18nRoute(router, {
        route: 'login',
        query: { forward: asPath },
      });
    }
    return false;
  }

  const isClient = decode?.scope?.split(':')[0] === AccessScopeType.client;

  if (decode && decode.type && decode.type !== TokenType.ACCESS_TOKEN) return false;
  const { data } = await apolloClient.query<UserDataResult | GetSingleClient>({
    query: isClient ? GET_AUTHENTICATED_CLIENT : USER_DATA_QUERY,
    variables: isClient ? { id: decode?.userId } : { filter: { token: access_token } },
    context: isClient ? { ...withAuthHeaders } : { token: access_token, ...withAuthHeaders },
  });

  const user = isClient ? (data as GetSingleClient)?.client : (data as UserDataResult)?.lawyersCollection[0];
  if (!user || !user._id || decode?.type === TokenType.AUTHORIZATION_CODE) return false;

  return user;
};

export default function useUser({
  redirectTo = '',
  redirectIfFound = false,
  scope,
}: {
  redirectTo?: string;
  redirectIfFound?: boolean;
  scope?: AccessScopeType;
} = {}) {
  const { token, auth_code: authCode } = parseCookies();
  const router = useRouter();
  const { asPath } = router;
  let decode: DecodedAuthToken | any;
  let isClient = scope?.split(':')[0] === AccessScopeType.client || false;
  const apolloClient = getApolloClient(false);

  if (token) {
    try {
      decode = jwtDecode<DecodedAuthToken>(token);
      const decodedScope = decode?.scope?.split(':')[0];

      if (decode.type !== TokenType.AUTHORIZATION_CODE) {
        if (
          (scope && decodedScope && decodedScope !== scope) ||
          (scope === AccessScopeType.client && (!decodedScope || decodedScope === AccessScopeType.lawyer))
        ) {
          pushi18nRoute(router, {
            route: redirectTo || 'login',
          });
        }
      }
      isClient = decodedScope === AccessScopeType.client;
    } catch (err) {
      console.warn('Invalid Token', err);
    }
  }

  const { data, loading, refetch } = useQuery<GetSingleClient | GetSingleLawyerResult>(
    isClient ? GET_AUTHENTICATED_CLIENT : SINGLE_LAWYER_QUERY,
    {
      variables: isClient ? { id: decode?.userId } : { filter: { token } },
      context: isClient ? { ...withAuthHeaders } : { token, ...withAuthHeaders },
      ssr: false,
      skip: !token || !decode || !!authCode,
    }
  );

  const user = isClient ? (data as GetSingleClient)?.client : (data as GetSingleLawyerResult)?.lawyersCollection[0];

  React.useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || loading) return;
    // if the received token is an auth_code token, then don't do anything yet (let validateToken finalize)
    if ((decode && decode.type === TokenType.AUTHORIZATION_CODE) || !!authCode) {
      return;
    }

    // if the token is a valid auth token, the user is populate but with null data
    if (redirectTo && user && !user._id) {
      logout(apolloClient);
    }

    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !user) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && user)
    ) {
      pushi18nRoute(router, {
        route: redirectTo,
        query: { forward: asPath },
      });
    }
  }, [user, redirectIfFound, redirectTo, loading]);

  return { user, loading, refetch };
}

export const initializeAuthToken = (apolloClient: ApolloClient<{}>) => {
  const { query, asPath, pathname } = Router;
  const { token: queryToken, nextInternalLocale, ...rest } = query;
  const TOKEN_IN_QUERY = /(\?|\&)token=([^&]*)/;

  const initializeToken = async (token: string) => {
    try {
      const tokenValidity = await validateToken(token as string, apolloClient);
      if (tokenValidity) {
        setCookie(undefined, 'token', token as string, { path: '/' });
        if ((tokenValidity as UserData).signUpStatus) {
          await identifyUser(tokenValidity._id, tokenValidity.email);
        } else {
          await identifyClient(tokenValidity._id, tokenValidity.email);
        }
        return true;
      }
      return false;
    } catch (err) {
      throw err;
    }
  };

  // This is a workaround. To be refactored because of problems cause by _middleware.tsx
  // https://github.com/vercel/next.js/discussions/11484
  const match = TOKEN_IN_QUERY.exec(asPath);
  const token = queryToken || (match && match.pop());

  if (token) {
    initializeToken(token as string)
      .then(() => {
        const pathVarsKeys = extractStringsBetweenSquareBrackets(pathname);
        const pathVars: any = {};
        for (const key of pathVarsKeys) {
          pathVars[key] = rest[key];
          delete rest[key];
        }

        pushi18nRoute(Router, {
          route: pathname,
          query: rest,
          pathVars,
        });
      })
      .catch((err) => {
        console.warn(err.message);
        if (err.message.includes('status code 401')) {
          pushi18nRoute(Router, { route: 'login', query: { expired: 'true' } });
        }
      });
  } else if (parseCookies()['token']) {
    initializeToken(parseCookies()['token']);
  }
};
