import axios from 'axios';
import { parseCookies } from 'nookies';
import { LawyerUserInterfaceType } from '../typings/shared';
import { AccessScopeType } from './auth-utils';

const LIMIT = 10000;
type MethodsType = 'GET' | 'POST' | 'PUT';
interface FetchProps {
  url: string;
  data: Record<string, any>;
  method?: MethodsType;
  timeout?: number;
  headers?: Record<string, string>;
}
// Based on https://stackoverflow.com/a/49857905
const api = ({ url, data, method = 'POST', timeout = LIMIT, headers = {} }: FetchProps): Promise<any> => {
  url = process.env.NEXT_PUBLIC_API_URL + url;
  return Promise.race([
    axios({ url, data, method, headers, timeout, timeoutErrorMessage: 'Timeout error' }),
    new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout error')), timeout)),
  ]);
};

export const requestLawyerContact = (data: Record<string, any>) => {
  return api({ url: '/api/requests', data });
};

export const requestLawyerScheduling = (data: Record<string, any>) => {
  return api({ url: '/api/requests', data });
};

export const requestOnboardingEmail = (data: Record<string, any>) => {
  return api({ url: '/api/lawyers/request-onboarding', data });
};

export const requestForgotPassword = (data: Record<string, any>) => {
  return api({ url: '/auth/forgot-password', data });
};

export const requestLogin = (data: Record<string, any>) => {
  return api({ url: '/auth/token', data });
};

export const verifyScope = (username: string) => {
  return api({ method: 'GET', url: `/auth/scope?email=${username}&scope=${AccessScopeType.lawyer}`, data: {} });
};

export const sendMagicLinkEmail = (data: Record<string, any>) => {
  return api({ url: '/auth/magic-link', data });
};

export const signUpEmail = (data: Record<string, any>) => {
  return api({ url: '/api/hubspot/email-signup', data });
};

export const submitCase = (data: Record<string, any>) => {
  const cookies = parseCookies();
  const fetchProps = { url: '/api/cases', data } as any;
  if (cookies.token) fetchProps.headers = { authorization: `Bearer ${cookies.token}` };
  return api(fetchProps);
};

export const claimCase = (data: Record<string, any>) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  return api({ url: '/api/requests/', data, headers });
};

export const submitSubscription = (data: Record<string, any>) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  return api({ url: `/api/lawyers/subscription`, data, headers });
};

export const getCustomerPortal = (lawyerSlug: string) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  return api({ url: `/api/lawyers/${lawyerSlug}/customer-portal`, data: {}, headers });
};

export const sendHubspotTimelineEvent = (
  type: LawyerUserInterfaceType,
  message: string,
  email?: string,
  id?: string
) => {
  const data = { message, type } as any;
  if (email) data.email = email;
  if (id) data.lawyerId = id;
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  return api({ url: '/api/lawyers/user-interface-action', data, headers });
};

export const generateLawyerAIReactionToCase = (caseId: string, lawyerId: string) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  return api({ url: `/api/cases/${caseId}/generateLawyerReaction?lawyer=${lawyerId}`, data: {}, headers });
};

export const getHubSpotIdentificationToken = async () => {
  try {
    const cookies = parseCookies();
    const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
    return (await api({ url: '/api/hubspot/conversation-token', data: {}, headers })).data.response;
  } catch (e) {
    return 'none';
  }
};

export const sendSlackEmailNotFound = (data: Record<string, any>) => {
  return api({ url: '/api/slack/email-not-found', data });
};

export const getSearchSuggestions = (searchTerm: string, locale: string = 'de') => {
  return api({ method: 'GET', url: `/api/search/suggest?q=${searchTerm}&locale=${locale}`, data: {} });
};

export const updateRequestPricing = (
  mandateFees: Array<{ issueDate: string | number; amount: number }>,
  requestId: string
) => {
  const cookies = parseCookies();
  const headers = { authorization: `LAWYER-AUTH-TOKEN ${cookies.token}` };
  return api({ method: 'PUT', url: `/api/requests/${requestId}`, data: { mandateFees }, headers });
};

export const setClientCredentials = (data: Record<string, any>) => {
  const cookies = parseCookies();
  const headers = { authorization: `Bearer ${cookies.token}` };
  return api({ url: `/auth/set-credentials`, data, headers });
};

export const sendVerifyClientEmail = (email: string) => {
  const cookies = parseCookies();
  const headers = { authorization: `Bearer ${cookies.token}` };
  return api({ url: `/auth/verify-email`, data: { email }, headers });
};

export const checkUserCredentials = (email: string) => {
  return api({ url: `/auth/check-credentials`, data: { email } });
};

export const deleteClientAccount = (clientId: string) => {
  const cookies = parseCookies();
  const headers = { authorization: `Bearer ${cookies.token}` };
  return api({ url: `/api/client/${clientId}/delete`, data: {}, method: 'PUT', headers });
};
