const germanRoutes = require('./german.js');
const frenchRoutes = require('./french.js');
const englishRoutes = require('./english.js');
const italianRoutes = require('./italian.js');

const internationalisedRoutes = [
  ...germanRoutes.flat(),
  ...frenchRoutes.flat(),
  ...englishRoutes.flat(),
  ...italianRoutes.flat(),
];

module.exports = internationalisedRoutes;
