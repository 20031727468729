import React from 'react';
import { useTranslation } from 'next-i18next';
import Link, { LinkProps } from 'next/link';
import { i18nPath } from '../utils/i18n-utils';

interface Props {
  route: string;
  query?: any;
  pathVars?: Record<string, string | Array<string | undefined> | undefined>;
  lang?: string;
  prefetch?: boolean;
  children?: React.ReactNode;
}

const ExtendedLink: React.FC<Props> = ({ children, route, pathVars, query, lang, prefetch = false }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const locale = lang || language || 'de';
  const pathVariables =
    (pathVars ? Object.values(pathVars).flat() : []).filter((v) => !!v).reduce((p, c) => p + '/' + c, '') || '';

  const translatedPathname = `/${locale}/${i18nPath(route, locale)}${pathVariables}`.replace('//', '/');

  const href = {
    pathname: `/${route}`.replace('//', '/'),
    query: query || pathVars ? Object.assign({}, query || {}, pathVars || {}) : undefined,
  };
  const as = { pathname: translatedPathname, query };
  const options = { passHref: children ? true : false, as, href, locale, prefetch } as LinkProps;

  return (
    <Link {...options} legacyBehavior>
      {children}
    </Link>
  );
};

export default ExtendedLink;
